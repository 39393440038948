<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <!-- <div class="attr-containerResize" :id="'containerResize'+attr.html_id"> -->
            <div class="label-grid" 
                v-if="attr.title && (attr.showTitle !== undefined ? attr.showTitle : true)" 
                v-wjTooltip="tooltip()"
                :for="attr.html_id"
            >
                {{ attr.title }}
            </div>
            <div class="grid-top-line" ref="attr_top_line">
                <div class="commandPanel-menu" v-if="commandPanel.length">
                    <component v-for="attrM in commandPanel"
                        :key="attrM.html_id"
                        :widget_id="widget_id" 
                        :is="attrM.component"
                        :attr_id="attrM.id"
                    />
                </div>
                <div class="grid-menu">
                    <wj-menu
                    v-for="(menu, index) in menuTree"
                        :key="index"
                        :id="'menu_' + index"
                        :header="menu.title"
                        :displayMemberPath="'title'"
                        :subItemsPath="'attrs'"
                        :showDropDownButton="false"
                        :openOnHover="true"
                        :isAnimated="false"
                        :itemsSource="menu.attrs"
                        :initialized="initMenu"
                        :itemClicked="menuNodeClicked"
                    />
                </div>
                <div class="p-1" v-if="attr.showFilterChips">
                    <span class="p-float-label">
                        <Chips inputId="chips" v-model="filterChips"
                            @add="apply_filterChips"
                            @remove="apply_filterChips"
                        ></Chips>
                        <label for="chips">Search...</label>
                    </span>
                </div>

                <div class="grid-summary-line" v-if="attr.showGridSummaryLine">
                    <p v-html="currentSelectionHTML"></p>
                </div>
            </div>
            <div :class="gridClass" ref="attr_container" tabindex="0">
                <wj-group-panel v-if="attr.showGroupPanel" :placeholder="'Drag columns here to create groups'" :initialized="groupPanelInitialized"/>
                <wj-flex-grid
                    :id="attr.html_id"
                    class="my-grid attr-grid-input"
                    :initialized="initialized"
                    v-wjContextMenu="'contextMenuId'+attr.html_id"
                    v-wjTooltip="attr.tooltip"
                    v-visible="handleVisibility"

                    :autoGenerateColumns="false"
                    :formatItem="formatItem"
                    :headersVisibility="attr.headersVisibility||'All'"
                    
                    :rowAdded="rowAdded"
                    :pasting="pasting"
                    :pastingCell="pastingCell"
                    :pastedCell="pastedCell"
                    
                    :beginningEdit="beginningEdit"
                    :prepareCellForEdit="prepareCellForEdit"
                    :cellEditEnding="cellEditEnding"
                    :cellEditEnded="cellEditEnded"

                    :allowMerging="'Cells'"

                    :allowSorting="this.attr.allowSorting||'None'"
                    :allowDragging="'Columns'"

                    :updatingView="updatingView"
                    :updatingLayout="updatingLayout"
                    :updatedLayout="updatedLayout"
                    :updatedView="updatedView"
                    :itemsSourceChanged="itemsSourceChanged"
                    :selectionChanging="selectionChanging"
                    :selectionChanged="selectionChanged"
                    
                    :showSort="true"
                    :allowPinning="attr.allowPinning||'None'"
                    :isTabHolderVisible="false"
                    :allowAddNew="!('allowAddNew' in attr) || attr.allowAddNew"
                    @click="click"
                    @dblclick="dblclick"
                    :showMarquee="!attr.hideMarquee"
                    :showSelectedHeaders="'All'"
                    :autoSearch="true"
                    :sortedColumn="apply_filterChips"
                    :allowResizing="'ColumnsAllCells'"

                    :frozenRows="attr.frozenRows || 0"
                    :frozenColumns="attr.frozenColumns || 0"
                >
                    <!-- :allowSorting="!this.attr.column_childItemsPath" -->
                    <!-- <wj-flex-grid-cell-template cellType="RowHeader" v-slot="cell">
                        {{cell.row.index + 1}}
                    </wj-flex-grid-cell-template> -->

                    <!-- <wj-flex-grid-detail :isAnimated=true v-slot="ctx" :detailVisibilityMode="'ExpandSingle'">
                        ID: <b>{{ ctx?.item }}</b><br />
                        <component v-for="attr_detail in attr.attrs_detail"
                            :widget_id="widget_id" 
                            :key="attr_detail.html_id"
                            :is="attr_detail.component"
                            :attr_id="attr_detail.id"
                        />
                    </wj-flex-grid-detail> -->
        
                    <wj-flex-grid-filter v-if="attr.showFilter||false"
                        :filterChanged="filterChanged"
                    />
                </wj-flex-grid>

                <div class="widget_menu">
                    <wj-menu :id="'contextMenuId'+attr.html_id" :header="'Context Menu'" :displayMemberPath="'title'" :subItemsPath="'attrs'" :showDropDownButton="false" style="display:none"
                        v-if="attr && attr.groupMenu && 'contextMenu' in attr.groupMenu"
                        :openOnHover="true" :isAnimated="false" :itemClicked="menuNodeClicked"
                    >
                        <!-- :itemsSource="attr.contextMenu" -->
                        <wj-menu-item :value="menu_node.id" v-for="menu_node in attr.groupMenu.contextMenu" :key="menu_node.id">
                            <div>
                                <span :class="menu_node.cssClass"></span>&nbsp;&nbsp;
                                {{menu_node.title}}
                            </div>
                        </wj-menu-item>
                    </wj-menu>
                </div>

                <wj-menu
                    style="display: none"
                    dropDownCssClass="ctx-menu"
                    :initialized="gp_initializedMenu"
                    :itemClicked="gp_itemClicked" >
                    <wj-menu-item>
                        <span class='wj-glyph-down-right'></span> Expand All
                    </wj-menu-item>
                    <wj-menu-item>
                        <span class='wj-glyph-right'></span> Collapse All
                    </wj-menu-item>
                </wj-menu>
            </div>
    <!-- </div> -->
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'
import * as wjcCore from '@mescius/wijmo';
import * as wjGrid from '@mescius/wijmo.grid';
import { InputDate, InputTime, ComboBox, AutoComplete, InputColor, InputNumber } from "@mescius/wijmo.input";
import { h,createApp } from 'vue/dist/vue.esm-bundler.js';
import "@mescius/wijmo.vue2.grid";
import { CellMaker, SparklineType, SparklineMarkers } from '@mescius/wijmo.grid.cellmaker';
import { filter, string } from 'mathjs';
import { Selector,BooleanChecker } from '@mescius/wijmo.grid.selector'
import { listenerCount, title } from 'process';
// import { UndoStack } from '@mescius/wijmo.undo';

let tooltip = new wjcCore.Tooltip({ 
    // showAtMouse: true,
    // showDelay: 200,
})

var __extends = (this && this.__extends) || (function() {
    var extendStatics = function(d, b) {
        extendStatics =
            Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array &&
                function(d, b) {
                    d.__proto__ = b;
                }) ||
            function(d, b) {
                for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
            };
        return extendStatics(d, b);
    };
    return function(d, b) {
        extendStatics(d, b);
        function __() {
            this.constructor = d;
        }
        d.prototype =
            b === null
                ? Object.create(b)
                : ((__.prototype = b.prototype), new __());
    };
})()

var CustomMergeManager = (function(_super) {
    __extends(CustomMergeManager, _super);
    function CustomMergeManager() {
        return _super.call(this) || this
    }
    CustomMergeManager.prototype.getMergedRange = function(panel, irow, icol, clip) {
        let row = panel.rows[irow],
        dataItem = row?.dataItem
        if (dataItem && dataItem.MD && dataItem.MD.mergeRow) {
            return new wjGrid.CellRange(irow, 0, irow, panel.columns.length-1)
        }

        if (panel.cellType != 1 || panel.grid.__mergeColumns < 2 || !(icol < panel.grid.__mergeColumns)) {
            return _super.prototype.getMergedRange.call(this, panel, irow, icol, clip)
        }
        if (clip === void 0) {
            clip = true
        }
        var rng = new wjGrid.CellRange(irow, icol)
            // cellData = panel.getCellData(irow, icol, true)
        
        // icol0, icol +++ icol2
        for (var icol2 = icol + 1; (icol2 <= panel.grid.__mergeColumns || 0) && (icol2 < panel.columns.length); icol2++) { // panel.columns.length
            if ( panel.getCellData(irow, icol, true) != '' && panel.getCellData(irow, icol2, true) == '' ) 
                rng.col2 = icol2
            else
                break
        }

        // icol0 +++ icol, icol2
        for (var icol0 = icol - 1; icol0 >= 0; icol0--) {
            if ( panel.getCellData(irow, icol0, true) != '' && panel.getCellData(irow, icol0 + 1, true) == '' ) 
                rng.col = icol0
            else
                break
        }
        // console.log(panel.cellType, irow, icol, panel.getCellData(irow, icol, true))
        return rng
    }
    return CustomMergeManager
})(wjGrid.MergeManager)

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    data: () => ({ 
        WIDGET: {},
        data: [],
        columns: [],
        col_input: {},
        col_itemsSource: {},
        attr_by_binding: {},
        column_by_binding: {},
        flex_isEditing: false,
        formatItem_count: 0,
        allow_formatItem:false,
       
        groupIndex: -1,

        headerWijmoRows: 0,
        headerRows: 0,
        headerColumns: 0,
        row_bottom: 0,
        column_left: 0,
        rowHeaders_col_index: 0,
        rowHeaders_col_selector: null,
        rowHeaders_col_use: null,

        changesStack: {},
        backendStack: {},
        backendProcessed: false,
        undoStack: [],
        redoStack: [],
        actionStack: 0,
        canUndo: false,
        canRedo: false,
        actionCount: 0,
        pastedVal: null,
        newVal: null,

        rowLevelGroup_ranges_length: 0,
        
        commandPanel: [],
        ind_commandPanel: 0,

        topLeftMenu_cssClass: 'pi-arrow-right',
        currentSelectionHTML: '',
        filterChips: [],
        conditions_multi: ['in', 'NOT in', 'LIKE', 'NOT LIKE', 'between'],

        allowDraggingRows: false,

        activeEditor_AttrMulti_OLD: null,
        value_multi_inEdit: {},

        frmModifyChip: null,


        // template for buttons on AttrLink
        tplBtn:{
            AttrDoc_choice:
                `<span wj-part="btn" class="wj-input-group-btn cell-value-show">
                    <button class="wj-btn wj-btn-default attr-doc-button my-text-shadow" id="onButtonСhoiceStart__Click" tabindex="-1" type="button" aria-label="Toggle choose in list">
                        <i class="pi pi-doc pi-ellipsis-h" id="onButtonСhoiceStart__Click"></i>
                    </button>
                </span>`,
            AttrDoc_showDroppedDown:
                `<span wj-part="btn" class="wj-input-group-btn cell-value-show">
                    <button class="wj-btn wj-btn-default attr-doc-button my-text-shadow" id="onButtonShowDroppedDown__Click" tabindex="-1" type="button" aria-label="Internal link">
                        <i class="pi pi-doc pi-angle-down" id="onButtonShowDroppedDown__Click"></i>
                    </button>
                </span>`,
            AttrDoc_open:
                `<span wj-part="btn" class="wj-input-group-btn cell-value-show">
                    <button class="wj-btn wj-btn-default attr-doc-button my-text-shadow" id="onButtonOpen__Click" tabindex="-1" type="button" aria-label="Internal link">
                        <i class="pi pi-doc pi-arrow-right" id="onButtonOpen__Click"></i>
                    </button>
                </span>`,
            AttrDoc_search:
                `<span wj-part="btn" class="wj-input-group-btn">
                    <button class="wj-btn wj-btn-default attr-doc-button my-text-shadow" id="onButtonSearch__Click" tabindex="-1" type="button" aria-label="Internal link">
                        <i class="pi pi-doc pi-search" id="onButtonSearch__Click"></i>
                    </button>
                </span>`,
            OpenGroup:
                `<div role="button" class="wj-elem-collapse" id="onButtonCollapsed__Click">
                    <span class="pi pi-doc pi-plus-circle" id="onButtonCollapsed__Click"></span>
                </div>`,
            CloseGroup:
                `<div role="button" class="wj-elem-collapse" id="onButtonCollapsed__Click">
                    <span class="pi pi-doc pi-minus-circle" id="onButtonCollapsed__Click"></span>
                </div>`,
            ApplySelector:
                `<span wj-part="btn" class="wj-input-group-btn">
                    <button class="wj-btn wj-btn-default attr-doc-button my-text-shadow" id="onSelector__Click" tabindex="-1" type="button" aria-label="Internal link">
                        <i class="pi pi-doc pi-check-square" id="onSelector__Click"></i>
                    </button>
                </span>`
        }
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
        // this.attr.groupMenu = {
        //     commandPanel: [
        //         {"tooltip":"Select","command":"executeWidgetMethod","method":"select","cssClass":"pi pi-refresh","type":"AttrButton","id":"root_4_AttrButton","html_id":"root_4_AttrButton","component":"AttrButton","attrs":[]}
        //     ],
        // }
    },
    mounted() {
        this.WIDGET.attrsResize.push(this.attrResize)
        // this.store.setObserver_attrResize(this)
    },
    methods: {
        formatItem(flex, e) {
            if (this.allow_formatItem) {
                if (e.panel == flex.cells) { // && !e.cell.classList.contains('wj-group')
                    // console.log(`formatItem ${this.attr.binding}: ${++this.formatItem_count}`)
                    let col = flex.columns[e.col],
                    irow = e.row,
                    col_binding = col.binding,
                    row = flex.rows[irow],
                    dataItem = row.dataItem,
                    attr_col = this.attr_by_binding[col_binding],
                    tooltip_doc = {}

                    // // spill
                    // let spill = e.col < flex.columns.length - 1 && e.cell.innerHTML && !flex.getCellData(e.row, e.col + 1) && attr_col.component == 'AttrLink'
                    // wjcCore.toggleClass(e.cell, 'spill', spill)
                    // if (!spill && attr_col.component != 'AttrLink') {
                    //     wjcCore.toggleClass(e.cell, 'spill_data', true)
                    // }
                    
                    // if (this.attr.hideMarquee) {
                    //     wjcCore.removeClass(e.cell, 'wj-state-selected')
                    //     wjcCore.removeClass(e.cell, 'wj-state-multi-selected')
                    // }
                    if (row.isReadOnly !== false && flex.isReadOnly === false ) {
                        row.isReadOnly = false
                    }

                    let wjRow = wjcCore.closest(e.cell, '.wj-row')
                    wjRow.data_cell = { irow: irow }
                    if (wjRow && wjRow.draggable !== this.allowDraggingRows) {
                        wjRow.draggable = this.allowDraggingRows
                        if (wjRow.draggable) {
                            wjRow.addEventListener('dragstart', this.wjRow_dragstart)
                            wjRow.addEventListener('dragover', this.wjRow_dragover)
                            wjRow.addEventListener('drop', this.wjRow_drop)
                            wjRow.addEventListener('dragend', this.wjRow_dragend)
                        }
                    }

                    if (this.attr.childItemsPath) {
                        wjcCore.removeClass(e.cell, 'wj-group')
                        if (row.hasChildren) {
                            if (row.isCollapsed) {
                                // row.height = null
                                if (this.attr.attr_type === 'AttrTabGrid' && col_binding === 'attr.valueStr') {
                                    let doc = this.attrsTabGrid_to_JSON(dataItem[this.attr.childItemsPath], 'valueStr', false)
                                    e.cell.innerHTML = this.store.JS_stringifyHtml(doc)
                                }
                            } else if (this.attr.attr_type !== 'AttrTabGrid') {
                                wjcCore.addClass(e.cell, `row-parent${row.level}`)
                                // row.height = 38
                            }
                        }
                        if (irow+1 < flex.rows.length && row.level > flex.rows[irow+1].level) {
                            wjcCore.addClass(e.cell, `row-bottom-group`)
                            // wjcCore.addClass(e.cell.parentElement, `row-bottom-group2`)
                        }
                    }

                    // search
                    if (e.cell.innerText && (this.filterChips.length || this.WIDGET.widget_class === 'widget_SEARCH')) {
                        let highlightedTitle = this.check_filterChips_highlighted(e.cell.innerText, this.filterChips)
                        if (highlightedTitle) {
                            e.cell.innerHTML = highlightedTitle
                        }
                    }

                    if (attr_col && attr_col.cssStyle) {
                        e.cell.style.cssText += attr_col.cssStyle // + `content:"${e.col} ${col_binding} ${attr_col.title}";`
                    }
                    if (dataItem && dataItem.cssStyle) {
                        e.cell.style.cssText += dataItem.cssStyle
                    }
                    if (attr_col && attr_col.cssClass) {
                        wjcCore.addClass(e.cell, attr_col.cssClass)
                    }
                    if (attr_col && dataItem && 'level' in dataItem && attr_col[`cssClass-${dataItem.level}`]) {
                        wjcCore.addClass(e.cell, attr_col[`cssClass-${dataItem.level}`])
                    }
                    if (dataItem && dataItem.cssClass) {
                        // if (!this.attr_by_binding.title || col_binding=='title') { LOOK 2024-09-05 for AttrTabGrid
                            wjcCore.addClass(e.cell, dataItem.cssClass)
                        // }
                    }
                    if (attr_col && dataItem && dataItem[attr_col.binding]?.is_customValueWarning) { // && attr_col.customValueWarning 
                        wjcCore.addClass(e.cell, 'custom-value-warning')
                    }

                    // Gantt
                    if (dataItem && dataItem.$gantt && dataItem.$gantt[col_binding]) {
                        // wjcCore.addClass(e.cell, 'gantt-container')
                        let tooltip_doc2
                        for (let bar_gantt of dataItem.$gantt[col_binding]) {
                            let bar = document.createElement('div')
                            if (bar_gantt.className.indexOf('gantt-bar') != -1) {
                                bar.className = bar_gantt.className
                                bar.style.cssText = bar_gantt.cssText
                                e.cell.appendChild(bar)
                                let workCenterV = {value:bar_gantt.workcenter}
                                this.store.get_valueStr(this, workCenterV, {relation_segment:'SUBJECT-WorkCenter'})
                                tooltip_doc2 = {
                                    // 'id': bar_gantt.order_id,
                                    'Work center': workCenterV.valueStr,
                                    'From': bar_gantt.from,
                                    'To': bar_gantt.to,
                                }
                            } else {
                                bar.className = bar_gantt.className // 'in-bar'
                                e.cell.appendChild(bar)
                                tooltip_doc2 = bar_gantt.tooltip
                            }
                            tooltip.setTooltip(bar, this.store.tooltip_html(tooltip_doc2))
                        }

                        // bar = document.createElement('div')
                        // bar.className = 'gantt-bar second-bar'
                        // e.cell.appendChild(bar)
                        // tooltip.setTooltip(bar, '2');

                        // bar = document.createElement('div')
                        // bar.className = 'gantt-bar third-bar'
                        // e.cell.appendChild(bar)
                        // tooltip.setTooltip(bar, '3');
                    }

                    if (attr_col && dataItem && attr_col.component == 'AttrMulti_OLD') {
                        var div0 = e.cell.childNodes[0]
                        if (!dataItem[attr_col.binding] || !dataItem[attr_col.binding].isList) { //  !this.conditions_multi.includes(dataItem.condition)
                            // if (dataItem[attr_col.binding][0].component === 'AttrBool') {
                            //     if (div0) {
                            //         div0.type="checkbox"
                            //     }
                            //     // e.cell.innerHTML = `<input type="checkbox" class="wj-cell-check" tabindex="-1">` // checked
                            // }
                        } else {
                            wjcCore.addClass(e.cell, 'i-chips2-container')
                            if (div0) {
                                e.cell.removeChild(div0)
                            }
                            let ivalue = 0
                            for (let value_multi of dataItem[attr_col.binding].values||[]) {
                                if (!(e.row == this.value_multi_inEdit.row && ivalue == this.value_multi_inEdit.ivalue)) {
                                    let div1 = document.createElement('span')
                                    div1.classList = ["i-chip2 data-cell"]
                                    if (e.cell.childNodes.length) {
                                        div1.innerHTML = `<span class="i-separator">|</span>&nbsp;&nbsp;`
                                    }
                                    div1.innerHTML += `
                                        <span class="i-chip2-content" id="onValue_multi__Click">
                                            ${value_multi.title}
                                        </span>
                                    `
                                    div1.data_cell = {
                                        irow: irow,
                                        binding: attr_col.binding,
                                        ivalue: ivalue,
                                        row: e.row,
                                        col: e.col
                                    }
                                    e.cell.appendChild(div1)
                                }
                                ivalue++
                            }
                            if (div0) {
                                if (e.cell.childNodes.length) {
                                    let div1 = document.createElement('span')
                                    div1.innerHTML = `<span class="i-separator">|</span>&nbsp;&nbsp;`
                                    e.cell.appendChild(div1)
                                }
                                e.cell.appendChild(div0)
                            }
                        }
                    }

                    let cellBtnHTML = ''

                    let item_attr = attr_col
                    if (dataItem && attr_col?.attr_type === 'AttrMulti') {
                        wjcCore.addClass(e.cell, 'cell-multi')
                        item_attr = dataItem[attr_col.binding]
                        if (!item_attr) {
                            item_attr = dataItem[attr_col.binding] = this.store.new_valueMulti(attr_col)
                        }

                        // tooltip
                        // if (item_attr.component === 'AttrLink' || item_attr.component === 'AttrLinkOdoo') {
                        if (item_attr.tooltip) {
                            tooltip_doc['Tooltip'] = item_attr.tooltip
                        }
                        if (item_attr.value && item_attr.value != item_attr.valueStr) {
                            tooltip_doc['ID'] = item_attr.value
                        }
                        if (item_attr.binding) {
                            tooltip_doc['Field'] = item_attr.binding
                        }
                        if (item_attr.attr_type) {
                            tooltip_doc['Field type'] = item_attr.attr_type
                        }
                        if (item_attr.relation_segment) {
                            tooltip_doc['Relation segment'] = item_attr.relation_segment
                        }
                        if (item_attr.relation_binding) {
                            tooltip_doc['Relation field'] = item_attr.relation_binding
                        }

                        if (attr_col.buttons?.search && flex.getCellData(e.row, e.col)) {
                            cellBtnHTML += this.tplBtn.AttrDoc_search
                        }
                    }

                    if (item_attr?.component === 'AttrTime' && dataItem?.[col_binding]) {
                        e.cell.innerHTML = this.store.humanDuration(dataItem[col_binding])
                    }

                    // selector cell
                    if (this.attr.selector_cell && attr_col && dataItem && dataItem[attr_col.binding_doc] && typeof dataItem[attr_col.binding_doc] === 'object' && 'selector' in dataItem[attr_col.binding_doc]) {
                        let cell_doc = dataItem[attr_col.binding_doc]
                        wjcCore.addClass(e.cell, 'attr-KPI')
                        
                        let div1 = document.createElement('div')
                        div1.classList = ["data-cell attr-KPI-group"]

                        if ('selector' in cell_doc) {
                            let checkbox = document.createElement('input')

                            checkbox.type = 'checkbox'
                            checkbox.className = 'wj-cell-check'
                            checkbox.tabIndex = -1
                            checkbox.id = 'onSelectorKPI__Click'
                            checkbox.checked = cell_doc.selector || false
                            if (checkbox.checked) {
                                checkbox.classList = ["attr-KPI-selector attr-KPI-selector-on wj-control"]
                            } else {
                                checkbox.classList = ["attr-KPI-selector wj-control"]
                            }
                            div1.appendChild(checkbox)
                        }

                        if (cell_doc.action) {
                            let button = document.createElement('button');
                            button.className = 'attr-KPI-button wj-btn wj-btn-default my-text-shadow';
                            button.id = 'onButtonKPI__Click';
                            button.tabIndex = -1;
                            button.type = 'button';
                            button.setAttribute('aria-label', 'Toggle choose in list');
                                let icon = document.createElement('i');
                                icon.className = 'pi pi-doc pi-arrow-right';
                                icon.id = 'onButtonKPI__Click';
                            button.appendChild(icon);
                            div1.appendChild(button)
                        }

                        e.cell.appendChild(div1)
                        
                        div1.data_cell = {
                            irow: irow,
                            binding_doc: attr_col.binding_doc,
                            col_binding: col_binding,
                            row: e.row,
                            col: e.col
                        }
                    }

                    // BtnAttrDoc
                    let set_data_cell = false
                    if (item_attr && (item_attr.component == 'AttrLink' || item_attr.component == 'AttrLinkOdoo') && irow >= this.headerRows ) {
                        set_data_cell = true
                        if (item_attr.buttons?.none) {
                            // pass
                        } else if (item_attr.isReadOnly || this.attr.isReadOnly || item_attr.component == 'AttrLinkOdoo') {
                            if (flex.getCellData(e.row, e.col)) {
                                cellBtnHTML += this.tplBtn.AttrDoc_open
                            }
                        } else if (!item_attr.relation_segment) {
                            wjcCore.addClass(e.cell, 'attr-doc2')
                            if (item_attr.buttons?.show_popupTab || attr_col.buttons?.show_popupTab) {
                                cellBtnHTML += this.tplBtn.AttrDoc_showDroppedDown
                            }
                        } else {
                            if (this.flex_isEditing) {
                                cellBtnHTML += this.tplBtn.AttrDoc_choice
                                if (item_attr.buttons?.show_popupTab || attr_col.buttons?.show_popupTab) {
                                    cellBtnHTML += this.tplBtn.AttrDoc_showDroppedDown
                                }
                            }
                            if (flex.getCellData(e.row, e.col)) {
                                cellBtnHTML += this.tplBtn.AttrDoc_open
                            }
                        }
                    }

                    if (cellBtnHTML != '' || set_data_cell) {
                        let div1 = document.createElement('div')
                        div1.classList = ["data-cell attr-doc-button-group wj-control"]
                        e.cell.appendChild(div1)
                        wjcCore.addClass(e.cell, 'attr-doc')

                        if (cellBtnHTML != '') {
                            div1.innerHTML = cellBtnHTML
                        }

                        div1.data_cell = {
                            irow: irow,
                            col_binding: col_binding,
                            row: e.row,
                            col: e.col
                        }
                    }

                    // row padding
                    if (this.attr.childItemsPath && e.col == 0 && dataItem && !(dataItem[this.attr.childItemsPath]||[]).length) { // Tree
                        // ++ 20px
                        e.cell.style['padding-left'] = `${parseInt(e.cell.style['padding-left'], 10) + 20}px`
                    } else if (irow >= this.headerRows && e.col==0 && dataItem && 'icol_group' in dataItem) { //  && dataItem.icol_group
                        e.cell.style.cssText += `padding-left: ${30+20*dataItem.icol_group}px !important;`
                    }

                    // // rowGroups collapse
                    // if (irow >= this.headerRows && e.col==0 && dataItem && 'isCollapsed' in dataItem) {
                    //     let div1 = document.createElement('div')
                    //     div1.style.cssText += `padding: 6px 0px 6px ${6+20*dataItem.icol_group}px !important;`
                    //     div1.classList = ["data-cell attr-row-button-group wj-control"]
                    //     e.cell.appendChild(div1)
                    //     if (dataItem.isCollapsed) {
                    //         div1.innerHTML = this.tplBtn.OpenGroup
                    //     } else {
                    //         div1.innerHTML = this.tplBtn.CloseGroup
                    //     }
                    //     div1.data_cell = {
                    //         irow: irow,
                    //     }
                    //     // e.cell.innerHTML = `<input type="checkbox" v-model="allocGr.isCollapsed">` + e.cell.innerHTML
                    // }

                    // columnGroups collapse
                    if (attr_col && irow ==0 && e.col >= this.headerColumns && 'isCollapsed' in attr_col) {
                        let div1 = document.createElement('div')
                        e.cell.style.cssText += `padding-left: 30px !important;`
                        div1.style.cssText += `padding: 6px 0px 6px 6px !important;`
                        div1.classList = ["data-cell attr-column-button-group wj-control"]
                        e.cell.appendChild(div1)
                        if (attr_col.isCollapsed) {
                            div1.innerHTML = this.tplBtn.OpenGroup
                        } else {
                            div1.innerHTML = this.tplBtn.CloseGroup
                        }
                        div1.data_cell = {
                            col_binding: col_binding,
                        }
                    }

                    // row ApplySelector
                    if (attr_col && irow >= this.headerRows && attr_col.addBtn_ApplySelector) {
                        wjcCore.addClass(e.cell, 'attr-selector')
                        let div1 = document.createElement('div')
                        div1.classList = ["data-cell attr-doc-button-group wj-control"]
                        e.cell.appendChild(div1)
                        div1.innerHTML = this.tplBtn.ApplySelector
                        div1.data_cell = {
                            irow: irow,
                        }
                        // e.cell.innerHTML = `<input type="checkbox" v-model="allocGr.isCollapsed">` + e.cell.innerHTML
                    }
                    
                    // undoStack
                    // changesStack (undoStack)
                    let has_stack = false
                    if (dataItem && attr_col) {
                        // let i = this.data.indexOf(dataItem)
                        if (`${irow}:${col_binding}` in this.changesStack) {
                            let rowStack = this.changesStack[`${irow}:${col_binding}`]
                            tooltip_doc['Old value'] = rowStack.val
                            if (rowStack.pastedVal) {
                                tooltip_doc['Pasted'] = rowStack.pastedVal
                            }
                            if (rowStack.pastedVal && !rowStack.newVal) {
                            // if (+(rowStack.pastedVal || 0) && !rowStack.newVal) {
                                wjcCore.addClass(e.cell, 'wj-has-stack-error')
                            } else {
                                wjcCore.addClass(e.cell, 'wj-has-stack')
                            }
                            has_stack = true
                        // } else if (`${dataItem.measure}:${attr_col.date}` in this.data_overrides) {
                        //     wjcCore.addClass(e.cell, 'wj-has-override')
                        //     let row_overrides = this.data_overrides[`${dataItem.measure}:${attr_col.date}`]
                        //     tooltip.setTooltip(e.cell, `<b>Old value:</b><br/>${row_overrides.oldVal}<br/>${row_overrides.tooltip}`);
                        //     has_stack = true
                        }

                        // if (irow >= this.headerRows && e.col >= this.headerColumns && dataItem && !dataItem.levelGroup) {
                        //     for (let iStack=this.undoStack.length-1; iStack>=0 && this.undoStack[iStack].actionStack > this.actionStack-100; iStack--) {
                        //         if (this.undoStack[iStack].i==irow-this.headerWijmoRows && this.undoStack[iStack].col_binding==col_binding) {
                        //             wjcCore.addClass(e.cell, "wj-has-stack");
                        //             // wjcCore.addClass(e.cell, 'wj-has-notes')
                        //             tooltip.setTooltip(e.cell, `<b>Old value:</b><br/>${this.undoStack[iStack].val}`);
                        //             break
                        //         }
                        //     }
                        // }

                    }
                    
                    // MD.tooltip, cellsStyle
                    if (irow >= this.headerRows && dataItem && dataItem.MD) {
                        if (dataItem.MD.tooltip && dataItem.MD.tooltip[col_binding]) {
                            tooltip.setTooltip(e.cell, dataItem.MD.tooltip[col_binding])
                        }
                        if (dataItem.MD.rowStyle) {
                            e.cell.style.cssText += dataItem.MD.rowStyle
                        }
                        if (dataItem.MD.cellsStyle && dataItem.MD.cellsStyle[col_binding]) {
                            // console.log(`${irow}[${col_binding}]=${dataItem[col_binding]}, ${dataItem.MD.cellsStyle[col_binding]}`)
                            e.cell.style.cssText += dataItem.MD.cellsStyle[col_binding]
                        }
                        if (dataItem.MD.cssClass && dataItem.MD.cssClass[col_binding]) {
                            wjcCore.addClass(e.cell, dataItem.MD.cssClass[col_binding])
                        }
                        if (dataItem.MD.KPIs_doc && dataItem.MD.KPIs_doc[col_binding] && this.attr.KPIs) {
                            for (let KPI in dataItem.MD.KPIs_doc[col_binding]) {
                                if (this.attr.KPIs[KPI]) {
                                    tooltip_doc[this.attr.KPIs[KPI].title] = ''
                                }
                            }
                        }
                    }
                    
                    if (tooltip_doc !== '') {
                        tooltip.setTooltip(e.cell, this.store.tooltip_html(tooltip_doc))
                        // tooltip.setTooltip(e.cell, this.store.tooltip_doc(this, tooltip_html))
                    }

                    // 0
                    if (dataItem && dataItem[col_binding]==0 && attr_col && attr_col.component != 'AttrMulti_OLD') {
                        wjcCore.addClass(e.cell, 'cell-zero')
                    }

                    // hide empty rows
                    if (this.attr.hideEmptyRows && dataItem) {
                        if (this.isEmpty(dataItem, this.attr.attrs)) {
                            wjcCore.addClass(e.cell, 'cell-empty')
                        }
                    }

                } else if (e.panel == flex.rowHeaders) {
                    let irow = e.row,
                        row = flex.rows[irow],
                        dataItem = row.dataItem

                    if (this.attr.childItemsPath) { 
                        if (row.hasChildren) {
                            if (row.isCollapsed) {
                                // row.height = null
                            } else {
                                wjcCore.addClass(e.cell, `row-parent${row.level}`)
                            }
                        }
                        if (row.level > flex.rows[irow+1]?.level) { // irow+1 < flex.rows.length && 
                            wjcCore.addClass(e.cell, `row-bottom-group`)
                            // wjcCore.addClass(e.cell.parentElement, `row-bottom-group2`)
                        }

                        if (e.col === 0 && row.level < flex.rows[irow-1]?.level) {
                            wjcCore.addClass(e.cell, `row-top-left-group`)
                        }
                        if (e.col === 0 && row.level !== flex.rows[irow+1]?.level) {
                            wjcCore.addClass(e.cell, `row-bottom-left-group`)
                        }

                        e.cell.parentElement.parentElement.parentElement.style['overflow'] = `initial`
                        e.cell.parentElement.style['left'] = `${row.level * 10}px`
                        // e.cell.parentElement.style['transition'] = `1250ms`

                        // if (e.col === 0) {
                        //     // if (irow && row.level !== flex.rows[irow-1].level) {
                        //     //     e.cell.style['border-top-left-radius'] = `0.5rem`
                        //     // }
                        //     if (row.level !== flex.rows[irow+1]?.level) {
                        //         e.cell.style['border-bottom-left-radius'] = `0.5rem`
                        //     }
                        // }
                        // if (e.col === 0) {
                        //     // e.cell.style['width'] = `${30 + row.level * 15}px`
                        // } else {
                        //     e.cell.style['left'] = `${row.level * 15}px`
                        // }
                            // e.cell.parentElement.style['z-index'] = `10`
                        //     wjcCore.addClass(e.cell.parentElement, `rowHeaders-parent-level-${row.level}`)

                        //     // wjcCore.addClass(e.cell, `rowHeaders-level`)
                        //     // wjcCore.addClass(e.cell, `rowHeaders-level-${row.level}`)
                    }

                    if (e.col == this.rowHeaders_col_index) {
                        wjcCore.addClass(e.cell, 'data-cell')
                        e.cell.data_cell = { 
                            irow: irow,
                        }
                        if (dataItem && dataItem.cssClass_rowHeader) {
                            wjcCore.addClass(e.cell, dataItem.cssClass_rowHeader)
                        }

                        let rowTitle = e.row+1-this.headerWijmoRows
                        if (rowTitle) {
                            let rowHeaderButton = ''
                            if ((this.attr.groupMenu?.topLeftMenu?.length)) {
                                rowHeaderButton = 
                                `<div class="row-header">
                                    <button class="button-hover wj-btn wj-btn-default attr-doc-button my-text-shadow" id="onHeader__Click" tabindex="-1" type="button" aria-label="Internal link">
                                        <i class="pi pi-doc ${this.topLeftMenu_cssClass}" id="onHeader__Click"></i>
                                    </button>
                                </div>`
                            } else if (this.dblclick_action) {
                                rowHeaderButton = 
                                `<div class="row-header">
                                    <button class="button-hover wj-btn wj-btn-default attr-doc-button my-text-shadow" id="onHeader__Click" tabindex="-1" type="button" aria-label="Internal link">
                                        <i class="pi pi-doc ${this.dblclick_action.cssClass || this.topLeftMenu_cssClass}" id="onHeader__Click"></i>
                                    </button>
                                </div>`
                            }
                            e.cell.innerHTML =
                            `<div>${rowTitle}</div>${rowHeaderButton}`
                        } else {
                            e.cell.innerHTML = ''
                        }
                    } else if (e.col === this.rowHeaders_col_selector || e.col == this.rowHeaders_col_use) {
                        // let binding = this.flex.rowHeaders.columns[e.col].binding,
                        let binding = e.col === this.rowHeaders_col_selector? 'selector' : 'use'

                        wjcCore.addClass(e.cell, 'data-cell')
                        e.cell.data_cell = { 
                            irow: irow,
                            binding: binding,
                        }

                        let checkbox = document.createElement('input')
                        checkbox.type = 'checkbox'
                        checkbox.className = 'wj-cell-check'
                        checkbox.tabIndex = -1
                        checkbox.id = 'onSelector__Click'
                        checkbox.checked = dataItem?.[binding] || false
                        e.cell.innerHTML = ''
                        e.cell.appendChild(checkbox)
                    }
                } else if (e.panel == flex.columnHeaders) {
                    let col = flex.columns[e.col],
                        col_binding = col.binding,
                        attr_col = this.attr_by_binding[col_binding];

                    // columnGroups
                    if (e.range.col != e.range.col2) {
                        attr_col = this.attr_by_bindingColumnGroup[`${col_binding}-${e.row}`]
                    } 

                    wjcCore.addClass(e.cell, 'wj-header-top')
                    if (attr_col && attr_col.cssClass_columnHeader) {
                        wjcCore.addClass(e.cell, attr_col.cssClass_columnHeader)
                    }
                    if (attr_col) {
                        let tooltip_doc = {}
                        if (attr_col.title) {
                            tooltip_doc['Title'] = attr_col.title
                        }
                        if (attr_col.binding) {
                            tooltip_doc['Field'] = attr_col.binding
                        }
                        if (attr_col.attr_type) {
                            tooltip_doc['Field type'] = attr_col.attr_type
                        }
                        if (attr_col.relation_segment) {
                            tooltip_doc['Relation segment'] = attr_col.relation_segment
                        }
                        if (attr_col.relation_binding) {
                            tooltip_doc['Relation field'] = attr_col.relation_binding
                        }
                        if (attr_col.tooltip) {
                            tooltip_doc['Tooltip'] = attr_col.tooltip
                        }

                        tooltip.setTooltip(e.cell, this.store.tooltip_html(tooltip_doc))
                    }
                    // e.cell.innerHTML = this.qqq++
                } else if (e.panel.cellType == 555) { // !2024 06 04
                    let col = flex.columns[e.col],
                        col_binding = col.binding,
                        attr_col = this.attr_by_binding[col_binding];

                    // aggregateFooters_BASIS
                    if (attr_col.measureDoc && attr_col.measureDoc.params && attr_col.measureDoc.params.aggregateFooters_useBASIS) {
                        let sum = 0,
                            weight = 0,
                            r = flex.rows[e.row],
                            dataItem = flex.rows[e.row].dataItem,
                            group = r instanceof wjGrid.GroupRow ? dataItem : null,
                            bindingW = attr_col.measureDoc.params.aggregateFooters_BASIS;
                        for (let row of group.items) {
                            if (row[bindingW]) {
                                weight += row[bindingW]
                                if (row[attr_col.binding]) {
                                    sum += row[bindingW] * row[attr_col.binding]
                                }
                            }
                        }
                        if (weight) {
                            e.cell.textContent = this.store.formatNumber(Math.round(sum / weight))
                        }
                    }
                } else if (e.panel.cellType == 5) { // ColumnFooter
                    let col = flex.columns[e.col],
                        col_binding = col.binding,
                        attr_col = this.attr_by_binding[col_binding];

                    if (attr_col.component == 'AttrBool') {
                        let count = 0
                        for (let row of this.data) {
                            if (row[col_binding]) {
                                count++
                            }
                        }
                        if (count) {
                            e.cell.textContent = count
                        }
                    } else if (attr_col.measureDoc && attr_col.measureDoc.params && attr_col.measureDoc.params.aggregateFooters_useBASIS) {
                        // aggregateFooters_BASIS
                        let sum = 0,
                            weight = 0,
                            bindingW = attr_col.measureDoc.params.aggregateFooters_BASIS;
                        for (let row of this.data) {
                            if (row[bindingW]) {
                                weight += row[bindingW]
                                if (row[attr_col.binding]) {
                                    sum += row[bindingW] * row[attr_col.binding]
                                }
                            }
                        }
                        if (weight) {
                            e.cell.textContent = this.store.formatNumber(Math.round(sum / weight))
                        }
                    }
                } else if (e.panel == flex.topLeftCells) {
                    let col = this.flex.rowHeaders.columns[e.col]
                    wjcCore.addClass(e.cell, 'wj-header-top')
                    
                    if (col.tooltip) {
                        tooltip.setTooltip(e.cell, col.tooltip)
                    } else if (col.title) {
                        tooltip.setTooltip(e.cell, col.title)
                    }

                    // if (this.attr.header_height) {
                    //     flex.rowHeaders.rows[0].height = 10
                    //     // row.height = this.attr.header_height
                    // }
                }
            }
        },
        content_Changed() {
            this.store.widget_attr_set(this)

            if (this.attr.attr_type === 'AttrTabGrid') {
                this.data = []
                this.attrsTabGrid_from_attrs(this.attr.attrsTabGrid, this.data)
            } else {
                this.data = this.store.attr_get(this.WIDGET, this.attr)
                if (!this.data) {
                    this.store.attr_set(this.WIDGET, this.attr, [])
                    this.data = this.store.attr_get(this.WIDGET, this.attr)
                }
            }

            if (this.flex) {
                this.load_data()
                this.rowHeaders()
                this.set_commandPanel()
                this.attrResize()
                this.getSelectionWorkspace_date()
            }
        },
        load_data() {
            this.changesStack = {}
            this.backendStack = {}
            this.backendProcessed = false
            this.undoStack.length = 0
            this.redoStack.length = 0
            this.flex_isEditing = this.attr.flex_isEditing||false

            this.attrWHERE_vueObj = this.WIDGET.vueObj.active_attr_vueObj('viewWHERE')
            if (this.attrWHERE_vueObj?.attr?.['target-binding'] !== this.attr.binding) {
                this.attrWHERE_vueObj = null
            }

            if (this.attr['default-filterChips'] && !this['set-default-filterChips']) {
                this.filterChips.push(this.attr['default-filterChips'])
                this['set-default-filterChips'] = true
            }

            this.selectedRanges_BEFORE = this.flex.selectedRanges
            this.flex.__mergeColumns = this.attr?.mergeColumns ? this.attr.mergeColumns : 0 // frozenColumns
            this.qqq = 0
            this.flex.selectionMode = this.attr.selectionMode||'CellRange'
            if (this.attr.childItemsPath) {
                this.flex.childItemsPath = this.attr.childItemsPath
                this.flex.selectionMode = this.attr.selectionMode||'CellRange'
            } else {
                this.flex.childItemsPath = ''
            }
            this.dblclick_action = this.store.dblclick(this.WIDGET.vueObj, this.attr, false)
            this.flex.isReadOnly = this.attr.isReadOnly||false
            // this.flex.showMarquee = !this.flex.isReadOnly

            if (this.attr.groupMenu?.topLeftMenu?.length?.length) {
                this.topLeftMenu_cssClass = this.attr.groupMenu.topLeftMenu[0].cssClass
            }
            this.aggregateFooters()
            
            //columns
            this.attr_by_binding = {}
            this.attr_by_bindingColumnGroup = {}
            this.column_by_binding0 = {}
            this.columnGroups_by_binding0 = {}
            this.flex.columns.clear()
            let col = null
            this.icol = 0
            if (this.attr.column_childItemsPath) {
                let columnGroups = this.scan_columnGroups_fromAttrs(this.attr.attrs, this.attr.column_childItemsPath)

                // get hierarchy from 'level'
                // columnGroups = this.store.get_hierarchy(columnGroups, 'columns')
                this.flex.columnGroups = columnGroups

                for (let col of this.flex.columns) {
                    this.column_by_binding0[col.binding] = col
                }
                this.scan_columnGroups_fromFlex(this.flex.columnGroups)
            }

            // ----------------------------------------------------------------------------------------------------------------
            this.scan_column_fromAttrs(this.attr.attrs, this.attr.column_childItemsPath)

            this.set_itemsSource()

            for (let attr_col of this.attr.attrs) {
                if (attr_col.component == 'AttrNumber') {
                    // for (let dataItem of this.data) {
                    this.flex.rows.forEach((item) => {
                        if (item.dataItem && item.dataItem[attr_col.binding] != null) { // 2023 05 23 for data_strip
                            item.dataItem[attr_col.binding] = +item.dataItem[attr_col.binding]
                        }
                    })
                }
            }
            this.prepare_AttrMulti_all()

            // handle button clicks
            // this.flex.addEventListener(this.flex.hostElement, 'click', this.click)
            
            // if (this.WIDGET.widget_class=='widget_SOP_document') {
            this.apply_filterChips()
            this.set_headerColumns()
            this.formatSheet()
            this.collapsedAll(true)
            // }
            // if (this.flex.sortManager.checkSortItemExists(0)==-1) {  
            //     this.flex.sortManager.addSortLevel(0, true)  !!! problems with ediding: Uncaught TypeError: Cannot set properties of undefined (setting '_view')
            // }
            // this.flex.sortManager.commitSort()
            // this.set_headerColumns()
            this.use_selectorMeasures()
        },
        set_itemsSource() {
            let groupDescriptions = this.attr.groupDescriptions || []
            let sortDescriptions = []
            for (let row of this.attr.sortDescriptions || []) {
                sortDescriptions.push(
                    new wjcCore.SortDescription(row.binding, row.ascending||true)
                )
            }
            
            this.ignore_selectionChanged = true
            this.flex.itemsSource = new wjcCore.CollectionView(this.data, {
                sortDescriptions: sortDescriptions,
                groupDescriptions: groupDescriptions,
                // groupIndex: -1
            })
            this.flex.selectedRanges = this.selectedRanges_BEFORE
            this.ignore_selectionChanged = false
            
            if (groupDescriptions.length) {
                for (let binding of groupDescriptions) {
                    let col = this.column_by_binding[binding]
                    if (col) {
                        col.visible = false
                    }
                }
                

                // let gd = this.flex.collectionView.groupDescriptions;
                //  // remove group
                // for (let i = 0; i < gd.length; i++) {
                //     if (gd[i].propertyName == col.binding) {
                //         gd.removeAt(i);
                //         return; // we're done
                //     }
                // }
                // // add group
                // gd.push(new PropertyGroupDescription(col.binding));
            } else if (this.attr.childItemsPath) {
                this.flex.childItemsPath = this.attr.childItemsPath
            }
            if ('collapseGroupsToLevel' in this.attr) {
                this.flex.collapseGroupsToLevel(this.attr.collapseGroupsToLevel)
            }
        },
        scan_columnGroups_fromAttrs(attrs, childItemsPath) {
            let columnGroups = []

            for (let attr_col of attrs) {
                if (attr_col.binding === 'selector' || attr_col.binding == 'use') {
                    continue
                }
                    
                let col = {}
                if (attr_col.binding) {
                    col.binding = attr_col.binding
                }
                // if (attr_col.level) {
                //     col.level = attr_col.level
                // }
                col.name = attr_col.title
                col.header = attr_col.title
                if (attr_col.binding) {
                    col.binding = attr_col.binding0 = attr_col.binding
                } else {
                    col.binding = attr_col.binding0 = `n${this.icol++}`
                }
                col.align = attr_col.align
                if (attr_col.collapseTo) {
                    col.collapseTo = attr_col.collapseTo
                }
                if (attr_col[childItemsPath]) {
                    col['columns'] = this.scan_columnGroups_fromAttrs(attr_col[childItemsPath], childItemsPath)
                    // if (col['columns'][0]) {
                    //     attr_col[childItemsPath][0].cssClass = 'column-parent0-start'
                    //     attr_col[childItemsPath][attr_col[childItemsPath].length - 1].cssClass = 'column-parent0-end'
                    //     // col.collapseTo = col['columns'][0].binding
                    // }
                }

                columnGroups.push(col)
            }

            return columnGroups
        },
        scan_column_fromAttrs(attrs, childItemsPath) {
            if (this.attr.hide_columnHeaders) {
                this.flex.columnHeaders.rows.length = 0
            }
            for (let attr_col of attrs) {
                if (attr_col.use !== false) {
                    if (attr_col.binding === 'selector' || attr_col.binding === 'use') {
                        this.attr_by_binding[attr_col.binding] = attr_col
                        continue
                    }
                        
                    let col = null
                    if (childItemsPath) {
                        col = this.column_by_binding0[attr_col.binding0]
                        if (!col) {
                            let columnGroup = this.columnGroups_by_binding0[attr_col.binding0],
                                bindingColumnGroup = columnGroup.bindingColumnGroup
                            col = columnGroup.col
                            this.attr_by_bindingColumnGroup[bindingColumnGroup] = attr_col
                            attr_col.is_columnGroup = true
                            // continue
                            // console.log('!!!Error')
                        }
                    } else {
                        col = new wjGrid.Column()
                        col.name = attr_col.title
                    }
                    col.header = attr_col.title
                    col.align = attr_col.align
                    if (!attr_col.is_columnGroup) {
                        col.binding = attr_col.binding
                        col.cssClass = attr_col.cssClass
                        if (typeof attr_col.width === 'number') {
                            col.width = attr_col.width
                        } else if (typeof attr_col.width === 'string') {
                            if (isNaN(Number(attr_col.width))) {
                                col.width = attr_col.width
                            } else {
                                col.width = Number(attr_col.width)
                            }
                        }
                        col.minWidth = attr_col.minWidth
                        if (typeof attr_col.width === 'string' && attr_col.width.includes('*') && !attr_col.minWidth) {
                            col.minWidth = 60
                        }
                        col.isReadOnly = attr_col.isReadOnly
                        col.visible = (!('visible' in attr_col) || attr_col.visible)
                        col.allowMerging = attr_col.allowMerging||false
                        
                        col.type = attr_col.component
                        if (attr_col.component == 'AttrLink') {
                            if (!col.minWidth) {
                                col.minWidth = 60
                            }
                            col.dataMapEditor = 'AutoComplete'
                            this.store.get_itemsSource(this, col, attr_col)
                        } else if (attr_col.component == 'AttrStr') {
                            if (!col.minWidth && (attr_col.title||'').toLowerCase().includes('title')) {
                                col.minWidth = 60
                            }
                            col.dataType = wjcCore.DataType.String
                        } else if (attr_col.component == 'AttrMulti') {
                            col.binding = `${attr_col.binding}.valueStr`
                            col.dataType = wjcCore.DataType.String
                        } else if (attr_col.component == 'AttrMulti_OLD') {
                            col.binding = `${attr_col.binding}.title`
                            col.dataType = wjcCore.DataType.String

                            for (let dataItem of this.data) {
                                if (dataItem[attr_col.binding]) {
                                    this.store.get_itemsSource(this, {}, dataItem[attr_col.binding])
                                    
                                    this.store.prepare_AttrMulti(this, attr_col, dataItem)
                                }
                            }
                        } else if (attr_col.component == 'AttrLinkOdoo') {
                            if (!col.minWidth && (attr_col.title||'').toLowerCase().includes('title')) {
                                col.minWidth = 60
                            }
                            col.dataType = wjcCore.DataType.String
                            col.binding = attr_col.binding + '[1]'
                        } else if (attr_col.component == 'AttrBool') {
                            col.dataType = wjcCore.DataType.Boolean
                        } else if (attr_col.component == 'AttrDate') {
                            col.dataType = wjcCore.DataType.String // Date
                        } else if (attr_col.component == 'AttrTime') {
                            col.dataType = wjcCore.DataType.Number
                        } else if (attr_col.component == 'AttrNumber') {
                            col.dataType = wjcCore.DataType.Number
                            if (attr_col.format) {
                                col.format = attr_col.format || 'n0'
                            } else {
                                col.format = 'n0'
                            }
                            col.step = attr_col.step
                            col.aggregate = attr_col.aggregateFooters||'Sum'
                            if (attr_col.cellTemplate) {
                                this.tplRating = CellMaker.makeRating({
                                    range: [0, 5],
                                    label: 'See Forecast Rating'
                                })
                                col.cellTemplate = this.tplRating
                            }
                        } else if (attr_col.component == 'AttrList') {
                            if (attr_col.cellTemplate) {
                                this.tplSparkLine = CellMaker.makeSparkline({
                                    markers: SparklineMarkers.High | SparklineMarkers.Low,
                                    label: 'Forecast',
                                })
                                col.cellTemplate = this.tplSparkLine
                            }
                        } else {
                            col.dataType = wjcCore.DataType.String
                        }
                    
                        this.attr_by_binding[col.binding] = attr_col
                        this.column_by_binding[col.binding] = col
                    }
                    if (!childItemsPath) {
                        this.flex.columns.push(col)
                    } else if (attr_col[childItemsPath]) {
                        this.scan_column_fromAttrs(attr_col[childItemsPath], childItemsPath)
                    }
                }
            }

            // icol
            for (let icol = 0; icol<this.flex.columns.length; icol++) {
                let col = this.flex.columns[icol]
                if (col.binding && col.binding in this.attr_by_binding) {
                    let attr_col = this.attr_by_binding[col.binding]
                    attr_col.icol = icol
                }
            }
        },
        scan_columnGroups_fromFlex(columnGroups, row=0) {
            let first_col_binding = ''
            for (let col of columnGroups) {
                if (col.columns && col.columns.length) {
                    let first_col_binding0 = this.scan_columnGroups_fromFlex(col.columns, row+1),
                        binding0 = col.binding
                    delete col.binding
                    this.columnGroups_by_binding0[binding0] = {
                        col: col,
                        bindingColumnGroup: `${first_col_binding0}-${row}`,
                    }
                    if (first_col_binding == '') {
                        first_col_binding = first_col_binding0
                    }
                } else if (first_col_binding == '') {
                    first_col_binding = col.binding
                }
            }
            return first_col_binding
        },
        rowHeaders() {
            this.flex.rowHeaders.columns.clear()
            let col

            // selector
            let attr_selector = this.attr.attrs.find(attr_col => attr_col.binding === 'selector')
            this.rowHeaders_col_selector = null
            if (attr_selector) {
                col = new wjGrid.Column()
                col.header = attr_selector.title
                col.tooltip = attr_selector.tooltip
                col.width = 25
                // col.dataType = wjcCore.DataType.Boolean
                // col.binding = attr_selector.binding
                this.rowHeaders_col_selector = this.flex.rowHeaders.columns.push(col) - 1
                // new BooleanChecker(col)
            }

            // #
            let levels = 0
            if (this.attr.childItemsPath) {
                for (let row of this.flex.rows) {
                    if (levels < row.level) {
                        levels = row.level
                    }
                }
            }
            col = new wjGrid.Column()
            col.header = '#'
            col.width = 30 // + 5 * levels
            this.rowHeaders_col_index = this.flex.rowHeaders.columns.push(col) - 1
            
            // use
            let attr_use = this.attr.attrs.find(attr_col => attr_col.binding === 'use')
            this.rowHeaders_col_use = null
            if (attr_use) {
                // det default value
                this.flex.rows.forEach((item) => {
                    if (item.dataItem && !('use' in item.dataItem)) {
                        item.dataItem.use = true
                    }
                })

                col = new wjGrid.Column()
                col.header = attr_use.title
                col.tooltip = attr_use.tooltip
                col.width = 25
                // col.dataType = wjcCore.DataType.Boolean
                // col.binding = attr_use.binding
                this.rowHeaders_col_use = this.flex.rowHeaders.columns.push(col) - 1
                // new BooleanChecker(col)
            }
        },
        set_headerColumns() {
            // for (let col of this.flex.columns) {
            //     let attr_col = this.attr_by_binding[col.binding]
            //     this.flex.setCellData(0, col.index, attr_col.title)
            // }
            // this.flex.refresh()
        },
        formatSheet(){
            console.log('formatSheet')
            // let row = new wjGrid.Row()
            // this.flex.columnHeaders.rows.push(row)

            // this.headerWijmoRows = 1
            if (this.attr.headerRows) {
                this.headerRows = this.attr.headerRows // 3
            }
            if (this.attr.headerColumns) {
                this.headerColumns = this.attr.headerColumns // 1
            }
            this.row_bottom = this.data.length + this.headerWijmoRows
            this.column_left = this.flex.columns.length-1

            // this.flex.columns[0].width = 3
            // if (this.attr.frozenColumns) {
            //     this.flex.frozenColumns = this.attr.frozenColumns
            // } else {
            //     this.flex.frozenColumns = this.headerColumns
            // }
            // if (this.attr.frozenRows) {
            //     this.flex.frozenRows = this.attr.frozenRows
            // }
            // this.flex.frozenRows = this.headerRows+1

            this.formatRows()
            this.formatColumns()
            this.mergeHeaderTop()
            // this.mergeHeaderLeft_leftToRight()
            this.mergeHeaderFirstCell()
            // this.mergeHeaderLeft_topToBottom()

            this.flex.refresh()
            this.attrResize()
        },
        formatRows() {
            // rowlevelGroup_ranges
            let rowLevelGroup_ranges = {}
            this.data.forEach((row, i) => {
                if (!rowLevelGroup_ranges[row.levelGroup]) rowLevelGroup_ranges[row.levelGroup] = []
                rowLevelGroup_ranges[row.levelGroup].push(new wjGrid.CellRange(i+1, 0, i+1, this.column_left))
            })
            
            // format rows
            let levelGroups_ICellStyle = {
                // 0: {
                //     // backgroundColor: "#4488CC",
                //     className: "grid-detail-row",
                //  }, 
                1: {
                    className: "grid-group1-row",
                 }, 
                2: {
                     className: "grid-group2-row",
                 }, 
                99: { //total
                     className: "grid-total-row",
                }, 
                100: { //header
                    className: "wj-header-row",
                },
            }
            this.rowLevelGroup_ranges_length = rowLevelGroup_ranges.length
            // if (rowLevelGroup_ranges.length > 1) 
                this.flex.alternatingRowStep = 0
            for (let levelGroup in levelGroups_ICellStyle) {
                if (rowLevelGroup_ranges[levelGroup]) {
                    this.flex.applyCellsStyle(levelGroups_ICellStyle[levelGroup], rowLevelGroup_ranges[levelGroup])
                }
            }

        },
        formatColumns() {
            // colValue_ranges
            let colValue_ranges = {}
            this.attr.attrs.forEach((attr_col, i) => {
                if (!colValue_ranges[attr_col.value]) colValue_ranges[attr_col.value] = []
                colValue_ranges[attr_col.value].push(new wjGrid.CellRange(0, i, this.row_bottom, i))
            })
            
            // format columns
            let value_ICellStyle = {
                'sum': {
                    // className: "cell-sum",
                    color: 'green',
                },
            }
            for (let value in value_ICellStyle) {
                if (colValue_ranges[value]) {
                    this.flex.applyCellsStyle(value_ICellStyle[value], colValue_ranges[value])
                }
            }
        },
        mergeHeaderTop() {
            let merge_ranges = []
            for (let irow = 0; irow < this.headerRows; irow++) {
                let icol_mergeLeft = this.headerColumns,
                    icol_mergeRight = this.headerColumns
                for (let icol = icol_mergeLeft + 1; icol <= this.column_left; icol++) {
                    // getCellValue
                    if (this.flex.getCellValue(irow, icol_mergeLeft) == this.flex.getCellValue(irow, icol)) {
                        icol_mergeRight = icol
                    } else {
                        if (icol_mergeLeft < icol_mergeRight) {
                            merge_ranges.push(new wjGrid.CellRange(irow, icol_mergeLeft, irow, icol_mergeRight))
                        }
                        icol_mergeLeft = icol_mergeRight = icol
                    }
                }
                if (icol_mergeLeft < icol_mergeRight) {
                    merge_ranges.push(new wjGrid.CellRange(irow, icol_mergeLeft, irow, icol_mergeRight))
                }
            }
            merge_ranges.forEach(range => {
                this.flex.mergeRange(range)
                this.flex.applyCellsStyle({ className: "wj-header-row" }, range)
            })
        },
        mergeHeaderFirstCell() {
            if (this.headerRows > 1) {
                let range = new wjGrid.CellRange(0, 0, this.headerRows-1, this.headerColumns-1)
                this.flex.mergeRange(range)
                this.flex.applyCellsStyle({ className: "wj-header-row" }, range)
            }
        },
        mergeHeaderLeft_leftToRight() {
            let merge_ranges = []
            for (let irow = this.headerRows; irow <= this.row_bottom; irow++) {
                let icol_mergeLeft = 0,
                    icol_mergeRight = 0
                for (let icol = 1; icol < this.headerColumns; icol++) {
                    // getCellValue
                    if (!this.flex.getCellValue(irow, icol)) {
                        icol_mergeRight = icol
                    } else {
                        if (icol_mergeLeft < icol_mergeRight) {
                            merge_ranges.push(new wjGrid.CellRange(irow, icol_mergeLeft, irow, icol_mergeRight))
                        }
                        icol_mergeLeft = icol_mergeRight = icol
                    }
                }
                if (icol_mergeLeft < icol_mergeRight) {
                    merge_ranges.push(new wjGrid.CellRange(irow, icol_mergeLeft, irow, icol_mergeRight))
                }
            }
            merge_ranges.forEach(range => {
                this.flex.mergeRange(range)
            })
        },
        mergeHeaderLeft_topToBottom() {
            let merge_ranges = []
            for (let icol = 0; icol < this.headerColumns; icol++) {
                let irow_mergeTop = 0,
                    irow_mergeBottom = 0
                for (let irow = this.headerRows; irow <= this.row_bottom; irow++) {
                    // getCellValue
                    if (this.flex.getCellValue(irow_mergeTop, icol) == this.flex.getCellValue(irow, icol)) {
                        irow_mergeBottom = irow
                        this.flex.setCellValue(irow, icol)
                    } else {
                        if (irow_mergeTop < irow_mergeBottom) {
                            merge_ranges.push(new wjGrid.CellRange(irow_mergeTop, icol, irow_mergeBottom, icol))
                        }
                        irow_mergeTop = irow_mergeBottom = irow
                    }
                }
                if (irow_mergeTop < irow_mergeBottom) {
                    merge_ranges.push(new wjGrid.CellRange(irow_mergeTop, icol, irow_mergeBottom, icol))
                }
            }
            merge_ranges.forEach(range => {
                this.flex.mergeRange(range)
            })
        },
        groupAll() {
            this.collapsedAll(true)
        },
        ungroupAll() {
            this.collapsedAll(false)
        },
        collapsedAll(forsed=null, justRefresh=false) {
            this.collapsedAllRows(forsed, justRefresh)
            this.collapsedAllColumns(forsed, justRefresh)
        },
        collapsedAllRows(forsed, justRefresh=false) {
            // for (let i=0; i<this.data.length; i++) {
            //     let dataItem = this.data[i]
            //     if ('isCollapsed' in dataItem) { // && !dataItem.isCollapsed
            //         this.collapsed_rowGroup(i, forsed ?? dataItem.isCollapsed, justRefresh);
            //     }
            // }
        },
        collapsedAllColumns(forsed, justRefresh=false) {
            // for (let attr_col of this.attr.attrs) {
            //     if ('isCollapsed' in attr_col) { // && !attr_col.isCollapsed
            //         this.collapsed_columnGroup(attr_col.binding, forsed ?? attr_col.isCollapsed, justRefresh)
            //     }
            // }
        },
        initialized(flex) {
            this.flex = flex
            this.flex.mergeManager = new CustomMergeManager()
            if (this.groupPanel) {
                this.groupPanel.grid = this.flex
            }
            // if (this.attr.aggregateFooters) {
            //     this.flex.columnFooters.rows.push(new wjGrid.GroupRow())
            //     this.flex.bottomLeftCells.setCellData(0, 0, 'Σ')
            // }
            this.content_Changed()
            this.setSelectoinListener()
        },
        setSelectoinListener() {
            const flex = this.flex

            flex.rowHeaders.hostElement.addEventListener('mousedown', () => {
                this.isRowHeaderClick = true
                
                if (this.isRowHeaderClick_timeout) { clearTimeout(this.isRowHeaderClick_timeout) }
                this.isRowHeaderClick_timeout = setTimeout(() => {
                    this.isRowHeaderClick = false
                }, 400)
            })
        },
        groupPanelInitialized: function (grid) {
            this.groupPanel = grid;
            if (this.flex) {
                this.groupPanel.grid = this.flex;
            }
            this.groupPanel.hostElement.addEventListener('contextmenu', e => {
                let groupDescription = this.groupPanel.hitTest(e),
                    cv = this.groupPanel.collectionView;
                if (groupDescription) {
                    this.groupIndex = cv.groupDescriptions.indexOf(groupDescription);
                    this.menu.show(e);
                }
                e.preventDefault();
            })
        },
        gp_initializedMenu: function(menu) {
            this.menu = menu;
        },
        gp_itemClicked: function(menu) { // handle menu commands
            // let grid = this.flex,
                // cv = grid.collectionView,
                // groupIndex = this.groupIndex;
            switch (menu.selectedIndex) {
                case 0: // expand all
                    this.flex.collapseGroupsToLevel(this.groupIndex + 1);
                    break;
                case 1: // collapse all
                    this.flex.collapseGroupsToLevel(this.groupIndex);
                    break;
                
                // case 3: // sort asc
                // case 4: // sort desc
                // case 5: // no sort
                //     cv.deferUpdate(() => {
                //         cv.sortDescriptions.clear();
                //         if (menu.selectedIndex != 5) {
                //             let binding = cv.groupDescriptions[groupIndex].propertyName;
                //             cv.sortDescriptions.push(new SortDescription(binding, menu.selectedIndex == 3));
                //         }
                //     });
                //     break;
                
                // case 7: // remove group
                //     cv.groupDescriptions.removeAt(groupIndex);
                //     break;
            }
        },
        onButtonOpen__Click(cell, e=null) {
            let item_value, itemOdoo, attr_col,
            dataItem = this.flex.rows[cell.row].dataItem,
            item_attr = attr_col = this.attr_by_binding[cell.col_binding]
            if (attr_col.attr_type === 'AttrMulti') {
                item_attr = dataItem[attr_col.binding]
                
                item_value = item_attr.value
                itemOdoo = [item_attr.value, item_attr.valueStr]
            } else {
                item_value = this.flex.getCellData(cell.row, cell.col)
                itemOdoo = dataItem[cell.col_binding] // this.data[cell.irow][item_attr.binding]
            }

            if (!item_value) {
                // this.store.choiceStart(this.WIDGET.vueObj, cell)
            } else if (item_attr.component === 'AttrLinkOdoo') {
                this.store.widget_open({
                    widget: 'widget-DOC-DataHub-raw',
                    doc: {
                        id: itemOdoo[0],
                        segment: item_attr.relation_segment,
                        relation_binding: item_attr.relation_binding,
                    },

                    parentActionMenu: { 'action_id':'action-front-2024-01-23-2', method: 'AttrLinkOdoo.onButtonOpen__Click' },
                    params_onOpen: {
                        parentSelectedItem: itemOdoo,
                        attr: item_attr, 
                    },
                }, this.WIDGET.vueObj)
            } else {
                this.store.widget_open({
                    doc:{
                        id: item_value,
                        segment:item_attr.relation_segment,
                        relation_binding:item_attr.relation_binding,
                    },
                    parentActionMenu: { 'action_id':'action-front-10', method: 'onButtonOpen__Click' },
                }, this.WIDGET.vueObj)
            }
        },
        onButtonSearch__Click(cell) {
            let item_value, item_attr, itemOdoo,
            dataItem = this.flex.rows[cell.row].dataItem
            item_value = this.flex.getCellData(cell.row, cell.col)

            if (item_value) {
                this.store.widget_open({
                    widget: 'widget-search',
                    params_onOpen:{
                        'search_value':item_value,
                    },
                    parentActionMenu: { 'action_id':'action-front-11', method: 'onButtonSearch__Click' },
                }, this.WIDGET.vueObj)
            }
        },
        onButtonCollapsed__Click(cell) {
            if (cell.irow) {
                let row_from = this.flex.rows[cell.irow].dataItem
                this.collapsed_rowGroup(cell.irow - this.headerWijmoRows, !row_from.isCollapsed)
            } else {
                let attr_from = this.attr_by_binding[cell.col_binding]
                this.collapsed_columnGroup(cell.col_binding, !attr_from.isCollapsed)
            }
        },
        attrResize() {
            // if (!this.isVisible) return FIXEDIT Scroll bars are lost
            if (this.attrResize_timeout) { clearTimeout(this.attrResize_timeout) }
            this.attrResize_timeout = setTimeout(() => {
                this.attrResize_afterTimeout()
                this.attrResize_timeout = null
            }, 1)
        },
        attrResize_afterTimeout() {
            // console.log('attrResize')
            // if (!('attrResize' in this.attr)||this.attr.attrResize) {
                const elementForm = document.getElementById(`WIDGET${this.widget_id}`);
                // const elementForm = document.getElementById(`containerResize${this.attr.html_id}`)
                const elementFlex = this.$refs.attr_container
                const elementFlex_grid = document.getElementById(this.attr.html_id)
                // const elementTopLine = this.$refs.attr_top_line
                if (!elementForm || !elementFlex || !elementFlex_grid) {
                    return
                }
                const elementWidget_Rect = elementForm.getBoundingClientRect(),
                elementFlex_Rect = elementFlex.getBoundingClientRect(),
                elementFlex_grid_Rect = elementFlex_grid.getBoundingClientRect()
                // const elementTopLine_Rect = elementTopLine.getBoundingClientRect()
                if (elementFlex_Rect.height > 10) { // if mount all elements
                    if (!this.allow_formatItem) {
                        this.allow_formatItem = true
                        this.flex.refresh()
                    }

                    let newHeight = elementFlex_Rect.height
                    if (!('attrResize' in this.attr) || this.attr.attrResize || newHeight == 50) {
                        newHeight = Math.floor(elementWidget_Rect.bottom - elementFlex_Rect.top) // - 2
                        if (newHeight > elementWidget_Rect.height) {
                        // if (elementFlex_Rect.top < 0) {
                            // let visibleRect = this.getVisibleRect(elementFlex)
                            // newHeight = Math.floor(visibleRect.height) - 2
                            newHeight = 160
                        }
                        if (this.attr.size) {
                            newHeight = Math.round(newHeight * this.attr.size / 100)
                        }
                    } else {
                        return
                    }
                    if (newHeight < (this.attr.minHeight||160)) {
                        newHeight = (this.attr.minHeight||160)
                    }
                    if (this.attr.maxHeight && newHeight > this.attr.maxHeight) {
                        newHeight = this.attr.maxHeight
                    } else if (newHeight > 1500) {
                        newHeight = 1500
                    }
                    if (this.attr.heightAdd) {
                        newHeight += this.attr.heightAdd
                    }
                    // if (this.attr.showGridSummaryLine) {
                        // newHeight -= 20
                    // }
                    if (elementFlex.style.height != `${newHeight}px`) { // elementFlex_Rect.height != newHeight
                        console.log(`attrResize∆ ${this.attr.binding}: ${elementFlex_Rect.height - newHeight}`)
                        elementFlex.style.height = `${newHeight}px`
                    }

                    let newHeight_grid = newHeight - Math.floor(elementFlex_grid_Rect.top - elementFlex_Rect.top)
                    if (elementFlex_grid.style.height != `${newHeight_grid}px`) {
                        elementFlex_grid.style.height = `${newHeight_grid}px`
                    }
                }
            // } else {
            //     this.allow_formatItem = true
            // }
        },
        getVisibleRect(element) {
            let rect = element.getBoundingClientRect();
            let parent = element.parentElement;

            while (parent) {
                const parentRect = parent.getBoundingClientRect();
                const overflowStyle = window.getComputedStyle(parent).overflow;

                // Проверка стиля overflow родителя
                if (overflowStyle === 'hidden' || overflowStyle === 'auto' || overflowStyle === 'scroll') {
                    rect = {
                        top: Math.max(rect.top, parentRect.top),
                        right: Math.min(rect.right, parentRect.right),
                        bottom: Math.min(rect.bottom, parentRect.bottom),
                        left: Math.max(rect.left, parentRect.left),
                        width: Math.max(0, Math.min(rect.right, parentRect.right) - Math.max(rect.left, parentRect.left)),
                        height: Math.max(0, Math.min(rect.bottom, parentRect.bottom) - Math.max(rect.top, parentRect.top))
                    };
                }

                parent = parent.parentElement;
            }

            return rect;
        },
        collapsed_rowGroup(item_index, isCollapsed=true, justRefresh=false) {
            let item_from = this.data[item_index]
            item_from.isCollapsed = isCollapsed
            let optionally_collapse = []
            for (let i=item_index+1; i<=item_from.collapseTo; i++) {
                let row = this.flex.rows[i+this.headerWijmoRows]
                let dataItem = this.data[i]
                if (row.visible != !isCollapsed) {
                    row.visible = !isCollapsed
                }
                if (!justRefresh && !isCollapsed && 'isCollapsed' in dataItem) { //  && row.icol_group+1 == row_from.icol_group
                    if (dataItem.isCollapsed) {
                        i = dataItem.collapseTo
                    } else {
                        optionally_collapse.push(i)
                    }
                }
            }
            for (let i of optionally_collapse) {
                this.collapsed_rowGroup(i)
            }
        },
        collapsed_columnGroup(col_binding, isCollapsed=true, justRefresh=false) {
            let attr_from = this.attr_by_binding[col_binding]
            attr_from.isCollapsed = isCollapsed
            for (let icol=attr_from.collapseFrom+1; icol<=attr_from.collapseTo; icol++) {
                let col = this.flex.columns[icol]
                if (col.visible != !isCollapsed) {
                    col.visible = !isCollapsed
                }
            }
        },
        onButtonСhoiceStart__Click(cell) {
            let item_value, attr_col,
            dataItem = this.flex.rows[cell.row].dataItem,
            item_attr = attr_col = this.attr_by_binding[cell.col_binding]
            if (attr_col.attr_type === 'AttrMulti') {
                item_attr = dataItem[attr_col.binding]

                item_value = item_attr.value
            } else {
                item_value = this.flex.getCellData(cell.row, cell.col)
            }

            this.store.open_widgetList_forAttr(item_value, 
                {...this.attr, attr_col:item_attr, attr_col2:attr_col, cell:cell}, 
                {command_Choice:true}, 
                this
            )
        },
        choiceFinish(parent_attr, chosenItem) {
            let attr_col = parent_attr.attr_col2,
            cell = parent_attr.cell,
            dataItem = this.get_dataItem(cell.row, cell.col_binding, ''), // this.flex.rows[cell.row].dataItem,
            item_attr = attr_col

            this.cellEditEnding2(cell, chosenItem.id, 'choiceFinish')

            if (attr_col.attr_type === 'AttrMulti') {
                item_attr = dataItem[attr_col.binding]

                item_attr.value = chosenItem.id
                item_attr.valueStr = chosenItem.title || chosenItem.id
                delete item_attr.is_customValueWarning
                if (this.flex.activeEditor) {
                    this.flex.activeEditor.value = item_attr.valueStr
                }

                this.relation_attr_set(item_attr) // TODO set_newVal > undoStack

                this.flex.setCellData(cell.row, cell.col, item_attr.valueStr)
            } else {
                this.flex.setCellData(cell.row, cell.col, chosenItem.id)
                dataItem[cell.col_binding] = chosenItem.id
                if (this.flex.activeEditor) {
                    this.flex.activeEditor.value = chosenItem.id
                }
            }
            this.cellEditEnded2(cell)
        },
        get_dataItem(row, col_binding=null, newVal='') {
            if (!this.flex.rows[row]?.dataItem) { // this.flex.rows[row].dataIndex === -1 is just for plain data
                let dataItem = this.attr.default_row || {}
                if (col_binding) {
                    this.store.doc_set(dataItem, col_binding, newVal)
                }
                this.flex.itemsSource.addNew(dataItem)
                this.flex.refresh()
                // this.isReadOnly_false()
                return dataItem
            }
            return this.flex.rows[row].dataItem
        },
        onSelector__Click(cell, checked, e) {
            const targetRow = cell.irow,
            binding = cell.binding,
            attr_col = this.attr_by_binding[binding] || {},
            dataItem = this.get_dataItem(cell.irow)

            if (e.shiftKey) {
                let lastSelectedRow = this.flex.rows.map((item, index) => item.dataItem?.[binding] ? index : -1).filter(index => index !== -1)
                if (lastSelectedRow.length === 0) lastSelectedRow = [targetRow]

                const start = Math.min(...lastSelectedRow, targetRow)
                const end = Math.max(...lastSelectedRow, targetRow)

                this.flex.rows.forEach((item, index) => {
                    if (item.dataItem) {
                        item.dataItem[binding] = index >= start && index <= end ? checked : false
                    }
                })
            } else if (e.ctrlKey || e.altKey || e.metaKey || binding === 'use') {
                this.flex.rows[targetRow].dataItem[binding] = checked
            } else {
                this.flex.rows.forEach((item, index) => {
                    if (item.dataItem) {
                        item.dataItem[binding] = checked && index === targetRow
                    }
                })
            }

            // selector
            if (attr_col.action_afterChange?.command === 'use_selectorMeasures') {
                this.use_selectorMeasures()
            } else if (attr_col.action_afterChange) {
                this.store.executeStoreMethod(this.WIDGET.vueObj, attr_col.action_afterChange)
                // if (binding === 'selector') {
                //     this.store.executeStoreMethod(this, { 'command': 'onApplySelector__Click' } )
                // }
            }

            this.flex.refresh()
        },
        onSelectorKPI__Click(cell, checked) {
            // console.log(cell, checked)
            let row = this.flex.rows[cell.irow],
            dataItem = row.dataItem,
            cell_doc = dataItem[cell.binding_doc]

            cell_doc.selector = checked
            this.store.executeStoreMethod(this.WIDGET.vueObj, { 'command': 'onApplySelector__Click' })
        },
        onButtonKPI__Click(cell) {
            let row = this.flex.rows[cell.irow],
            dataItem = row.dataItem,
            cell_doc = dataItem[cell.binding_doc]

            this.store.executeStoreMethod(this.WIDGET.vueObj, cell_doc.action)
        },
        pasting(flex, e) {
            this.get_dataItem(e.row) // LOOK to add new row
        },
        rowAdded(flex, e) {
            let dataItem = this.flex.rows[e.row].dataItem
            for (let binding in (this.attr.default_row || {})) {
                if (!(binding in dataItem)) {
                    this.store.doc_set(dataItem, binding, this.attr.default_row[binding])
                }
            }
        },
        pastingCell(flex, e) {
            this.cellEditEnding2(e, e.data, 'pastingCell')

            this.saveVisibility()
            this.need_restoreVisibility = true
        },
        pastedCell(flex, e) {
            this.cellEditEnded2(e)
            this.isReadOnly_false()
        },
        updatingView(flex, e) {
            if (this.need_aggregate) {
                this.aggregateFront()
                this.actionStack++
                this.need_aggregate = false
            }
            if (this.need_restoreVisibility && this.rowLevelGroup_ranges_length) {
                this.restoreVisibility()
                this.need_restoreVisibility = false
            } else if (this.need_collapsedAll) {
                console.log('updatingView+need_collapsedAll')
                this.need_collapsedAll = false
                this.collapsedAll(false, true)
            }
        },
        saveVisibility() {
            // this.columnsVisibility = []
            // this.rowsVisibility = []
            
            // for (let col of this.flex.columns) {
            //     this.columnsVisibility.push(col.visible)
            // }
            // for (let row of this.flex.rows) {
            //     this.rowsVisibility.push(row.visible)
            // }
        },
        restoreVisibility() {
            // for (let icol in this.columnsVisibility) {
            //     if (this.flex.columns[icol].visible != this.columnsVisibility[icol]) {
            //         this.flex.columns[icol].visible = this.columnsVisibility[icol]
            //     }
            // }
            // for (let irow in this.rowsVisibility) {
            //     if (this.flex.rows[irow].visible != this.rowsVisibility[irow]) {
            //         this.flex.rows[irow].visible = this.rowsVisibility[irow]
            //     }
            // }

            // this.columnsVisibility = []
            // this.rowsVisibility = []
        },
        updatingLayout(flex, e) {
            // console.log('updatingLayout')
        },
        updatedLayout(flex, e) {
            // console.log('updatedLayout')
            this.attrResize()
        },
        updatedView(flex, e) {
            // console.log('updatedView')
        },
        itemsSourceChanged(flex, e) {
            this.isReadOnly_false()
        },
        isReadOnly_false() { 
            // need if set childItemsPath
            if (this.attr.childItemsPath) {
                this.flex.rows.forEach(row => {
                    if (row.isReadOnly !== false) {
                        row.isReadOnly = false
                    }
                })
            }
        },
        value_multi_click(cell) {
            if (this.value_multi_inEdit.row != null) {
                console.log('To select, first complete the edit')
                return
            }

            this.value_multi_inEdit = cell
            let dataItem = this.flex.rows[cell.irow].dataItem
            this.flex.setCellData(cell.row, cell.col, dataItem[cell.binding].values[this.value_multi_inEdit.ivalue].title)

            // console.log(this.flex.onBeginningEdit(new wjGrid.CellRangeEventArgs(this.flex, new wjGrid.CellRange(cell.row, cell.col))))
        },
        beginningEdit(flex, e) {
            this.cell_oldVal = this.flex.getCellData(e.row, e.col)
            // wjcCore.removeClass(e.cell, 'cell-zero')
        },
        prepareCellForEdit(flex, e) { // this after formatItem
            let col = flex.columns[e.col],
            irow = e.row,
            col_binding = col.binding,
            row = flex.rows[irow],
            dataItem = row.dataItem,
            attr_col = this.attr_by_binding[col_binding],
            WIDGET = this.WIDGET
            
            if (row.isReadOnly !== false) {
                row.isReadOnly = false
            }

            if (this.flex.activeEditor) {
                this.flex.activeEditor.addEventListener('input', function(event) {
                    const popupTab = WIDGET.attrs_vueObj?.WIDGET?.popupTab
                    if (popupTab?.isVisible && WIDGET.popupTab.parent_attr.attr_col2 === attr_col) {
                        let valueStr = event.target.value
                        // if (valueStr) {
                            WIDGET.popupTab.attr_popupTab_grid_list_vueObj.filterChips = valueStr.split(' ')
                            WIDGET.popupTab.attr_popupTab_grid_list_vueObj.apply_filterChips()
                        // }
                    }
                })
            }

            if (attr_col && dataItem && attr_col.component == 'AttrMulti_OLD') { // this.attr.name == 'viewWHERE' && attr_col.binding == 'rightValue'
                let value_multi = null
                if (dataItem[attr_col.binding].isList) {
                    if (this.value_multi_inEdit.row == null) {
                        value_multi = {
                            value: '',
                            title: '',
                        }
                    } else {
                        value_multi = {
                            value: dataItem[attr_col.binding].values[this.value_multi_inEdit.ivalue].value,
                            title: dataItem[attr_col.binding].values[this.value_multi_inEdit.ivalue].title,
                        }
                    }
                    // flex.activeEditor.value = value_multi.title
                } else {
                    value_multi = {
                        value: dataItem[attr_col.binding].value,
                        title: dataItem[attr_col.binding].title,
                    }
                }
                // console.log(value_multi)
                
                if (dataItem[attr_col.binding].component === 'AttrLink') {
                    this.activeEditor_AttrMulti_OLD = new AutoComplete(flex.activeEditor, {
                        itemsSource: this.store.itemsSource[dataItem[attr_col.binding].relation_segment].data,
                        selectedValuePath: 'id',
                        displayMemberPath: 'title',
                    })
                    this.activeEditor_AttrMulti_OLD.selectedValue = value_multi.value

                // } else if (dataItem[attr_col.binding].component === 'AttrDate') {
                //     this.activeEditor_AttrMulti_OLD = new InputDate(flex.activeEditor)
                //     this.activeEditor_AttrMulti_OLD.value = dataItem[attr_col.binding]
                // } else if (dataItem[attr_col.binding].component === 'AttrNumber') {
                //     this.activeEditor_AttrMulti_OLD = new InputNumber(flex.activeEditor, {
                //         // format: 'n2',
                //     })
                //     this.activeEditor_AttrMulti_OLD.value = dataItem[attr_col.binding]
                } else {
                    this.activeEditor_AttrMulti_OLD = null
                }
            }
        },
        cellEditEnding(flex, e) {
            let newVal = ''
            if (this.flex.activeEditor) {
                if (this.flex.activeEditor.type == 'checkbox') { // 'checked' in this.flex.activeEditor
                    newVal = this.flex.activeEditor.checked
                } else {
                    newVal = this.flex.activeEditor.value
                }
            }
            this.cellEditEnding2(e, newVal, 'cellEditEnding')
        },
        cellEditEnded(flex, e) {
            this.cellEditEnded2(e)
        },
        cellEditEnding2(e, newVal, parent_method) {
            let oldVal = this.flex.getCellData(e.row, e.col),
            col = this.flex.columns[e.col],
            attr_col = this.attr_by_binding[col.binding],
            irow = e.row - this.headerWijmoRows, // this.flex.collectionView.currentPosition,
            col_binding = col.binding

            if (parent_method == 'pastingCell') {
                this.pastedVal = newVal
            } else {
                this.pastedVal = null
            }

            // oldVal if dell cell
            if (this.cell_oldVal!=null) {
                oldVal = this.cell_oldVal
            }
            this.cell_oldVal = null

            let dataItem = this.get_dataItem(irow, col_binding, newVal)
            // let dataItem = {}
            // if (this.flex.rows[irow]) {
            //     dataItem = this.flex.rows[irow].dataItem // this.flex.collectionView.items[irow], 
            // } else {
            //     this.store.doc_set(dataItem, col_binding, newVal)
            // }

            let item_attr = attr_col
            if (attr_col.attr_type === 'AttrMulti') {
                item_attr = dataItem[attr_col.binding]
                if (!item_attr) {
                    item_attr = dataItem[attr_col.binding] = this.store.new_valueMulti(attr_col)
                }
            }

            // prepare newVal (convert to type)
            if (item_attr.component === 'AttrMulti_OLD') {
                let dataItem = this.flex.rows[irow].dataItem
                
                let value_multi = null
                if (this.activeEditor_AttrMulti_OLD != null) {
                    if (this.activeEditor_AttrMulti_OLD.selectedValue) {
                        value_multi = {
                            value: this.activeEditor_AttrMulti_OLD.selectedValue,
                            title: this.activeEditor_AttrMulti_OLD.text,
                        }
                    } else {
                        value_multi = {
                            value: '',
                            title: '',
                        }
                    }
                } else {
                    value_multi = {
                        value: newVal,
                        title: newVal,
                    }
                }

                if (!dataItem[item_attr.binding]) {
                    dataItem[item_attr.binding] = {}
                }
                if (dataItem[item_attr.binding].isList) {
                    if (!dataItem[item_attr.binding].values) {
                        dataItem[item_attr.binding].values = []
                    }
                    if (this.value_multi_inEdit.row == null) {
                        if (value_multi.value) {
                            dataItem[item_attr.binding].values.push(value_multi)
                        } // else pass
                    } else {
                        if (value_multi.value) {
                            dataItem[item_attr.binding].values[this.value_multi_inEdit.ivalue].value = value_multi.value
                            dataItem[item_attr.binding].values[this.value_multi_inEdit.ivalue].title = value_multi.title
                        } else {
                            dataItem[item_attr.binding].values.splice(this.value_multi_inEdit.ivalue, 1)
                        }
                    }
                    this.value_multi_inEdit = {}
                    this.flex.activeEditor.value = ''
                    this.flex.setCellData(e.row, e.col, '') // for esc
                } else {
                    dataItem[item_attr.binding].value = value_multi.value
                    dataItem[item_attr.binding].title = value_multi.title

                    this.flex.activeEditor.value = value_multi.title
                    this.flex.setCellData(e.row, e.col, value_multi.title)
                }
            } else if (item_attr.component === 'AttrNumber') {
                if (typeof newVal === 'string') {
                    newVal = +newVal.replace(/,/g, '').replace(' ', '') || 0 // Math.max(0,+newVal)
                }
            } else if (item_attr.component === 'AttrStr') {
                newVal = '' + newVal
            } else if (item_attr.component === 'AttrBool') {
                this.AttrLink_oldVal = oldVal
            } else if (item_attr.component === 'AttrDate') {
                let date = this.store.parseDateString(newVal)
                if (date) {
                    newVal = wjcCore.Globalize.formatDate(date, 'yyyy-MM-dd') // input.value.toISOString()
                } else {
                    newVal = null
                }
            } else if (item_attr.component === 'AttrTime') {
                // newVal = new Date('1970-01-01T' + newVal + 'Z').toISOString().substr(11, 8)
            } else if (item_attr.component === 'AttrLink') {
                this.AttrLink_oldVal = oldVal
                
                if (attr_col.attr_type === 'AttrMulti') {
                    let V = item_attr
                    if (newVal === '') {
                        V.value = ''
                        V.valueStr = newVal = ''
                        delete V.is_customValueWarning
                    } else {
                        let parentsList = this.get_parentsList(irow, attr_col)
                        if (this.store.is_value_eval(newVal)) {
                            V.value = newVal
                            V.valueStr = newVal
                            delete V.is_customValueWarning
                        } else {
                            let valueMulti = this.store.get_valueMulti_byStr(this, newVal, V, attr_col, oldVal, parentsList)
                            if (valueMulti === null && attr_col.customValueWarning) {
                                V.value = newVal
                                V.valueStr = newVal
                                V.is_customValueWarning = !!newVal
                            } else if (valueMulti === null) {
                                V.value = ''
                                V.valueStr = newVal = ''
                                delete V.is_customValueWarning
                            } else {
                                V.value = valueMulti.value
                                V.valueStr = newVal = valueMulti.valueStr
                                delete V.is_customValueWarning
                            }
                        }
                    }
                } else  if (item_attr.relation_segment && newVal != '') { // parent_method == 'pastingCell' && 
                    let itemsSource = this.store.itemsSource[item_attr.relation_segment]
                    if ((oldVal in itemsSource.ids)) {
                        if (newVal == itemsSource.ids[oldVal]) {
                            return
                        }
                    }
                    if (!(newVal in itemsSource.ids)) {
                        let newVal2 = null
                        for (let item0 of itemsSource.data) {
                            if (newVal == item0.title) {
                                newVal2 = item0.id
                                break
                            }
                            if (newVal == item0.code) {
                                newVal2 = item0.id
                                break
                            }
                            if (newVal2 == null && item0.title.includes(newVal)) {
                                newVal2 = item0.id
                            }
                        }
                        if (newVal2 != null) {
                            newVal = newVal2
                        } else { 
                            //so that the insertion packet is not lost if the value from the first cell is not found;
                            // e.g. when inserting an area with new lines added
                            newVal = ''
                            // this.set_newVal(irow, dataItem, col_binding, oldVal, newVal)
                        }
                    }
                }
            }
            this.newVal = newVal

            // e.cancel = true    // is bad for pasting in new row

            // refresh prepered value
            if (item_attr.component!='AttrBool' && parent_method != 'choiceFinish') {
                if (parent_method == 'pastingCell') {
                    e.data = newVal
                } if (parent_method == 'cellEditEnding' && this.flex.activeEditor) {
                    this.flex.activeEditor.value = newVal
                }
            }

            // check
            if (['AttrBool', 'AttrLink', 'AttrMulti_OLD'].includes(item_attr.component)) {
                return
            }
            if (oldVal == newVal) {
                return
            } else if (irow < this.headerRows - this.headerWijmoRows || e.col < this.headerColumns) {
                return
            } else if (item_attr.component === 'AttrNumber') {
                if (isNaN(newVal)) {
                    return
                }
            }

            this.set_newVal(irow, dataItem, col_binding, oldVal, newVal)

            this.actionStack++
        },
        cellEditEnded2(e) {
            let newVal = this.flex.getCellData(e.row, e.col), // this.flex.activeEditor.value
            col = this.flex.columns[e.col],
            attr_col = this.attr_by_binding[col.binding],
            irow = e.row - this.headerWijmoRows, // this.flex.collectionView.currentPosition, 
            // dataItem = this.flex.rows[irow].dataItem, // this.flex.collectionView.items[irow], 
            col_binding = col.binding

            let dataItem = {}
            if (this.flex.rows[irow]) {
                dataItem = this.flex.rows[irow].dataItem // this.flex.collectionView.items[irow], 
            }

            let item_attr = attr_col
            if (attr_col.attr_type === 'AttrMulti') {
                item_attr = this.store.check_AttrMulti(attr_col, dataItem)
            }

            if (!['AttrBool', 'AttrLink'].includes(item_attr.component)) {
                return
            }
            
            if (!newVal) {
                newVal = this.newVal
            }

            if (item_attr.component === 'AttrBool') {
                newVal = Boolean(newVal)
            }
            console.log('cellEditEnded2')

            // check
            if (this.AttrLink_oldVal == newVal) {
                return
            } else if (irow < this.headerRows - this.headerWijmoRows || e.col < this.headerColumns) {
                return
            }

            if (!this.flex.rows[irow]) {
                this.store.doc_set(dataItem, col_binding, newVal)
            }

            this.set_newVal(irow, dataItem, col_binding, this.AttrLink_oldVal, newVal)
            this.actionStack++
        },
        get_cell(irow, icol) {
            let attr_col = this.get_attr_col(icol),
                row = this.flex.rows[irow],
                attr_row = this.get_attr_row(irow)

            // scan attr_parents
            let irow2 = irow,
                level2 = row.level
            while (irow2 > 0 && level2 > 0) {
                irow2--
                let row2 = this.flex.rows[irow2]
                if (row2.level == level2 - 1) {
                    (attr_row.attr_parents = attr_row.attr_parents || []).push(this.get_attr_row(irow2))
                    level2--
                }
            }
            
            return {
                icol: icol,
                irow: irow,
                attr_col: attr_col,
                attr_row: attr_row,
                value: this.flex.getCellData(irow, icol), 
            }
        },
        get_attr_row(irow) {
            let row = this.flex.rows[irow],
            dataItem = row.dataItem,
            attr_row = {}
                
            attr_row.level = row.level
            if (dataItem.MD && dataItem.MD['full_index-data_layaut']) {
                attr_row['full_index-data_layaut'] = dataItem.MD['full_index-data_layaut']
            }
            for (let binding of this.attr.row_bindings) {
                if (binding in dataItem) {
                    attr_row[binding] = dataItem[binding]
                }
            }

            return attr_row
        },
        set_newVal(irow, dataItem, col_binding, oldVal, newVal, changeStack=true) {
            // let oldVal = dataItem[col_binding],
            let attr_col = this.attr_by_binding[col_binding] || {}

            if (oldVal==newVal) {
                return
            }

            // if (col_binding==='selector') {
            //     this.flex.rows[irow].isSelected = newVal
            // }

            // undoStack
            if (changeStack && col_binding!='visibleChart' && col_binding!=='selector' && col_binding!='visibleChart') {
                const startStack_length = this.undoStack.length + this.redoStack.length
                let rowStack = {
                    actionStack: this.actionStack,
                    // i: this.data.indexOf(dataItem),
                    irow: irow,
                    $key: dataItem.$key,
                    col_binding: col_binding, 
                    val: oldVal,
                    newVal: newVal,
                    measure: dataItem.measure||attr_col.measure||'',
                    date: dataItem.date||attr_col.date||'',
                }
                if (this.pastedVal != null) {
                    rowStack.pastedVal = this.pastedVal
                }
                this.undoStack.push(rowStack)
                this.changesStack[`${rowStack.irow}:${rowStack.col_binding}`] = rowStack //.val
                this.redoStack.length = 0
                if (!this.flex_isEditing) {
                    this.flex_isEditing = true
                }
                if (!startStack_length) {
                    this.set_commandPanel()
                }
            }

            // set new value
            let item_attr = attr_col
            if (attr_col.attr_type === 'AttrMulti') {
                item_attr = this.store.check_AttrMulti(attr_col, dataItem)
                if (attr_col.attr_type === 'AttrMulti' && item_attr.component !== 'AttrLink') {
                    item_attr.value = item_attr.valueStr = newVal
                }
                this.relation_attr_set(dataItem[attr_col.binding])
            } else {
                this.store.doc_set(dataItem, col_binding, newVal) // dataItem[col_binding] = newVal
            }

            // MANUF-BATCH
            if (this.attr.executeWidgetMethod_afterEdit && col_binding != 'selector') { // this.WIDGET.doc.type == 'WIDGET-PLAN'
                let cell = this.get_cell(irow, attr_col.icol)
                cell.oldVal = oldVal

                this.backendStack[`${irow}:${col_binding}`] = cell
                this.processBackend()
                                
                // this.store.executeStoreMethod(this.WIDGET.vueObj, { 
                //     command: 'executeWidgetMethod', 
                //     method: 'save',
                //     updateDoc_ifChanged: true,
                //     binding: 'dataSheet',
                //     doc_sendToBackend_undoStack: true,
                // })
                
                // // if (this.WIDGET.doc.params.show_Gantt) {
                // //     manuf_date_from = attr_col.date_from

                // //     row_manuf.duration = Math.round((row_manuf.manuf_quantity / row_data.productivity) * 3600);

                // //     const manufDateFrom = new Date(row_manuf.manuf_date_from);
                // //     const manufDateTo = new Date(manufDateFrom.getTime() + (row_manuf.duration - 1) * 1000);
                // //     row_manuf.manuf_date_to = manufDateTo.toISOString();

                // //     let manuf_date_from = new Date(attr_col.date_from);
                // //     let manuf_date_to = new Date(batch.data_manuf.manuf_date_to);
                // //     let left = Math.round(((manuf_date_from - attr_col.date_from) / 1000) / attr_col.duration * 100);
                // //     let right = Math.round(((manuf_date_to - attr_col.date_from) / 1000) / attr_col.duration * 100);
                // //     let top = 20;
                // //     if (left < 100) {
                // //         let bar = {
                // //             batch_id: batch.batch_id,
                // //             batch_number: batch.batch_number,
                // //             workcenter: batch.data_manuf.workcenter,
                // //             from: batch.data_manuf.manuf_date_from,
                // //             to: batch.data_manuf.manuf_date_to,
                // //             cssText: `
                // //                 top: ${top}%;
                // //                 width: ${right - left}%;
                // //                 left: ${left}%;
                // //                 background-color: ${batch.batch_color};
                // //             `
                // //         };

                // //         // workcenter
                // //         if (show_Gantt__workCenter) {
                // //             if (!(date_binding in data_keys[key_workCenter]['$gantt'])) {
                // //                 data_keys[key_workCenter]['$gantt'][date_binding] = [];
                // //             }
                // //             data_keys[key_workCenter]['$gantt'][date_binding].push(bar);
                // //         }

                // //         // batch_object
                // //         if (key_batch_object) {
                // //             let batch_bar = {...bar};
                // //             let level = batch.data_manuf.hasOwnProperty('level') ? batch.data_manuf.level : 0;
                // //             batch_bar.cssText = `
                // //                 top: ${10 + level * 25}%;
                // //                 width: ${right - left}%;
                // //                 left: ${left}%;
                // //                 height: 20%;
                // //                 background-color: lightslategray;
                // //                 opacity: .3;
                // //             `;
                // //             if (!(date_binding in data_keys[key_batch_object]['$gantt'])) {
                // //                 data_keys[key_batch_object]['$gantt'][date_binding] = [];
                // //             }
                // //             data_keys[key_batch_object]['$gantt'][date_binding].push(batch_bar);
                // //         }

                // //         // workcenter + manuf_object
                // //         if (!(date_binding in data_keys[key_manuf_object]['$gantt'])) {
                // //             data_keys[key_manuf_object]['$gantt'][date_binding] = [];
                // //         }
                // //         data_keys[key_manuf_object]['$gantt'][date_binding].push(bar);
                // //     }

                // // }
            }

            if (col_binding=='visibleChart') {
                let visibleChart = newVal
                let attrChart= this.store.attr_find_by_keys(this.WIDGET.attrs, 'dataChart')
                if (attrChart) {
                    let flexChart = this.WIDGET.attrs_vueObj[attrChart.id].flex
                    for (let series of flexChart.series) {
                        if (series.binding==dataItem.measure) {
                            series.visibility = visibleChart ? 0 : 2 // hide
                            break
                        }
                    }
                    for (let m of this.WIDGET.doc.measures||[]) {
                        if (m.measure==dataItem.measure) {
                            m.visibleChart = visibleChart
                            break
                        }
                    }
                }
                return
            }

            // transpose
            if (this.attr.transpose && this.attr.binding_transpose && 'irow_tranpose' in attr_col) {
                let irow_tranpose = attr_col.irow_tranpose

                this.store.doc_set(this.WIDGET.doc, this.attr.binding_transpose, newVal, irow_tranpose, dataItem.measure)
            }

            if (this.attr.refreshAttr_AfterChange) {
                let attr_vueObj = this.WIDGET.vueObj.active_attr_vueObj(this.attr.refreshAttr_AfterChange)
                if (attr_vueObj.refresh) {
                    attr_vueObj.refresh()
                }
            }

            if (attr_col.component === 'AttrNumber') {
                let _quantity = attr_col.binding_quantity,
                    _sum = attr_col.binding_sum,
                    _price = attr_col.binding_price,
                    _calculation = attr_col.type_of_quantity_calculation

                // calculate
                if (_sum && _quantity && _price) {
                    if (_calculation=='sum=quantity*price') {
                        if (col_binding==_quantity || col_binding==_price) {
                            // sum=quantity*price
                            dataItem[_sum] = (dataItem[_quantity] * dataItem[_price]).toFixed(0)
                        } else if (col_binding==_sum) {
                            if (dataItem[_price]) {
                                // quantity=sum/price
                                dataItem[_quantity] = (dataItem[_sum] / dataItem[_price]).toFixed(0)
                            } else if (dataItem[_quantity]) {
                                // price=sum/quantity
                                dataItem[_price] = (dataItem[_sum] / dataItem[_quantity]).toFixed(3)
                            }
                        }
                    }
                } else if (_sum && _quantity) {
                    if (_calculation=='sum=quantity*price') {
                        if (col_binding==_quantity) {
                            let old_quantity = oldVal
                            if (dataItem[_sum] && old_quantity) {
                                let price = dataItem[_sum] / old_quantity
                                // sum=quantity*price
                                dataItem[_sum] = (dataItem[_quantity] * price).toFixed(0)
                            }
                        } else if (col_binding==_sum) {
                            let old_sum = oldVal
                            if (old_sum && dataItem[_quantity]) {
                                let price = old_sum / dataItem[_quantity]
                                if (price) {
                                    // quantity=sum/price
                                    dataItem[_quantity] = (dataItem[_sum] / price).toFixed(0)
                                }
                            }
                        }
                    }
                }
            }

            // selector
            if (item_attr.action_afterChange) {
                this.store.executeStoreMethod(this.WIDGET.vueObj, {
                    ...item_attr.action_afterChange,
                    actioinFrom_attr: item_attr,
                })
            } else if (attr_col.action_afterChange) {
                this.store.executeStoreMethod(this.WIDGET.vueObj, {
                    ...attr_col.action_afterChange,
                    actioinFrom_attr: item_attr,
                })
            }

            if (this.WIDGET.widget_class == 'widget_PARAMS') {
                this.store.rightArea_editEnded(this.WIDGET, this.attr)
            }
        },

        // ---------------------- AttrTabGrid ----------------------------------
        attrsTabGrid_from_attrs(attrsTabGrid, rows, parentsList=[]) {
            let attr_col = {binding:'attr'}
            attrsTabGrid.forEach(attr => {
                if (attr.use !== false) {
                    let item_attr = {
                        ...attr,
                        attrs: [], // del
                        // title
                        // value
                        // valueStr
                    },
                    dataItem = { [attr_col.binding]: item_attr }
                    rows.push(dataItem)

                    this.store.prepare_AttrMulti(this, attr_col, dataItem, parentsList)

                    if (item_attr.attr_type === 'AttrJSON') {
                        item_attr.valueStr = ''
                        dataItem.cssClass = 'row-label'
                        dataItem.MD = { cssClass: {'attr.title':'row-label-title json-key'} }
                        dataItem.attrs = []
                        this.attrsTabGrid_from_JSON(item_attr.value, dataItem.attrs)
                    
                    } else if (item_attr.attr_type === 'AttrGrid') {
                        item_attr.valueStr = ''
                        dataItem.cssClass = 'row-label'
                        dataItem.MD = { cssClass: {'attr.title':'row-label-title json-key'} }
                        dataItem.attrs = []
                        this.attrsTabGrid_from_JSON(item_attr.value, dataItem.attrs)
                    
                    } else if (attr.attrs?.length) { // AttrList
                        dataItem.cssClass = 'row-label'
                        dataItem.MD = { cssClass: {'attr.title':'row-label-title'} }
                        dataItem.attrs = []
                        parentsList.unshift(attr)
                        this.attrsTabGrid_from_attrs(attr.attrs, dataItem.attrs, parentsList)
                        parentsList.shift()
                    }
                }
            })
        },
        attrsTabGrid_from_JSON(valueJSON, rows) {
            let attr_col = {binding:'attr'}
            for (let key in valueJSON) {
                let value = valueJSON[key],
                item_attr = {
                    title: key,
                    value: value,
                    valueStr: `${value}`,
                },
                dataItem = { 
                    [attr_col.binding]: item_attr,
                    MD: { cssClass: {'attr.title':'json-key2'} },
                }
                rows.push(dataItem)

                if (Array.isArray(valueJSON)) {
                    item_attr.title = `[${item_attr.title}]`
                    dataItem.MD = { cssClass: {'attr.title':'json-array-key'} }
                }

                if (typeof value === 'object' && value !== null) {
                    item_attr.valueStr = ''
                    dataItem.cssClass = 'row-label'
                    dataItem.MD.cssClass['attr.valueStr'] = 'json-value-title'
                    dataItem.attrs = []
                    this.attrsTabGrid_from_JSON(value, dataItem.attrs)
                } else {
                    // dataItem.MD = { cssClass: {'attr.valueStr':'json-value'} }
                }
            }
        },
        attrsTabGrid_to_JSON(dataItems, colValue='value', showEmpty=true, keyTitle={}) {
            let attr_col = {binding:'attr'},
            valueJSON = {}
            if (Array.isArray(dataItems)) {
                for (let i in dataItems) {
                    let dataItem = dataItems[i],
                    item_attr = dataItem[attr_col.binding],
                    key = item_attr.title,
                    value = item_attr[colValue]
                    
                    if (dataItem[this.attr.childItemsPath]?.length) {
                        value = this.attrsTabGrid_to_JSON(dataItem[this.attr.childItemsPath], colValue)
                    }
                    
                    if (value || showEmpty) {
                        valueJSON[key] = value
                    }
                }
            } else {
                for (let dataItem in dataItems) {
                    let item_attr = dataItem[attr_col.binding],
                    key = item_attr.title,
                    value = item_attr.value

                    valueJSON[key] = value

                    if (dataItem[this.attr.childItemsPath]?.length) {
                        valueJSON[key] = this.attrsTabGrid_to_JSON(dataItem[this.attr.childItemsPath], colValue)
                    }
                }
            }
            return valueJSON
        },
        relation_attr_set(item_attr) {
            if (item_attr?.binding) {
                this.store.attr_set(this.WIDGET, item_attr, item_attr.value)
            } 
        },
        // checks_AttrMulti() {
        //     return {
        //         title:'',
        //         value:'',
        //         valueStr:'',
        //         attr_type:'AttrStr',
        //         component:'AttrStr',
        //     }
        // },

        processBackend() {
            console.log('processBackend()')
            if (!this.backendProcessed && !this.store.isEmpty(this.backendStack)) {
                let selectedRanges = []
                for (let key in this.backendStack) {
                    selectedRanges.push(this.backendStack[key])
                }
                
                this.store.executeStoreMethod(this.WIDGET.vueObj, { 
                    command: 'executeWidgetMethod', 
                    method: this.attr.executeWidgetMethod_afterEdit, // 'save_cell',
                    doc_sendToBackend_exclude: ['data'],
                    selectedRanges: selectedRanges,
                    action_after: {
                        command: 'executeVueObjMethod', 
                        method: 'processBackend_Ended',
                        binding: this.attr.binding,
                    },
                })

                this.backendProcessed = true
                this.backendStack = {}
            }
        },
        processBackend_Ended() {
            console.log('processBackend_Ended()')
            this.backendProcessed = false
            if (!this.store.isEmpty(this.backendStack)) {
                this.processBackend()
            } else {
                this.store.executeStoreMethod(this.WIDGET.vueObj, { 
                    command: 'executeWidgetMethod',
                    method: 'select', 
                    updateDoc_ifChanged:true,
                    doc_sendToBackend_exclude: ['data'],
                    action_after: {
                        command: 'updateRelatedWidgets', 
                        // updateDoc_ifChanged: true,
                    },
                })
            }
        },
        aggregateFront() {
            // + formulas
            console.time("aggregateFront"); let countFormulas = 0
            for (let attr_col of this.attr.attrs) {
                if (attr_col.aggregateFront) {
                    let col_binding = attr_col.binding
                    for (let i=0; i<this.data.length; i++) {
                        let dataItem = this.data[i]
                        if (dataItem.levelGroup>0 && dataItem.levelGroup<99) {
                            // sum()
                            // this.flex.setCellData(i, 2, "=sum(B2:D4)")
                            let i_next = i + 1,
                                sum = 0
                            while(i_next < this.data.length && dataItem.levelGroup != this.data[i_next].levelGroup) {
                                if (this.data[i_next].levelGroup == 0) {
                                    sum += +this.data[i_next][col_binding]
                                }
                                i_next++
                                countFormulas++
                            }
                            dataItem[col_binding] = sum
                        }
                    }
                }
            }
            console.timeEnd("aggregateFront"); console.log(`countFormulas: ${countFormulas}`)
        },
        undo_Click() {
            this.changeStack(this.undoStack, this.redoStack)
        },
        redo_Click() {
            this.changeStack(this.redoStack, this.undoStack, 'newVal')
        },
        changeStack(fromStack, toStack, nameVal='val') {
            if (fromStack.length) {
                let actionStack = fromStack[fromStack.length-1].actionStack
                while (fromStack.length && actionStack == fromStack[fromStack.length-1].actionStack) {
                    let a = fromStack.pop(),
                        dataItem = this.flex.rows[a.irow].dataItem, // this.data[a.i],
                        oldVal = dataItem[a.col_binding]
                    this.set_newVal(a.irow, dataItem, a.col_binding, oldVal, a[nameVal], false)
                    toStack.push(a)
                }
                if (this.rowLevelGroup_ranges_length) {
                    this.aggregateFront()
                }
                this.initialized_stackСhanges()
                this.flex.refresh()
            }
        },
        click(e) {
            if (e.target.id) {
                let data_cell = wjcCore.closest(e.target, '.data-cell')?.data_cell
                if (data_cell) {
                    if (e.target.id == "onButtonCollapsed__Click") {
                        this.onButtonCollapsed__Click(data_cell)
                    } else if (e.target.id == "onButtonOpen__Click") {
                        this.onButtonOpen__Click(data_cell, e)
                    } else if (e.target.id == "onButtonSearch__Click") {
                        this.onButtonSearch__Click(data_cell)
                    } else if (e.target.id == "onButtonShowDroppedDown__Click") {
                        this.onButtonShowDroppedDown__Click(data_cell, e)
                    } else if (e.target.id == "onValue_multi__Click") {
                        this.value_multi_click(data_cell)
                    } else if (e.target.id == "onButtonСhoiceStart__Click") {
                        this.onButtonСhoiceStart__Click(data_cell)
                    } else if (e.target.id == "onSelector__Click") {
                        let checkbox = e.target
                        this.onSelector__Click(data_cell, checkbox.checked, e)
                    } else if (e.target.id == "onSelectorKPI__Click") {
                        let checkbox = e.target
                        if (checkbox.checked) {
                            checkbox.classList = ["attr-KPI-selector attr-KPI-selector-on wj-control"]
                        } else {
                            checkbox.classList = ["attr-KPI-selector wj-control"]
                        }
                        this.onSelectorKPI__Click(data_cell, checkbox.checked)
                    } else if (e.target.id == "onButtonKPI__Click") {
                        this.onButtonKPI__Click(data_cell)
                    } else if (e.target.id == "onHeader__Click") {
                        this.isRowHeaderClick = false
                        this.flex.selectedRanges = [new wjGrid.CellRange(data_cell.irow, 0, data_cell.irow, this.flex.columns.length-1)]
                        if (this.attr.groupMenu?.topLeftMenu?.length) {
                            this.store.executeStoreMethod(this, this.attr.groupMenu.topLeftMenu[0])
                        } else {
                            this.dblclick({}, true)
                        }
                    }
                }
            }
        },
        dblclick(m, force=false) {
            let vueObj = this.WIDGET.vueObj,
                col = this.flex.columns[this.flex.selection.col],
                attr_col = this.attr_by_binding[col.binding]
                
            if (this.attr.isReadOnly || attr_col.isReadOnly || force) {
                this.store.dblclick(vueObj, this.attr)
            }
        },
        onClick(actionMenu) {
            let vueObj = this.WIDGET.vueObj

            // Let's leave only the modified lines
            if (actionMenu.method == 'save') {
                const [doc, binding] = this.store.attr_get_link(this.WIDGET, this.attr)
                const indexes = new Set(this.undoStack.map(row => row.i))
                doc[binding] = [...doc[binding].filter((row, index) => indexes.has(index)).map(row => row)]
            }

            vueObj.store.executeStoreMethod(vueObj, actionMenu)
        },
        flex_editing() {
            this.flex.isReadOnly = this.attr.isReadOnly = false
            // this.flex.showMarquee = !this.flex.isReadOnly
            this.flex_isEditing = true
            this.flex.selectionMode = this.attr.selectionMode||'CellRange'
            this.set_commandPanel()

            // show Id, type
            for (let col of this.flex.columns) {
                let attr_col = this.attr_by_binding[col.binding]
                if (attr_col.binding == 'id' || attr_col.binding == 'segment' || attr_col.binding == 'company') {
                    col.visible = true
                    attr_col.visible = true
                }
            }

            // for (attr_col of this.attr.attrs) {
            //     attr_col.isReadOnly = false
            // }
            this.isReadOnly_false()
        },
        activate_allowDraggingRows() {
            this.allowDraggingRows = !this.allowDraggingRows

            let button1 = this.attr.groupMenu.commandPanel.find(item => item.name === 'button-activate_allowDraggingRows')
            button1.active = this.allowDraggingRows

            this.flex.refresh()
        },
        get_parentItems(irow) {
            if (this.attr.childItemsPath) {
                let row = this.flex.rows[irow]
                if (row?.level > 0) {
                    let iparent = irow
                    while (iparent >= 0) {
                        if (row.level-1 === this.flex.rows[iparent].level) {
                            return this.flex.rows[iparent].dataItem[this.attr.childItemsPath]
                        }
                        iparent--
                    }
                }
            }
            return this.data // this.flex.collectionView.items
        },
        get_parentPosition(irow) {
            if (this.attr.childItemsPath) {
                let row = this.flex.rows[irow]
                if (row?.level > 0) {
                    let iparent = irow
                    while (iparent >= 0) {
                        if (row.level-1 === this.flex.rows[iparent].level) {
                            return iparent
                        }
                        iparent--
                    }
                }
            }
            return -1
        },
        get_parentsList(irow, attr_col, parentsList=null) {
            if (!attr_col.is_field_link) {
                return []
            }
            if (!parentsList) {
                parentsList = []
            }
            let parentPosition = this.get_parentPosition(irow)
            if (parentPosition >= 0) {
                let parentRow = this.flex.rows[parentPosition]
                parentsList.push(parentRow.dataItem[attr_col.binding])

                this.get_parentsList(parentPosition, attr_col, parentsList)
            }
            return parentsList
        },
        rows_indexOf(dataItem, start_irow=0) {
            dataItem['$findMarker'] = 1
            for (let irow = start_irow; irow < this.flex.rows.length; irow++) {
                if (this.flex.rows[irow]?.dataItem?.['$findMarker'] === dataItem['$findMarker']) {
                    delete dataItem['$findMarker']
                    return irow
                }
            }
            delete dataItem['$findMarker']
            return -1
        },
        new_Click(e, duplicate=false) {
            let currentPosition = Math.max(0, this.flex.selectedRanges[0].topRow), // this.flex.collectionView.currentPosition // e.row - this.headerWijmoRows
            dataItem = this.flex.rows[currentPosition].dataItem,
            dataItem_dublicate = structuredClone(dataItem),
            parentItems = this.get_parentItems(currentPosition),
            irowItem = parentItems.indexOf(dataItem)

            this.shift_undoStack(currentPosition+1)
            let dataItem_new = {}

            // this.flex.collectionView.items.splice(currentPosition+1, 0, dataItem_new)
            parentItems.splice(irowItem + 1, 0, dataItem_new)

            if (duplicate) {
                for (let col_binding in dataItem_dublicate) {
                    if (col_binding=='id'){
                        continue
                    } else if (col_binding=='title'){
                        dataItem_new[col_binding] = this.store.incrementName(dataItem_dublicate[col_binding])
                    } else {
                        dataItem_new[col_binding] = dataItem_dublicate[col_binding]
                    }
                    if (dataItem_new[col_binding] && dataItem_new[col_binding]!='0') {
                        this.set_newVal(currentPosition, dataItem_new, col_binding, '', dataItem_new[col_binding])
                    }
                }
                this.actionStack++
            }
            this.refresh([dataItem_new])
        },
        shift_undoStack(irow, add=1) {
            for (let i = 0; i < this.undoStack.length; i++) {
                let row = this.undoStack[i];
                if (row.i == irow && add==-1) {
                    this.undoStack.splice(i, 1);
                    i--;
                } else if (row.i >= irow) {
                    row.i += add;
                }
            }
            this.initialized_stackСhanges()
        },
        initialized_stackСhanges() {
            // changesStack
            tooltip.dispose()
            this.changesStack = {}
            for (let i = 0; i < this.undoStack.length; i++) {
                let rowStack = this.undoStack[i];
                this.changesStack[`${rowStack.irow}:${rowStack.col_binding}`] = rowStack
            }
        },
        duplicate_Click(e) {
            let irow = 1
            if (this.flex.selectedRows.length && this.flex.selectedRows[0].index >= this.headerRows) {
                this.new_Click(e,true)
            }
        },
        left_tab_Click(e) {
            let currentPosition = this.flex.selectedRanges[0].topRow,
            parentPosition = this.get_parentPosition(currentPosition)
            
            if (parentPosition >= 0) {
                let parentRow = this.flex.rows[parentPosition],
                parentItem = parentRow.dataItem,
                parentItems0 = this.get_parentItems(parentPosition),
                irowParentItem = parentItems0.indexOf(parentItem)
                
                let movedItems = this.cut_selectedRanges()
                parentItems0.splice(irowParentItem + 1, 0, ...movedItems)

                this.refresh(movedItems)
            }
        },
        right_tab_Click(e) {
            let currentPosition = this.flex.selectedRanges[0].topRow,
            new_parentPosition = currentPosition - 1,
            currentRow = this.flex.rows[currentPosition],
            new_parentRow
            
            while (new_parentPosition >= 0) {
                new_parentRow = this.flex.rows[new_parentPosition]
                if (new_parentRow.level === currentRow.level) {
                    break
                }
                new_parentPosition --
            }

            if (new_parentPosition >= 0) {
                let parentItem = new_parentRow.dataItem
                
                let movedItems = this.cut_selectedRanges()
                if (!parentItem[ this.attr.childItemsPath ]) {
                    parentItem[ this.attr.childItemsPath ] = []
                }
                parentItem[ this.attr.childItemsPath ].push(...movedItems)

                this.refresh(movedItems)
            }
        },
        up_Click(e) {
            let currentPosition = this.flex.selectedRanges[0].topRow,
            count = this.flex.selectedRanges[0].bottomRow - this.flex.selectedRanges[0].topRow + 1,
            dataItem = this.flex.rows[currentPosition]?.dataItem

            if (dataItem) {
                let parentItems = this.get_parentItems(currentPosition),
                irowItem = parentItems.indexOf(dataItem)

                if (irowItem - 1 >= 0) {
                    let movedItems = this.cut_selectedRanges()
                    parentItems.splice(irowItem - 1, 0, ...movedItems)

                    this.refresh(movedItems)
                }
            }
        },
        down_Click(e) {
            let currentPosition = this.flex.selectedRanges[0].topRow,
            count = this.flex.selectedRanges[0].bottomRow - this.flex.selectedRanges[0].topRow + 1,
            dataItem = this.flex.rows[currentPosition]?.dataItem
            
            if (dataItem) {
                let parentItems = this.get_parentItems(currentPosition),
                irowItem = parentItems.indexOf(dataItem)

                if (irowItem + count < parentItems.length) {
                    let movedItems = this.cut_selectedRanges()
                    parentItems.splice(irowItem + 1, 0, ...movedItems)

                    this.refresh(movedItems)
                }
            }
        },
        selected_items(items) {
            if (items.length) {
                let selectedRanges = [],
                min_irow = null,
                max_irow = null
                for (let item of items) {
                    let irow = this.rows_indexOf(item)
                    if (min_irow === null || min_irow > irow) {
                        min_irow = irow
                    }
                    if (max_irow === null || max_irow < irow) {
                        max_irow = irow
                    }
                }
                selectedRanges.push(new wjGrid.CellRange(min_irow,0,max_irow,this.column_left))
                this.flex.selectedRanges = selectedRanges
            }
        },
        delete_Click() {
            let currentPosition = this.flex.selectedRanges[0].topRow,
            deleteCount = this.flex.selectedRanges[0].bottomRow - this.flex.selectedRanges[0].topRow + 1
            
            let deletedItems = this.cut_selectedRanges()

            this.shift_undoStack(currentPosition, -deleteCount) // TODO with cut_selectedRanges...
            this.refresh()

            // selected_items
            setTimeout(() => {
                const newPosition = currentPosition < this.flex.rows.length-1 ? currentPosition : this.flex.rows.length - 2
                if (newPosition >= 0) {
                    this.selected_items([ this.flex.rows[newPosition].dataItem ])
                }
            }, 10)
        },
        cut_selectedRanges() {
            for (let irow = this.flex.selectedRanges[0].topRow; 0 <= irow && irow <= this.flex.selectedRanges[0].bottomRow; irow++) {
                if (this.flex.rows[irow].dataItem !== null) {
                    this.flex.rows[irow].dataItem['$cutMarker'] = 1
                }
            }

            return this.cut_byMarker()
        },
        cut_byMarker(items=null, cutItems=null) {
            if (items === null) items = this.data // this.flex.collectionView.items
            if (cutItems === null) cutItems = []

            for (let irow = 0; irow < items.length; irow++) {
                if (items[irow]['$cutMarker']) {
                    cutItems.push(...items.splice(irow, 1))
                    irow--
                } else if (this.attr.childItemsPath && items[irow][this.attr.childItemsPath]?.length) {
                    this.cut_byMarker(items[irow][this.attr.childItemsPath], cutItems)
                }
            }
            return cutItems
        },
        set_commandPanel() {
            let params = {}
            params['flex_isEditing'] = this.flex_isEditing
            params['!flex_isEditing'] = !this.flex_isEditing
            params['show_actionMenu'] = !this.flex_isEditing

            if (this.WIDGET.data && this.WIDGET.frontend_set_commandPanel && this.WIDGET.frontend_set_commandPanel == this.attr.name) {
                this.WIDGET.attrs_vueObj.WIDGET.set_commandPanel(params)
            }

            let commandPanel = []
            // if (this.WIDGET.widget_class=='widget_SOP_document' || (!this.attr.isReadOnly && this.WIDGET.widget_class!='widget_LIST')) {
                if ((this.attr.showCommandPanel || this.flex_isEditing) && !this.attr.hideGridEditingCommandPanel) {
                    if (!this.attr.readOnlyRows) {
                        commandPanel = [ ...commandPanel,
                            { name:'button-new_Click', autoGeneratedButton:true, 'attr_type':'AttrButton', 'binding':this.attr.binding, 'tooltip': 'Add '+this.attr.binding, 'command':'executeVueObjMethod', 'method':'new_Click', 'cssClass':'pi pi-plus p-button-white p-button-text' },
                            { name:'button-duplicate_Click', autoGeneratedButton:true, 'attr_type':'AttrButton', 'binding':this.attr.binding, 'tooltip': 'Duplicate', 'command':'executeVueObjMethod', 'method':'duplicate_Click', 'cssClass':'pi pi-copy p-button-white p-button-text' },
                            { name:'button-delete_Click', autoGeneratedButton:true, 'attr_type':'AttrButton', 'binding':this.attr.binding, 'tooltip': 'Delete rows', 'command':'executeVueObjMethod', 'method':'delete_Click', 'cssClass':'pi pi-minus p-button-white p-button-text' },
                            { autoGeneratedButton:true},
                            { name:'button-up_Click', autoGeneratedButton:true, 'attr_type':'AttrButton', 'binding':this.attr.binding, 'tooltip': 'Move up', 'command':'executeVueObjMethod', 'method':'up_Click', 'cssClass':'pi pi-angle-up p-button-white p-button-text' },
                            { name:'button-down_Click', autoGeneratedButton:true, 'attr_type':'AttrButton', 'binding':this.attr.binding, 'tooltip': 'Move down', 'command':'executeVueObjMethod', 'method':'down_Click', 'cssClass':'pi pi-angle-down p-button-white p-button-text' },
                        ]
                        if (this.attr.childItemsPath) {
                            commandPanel = [ ...commandPanel,
                                { name:'button-left_tab_Click', autoGeneratedButton:true, 'attr_type':'AttrButton', 'binding':this.attr.binding, 'tooltip': 'Move left', 'command':'executeVueObjMethod', 'method':'left_tab_Click', 'cssClass':'pi pi-angle-double-left p-button-white p-button-text' },
                                { name:'button-right_tab_Click', autoGeneratedButton:true, 'attr_type':'AttrButton', 'binding':this.attr.binding, 'tooltip': 'Move right', 'command':'executeVueObjMethod', 'method':'right_tab_Click', 'cssClass':'pi pi-angle-double-right p-button-white p-button-text' },
                                { name:'button-activate_allowDraggingRows', autoGeneratedButton:true, active:this.allowDraggingRows, 'attr_type':'AttrButton', 'binding':this.attr.binding, 'tooltip': 'Allow dragging rows', 'command':'executeVueObjMethod', 'method':'activate_allowDraggingRows', 'cssClass':'pi pi-arrows-alt p-button-white p-button-text' },
                            ]
                        }
                        if (this.undoStack.length || this.redoStack.length) {
                        commandPanel = [ ...commandPanel,
                            { autoGeneratedButton:true},
                            { name:'button-undo_Click', autoGeneratedButton:true, 'attr_type':'AttrButton', 'binding':this.attr.binding, 'tooltip': 'Undo', 'command':'executeVueObjMethod', 'method':'undo_Click', 'cssClass':'pi pi-undo p-button-white p-button-text' },
                            { name:'button-redo_Click', autoGeneratedButton:true, 'attr_type':'AttrButton', 'binding':this.attr.binding, 'tooltip': 'Redo', 'command':'executeVueObjMethod', 'method':'redo_Click', 'cssClass':'pi pi-refresh p-button-white p-button-text' },
                        ]
                    }
                    }
                }
                if (this.rowLevelGroup_ranges_length) {
                    commandPanel = [ ...commandPanel,
                        { autoGeneratedButton:true, 'attr_type':'AttrButton', 'binding':this.attr.binding, 'tooltip': 'Group all', 'title': 'Group all', 'command':'executeVueObjMethod', 'method':'groupAll', 'cssClass':'pi p-button-white p-button-text' },
                        { autoGeneratedButton:true, 'attr_type':'AttrButton', 'binding':this.attr.binding, 'tooltip': 'Ungroup all', 'title': 'Group all', 'command':'executeVueObjMethod', 'method':'ungroupAll', 'cssClass':'pi p-button-white p-button-text' },
                    ]
                }
            // }

            let need_separator = true
            if (this.attr.groupMenu?.commandPanel) {
                this.attr.groupMenu.commandPanel.forEach(item => {
                    if (!item.autoGeneratedButton) {
                        if (need_separator) {
                            commandPanel.push({autoGeneratedButton:true})
                            need_separator = false
                        }
                        commandPanel.push(item)
                    }
                })
            }
            this.store.attrs_prepare(commandPanel, this.attr.html_id + (++this.ind_commandPanel))
           
            // console.log(commandPanel)
            if (!this.attr.groupMenu) {
                this.attr.groupMenu = {}
            }
            this.attr.groupMenu.commandPanel = this.commandPanel = commandPanel
          
            this.set_contextMenu()
        },
        set_contextMenu() {
            if (!this.attr.groupMenu) {
                this.attr.groupMenu = {}
            }
            if (!this.attr.groupMenu.contextMenu) {
                this.attr.groupMenu.contextMenu = [
                    // { 'attr_type':'AttrButton', 'binding':this.attr.binding, 'title': 'Move up', 'command':'executeVueObjMethod', 'method':'up_Click', 'cssClass':'pi pi-angle-up p-button-white p-button-text' },
                    // { 'attr_type':'AttrButton', 'binding':this.attr.binding, 'title': 'Move down', 'command':'executeVueObjMethod', 'method':'down_Click', 'cssClass':'pi pi-angle-down p-button-white p-button-text' },
                ]
            }
            // if (this.attr['dataSheet-contextMenu']) {
            //     this.attr.contextMenu = this.attr['dataSheet-contextMenu']
            // }

            // if (this.WIDGET.groupMenu) {
            //     if (this.WIDGET.groupMenu.actionMenu) {
            //         // Open up menu ‘action’ in contextMenu
            //         let indMemu = 0
            //         while (indMemu < this.WIDGET.groupMenu.actionMenu.length) {
            //             let itemMenu = this.WIDGET.groupMenu.actionMenu[indMemu]
            //             if (itemMenu.title == '-') {
            //                 indMemu++;
            //                 break
            //             }
            //             this.attr.contextMenu.push(itemMenu)
            //             indMemu++;
            //         }
            //         if (indMemu < this.WIDGET.groupMenu.actionMenu.length) {
            //             let itemMenu2 = { 'title': 'Action', 'attrs': [] }
            //             while (indMemu < this.WIDGET.groupMenu.actionMenu.length) {
            //                 let itemMenu = this.WIDGET.groupMenu.actionMenu[indMemu]
            //                 itemMenu2.attrs.push(itemMenu)
            //                 indMemu++;
            //             }
            //             this.attr.contextMenu.push(itemMenu2)
            //         }
            //     }

            //     // if (this.WIDGET.groupMenu.flexGridMenu) this.attr.contextMenu.push({ 'title': 'Grid', 'attrs': this.WIDGET.groupMenu.flexGridMenu })
            // }
            this.store.attrs_prepare(this.attr.groupMenu.contextMenu, this.attr.html_id)
        },
        get_measure(measure) {
            for (let m of this.WIDGET.doc.measures) {
                if (m.measure==measure) {
                    return m
                }
            }
            return null
        },
        get_attr_col(icol) {
            if (icol > 0) {
                let col = this.flex.columns[icol]
                let attr_col = this.attr_by_binding[col.binding]
                return attr_col
            }
            return null
        },

        //---------------------------------   SELECTION  ---------------------------------
        getSelectionWorkspace_date() {
            if (!this.isVisible || !this.data?.length) return
            let WORKSPACE = this.store.activeWORKSPACE,
                selectedDates = WORKSPACE.selectionWorkspace?.selectedDates
            
            if (selectedDates && this.attr.getSelectionWorkspace_date) {
                this.isFrom_selectionWorkspace = true
                this.setSelection(selectedDates.col1?.date, selectedDates.col2?.date)
                this.isFrom_selectionWorkspace = false
            } else if (this.selectedRanges_BEFORE?.[0].row2 || this.selectedRanges_BEFORE?.[0].col2) { // this.attr.selectedRanges_dontChange || 
                this.flex.selectedRanges = this.selectedRanges_BEFORE
            } else if (this.attr.selectedRanges_byDefault) {
                let range = this.attr.selectedRanges_byDefault[0],
                    r1 = this.headerRows,
                    c1 = 0
                if (range.measure) {
                    for (let row of this.flex.rows) {
                        if (row.dataItem && row.dataItem.measure==range.measure) {
                            r1 = row.index
                            break
                        }
                    }
                }
                if (range.binding) {
                    for (let col of this.flex.columns) {
                        if (col.binding && col.binding==range.binding) {
                            c1 = col.index
                            break
                        }
                    }
                }
                this.flex.selectedRanges = [new wjGrid.CellRange(r1, c1, r1, c1)]
            } else { // if (this.flex.selectionMode==5) { // ListBox
                const leftCol = this.flex.columns.length - 1 // this.attr.frozenColumns||0
                if (this.attr.selectRowID) {
                    console.log('selectedRows')
                    for (let row of this.flex.rows) {
                        if (row.dataItem && row.dataItem.id==this.attr.selectRowID) {
                            // this.flex.selectedRows = [row]    if (this.flex.selectionMode==5) { // ListBox
                            this.flex.selectedRanges = [new wjGrid.CellRange(row.index,0,row.index,leftCol)] // cell
                            break
                        }
                    }
                } else if (this.WIDGET.doc?.params?.current_item) {
                    console.log('selectedRows')
                    for (let row of this.flex.rows) {
                        if (row.dataItem && row.dataItem.id==this.WIDGET.doc.params.current_item) {
                            // this.flex.selectedRows = [row]    if (this.flex.selectionMode==5) { // ListBox
                            this.flex.selectedRanges = [new wjGrid.CellRange(row.index,0,row.index,leftCol)] // cell
                            break
                        }
                    }
                } else if (this.attr.attr_type === 'AttrTabGrid') {
                    if (!(this.flex.selectedRanges.length && this.flex.selectedRanges.col)) {
                        let irow = this.flex.frozenRows||0
                        while (irow < this.flex.rows.length && !this.flex.getCellData(irow, leftCol)) {
                            irow++
                        }
                        this.flex.selectedRanges = [new wjGrid.CellRange(irow,leftCol,irow,leftCol)] // cell
                    }
                } else { // byDefault
                    // this.flex.selectedRanges = [new wjGrid.CellRange(this.headerRows,0,this.headerRows,this.column_left)] // line
                    if (!(this.flex.selectedRanges.length && this.flex.selectedRanges.col)) {
                        this.flex.selectedRanges = [new wjGrid.CellRange(this.flex.frozenRows||0,0,this.flex.frozenRows||0,leftCol)] // cell
                    }
                }
            }
        },
        setSelection(date1, date2) {
            if (this.flex?.rows) {
                let irow1 = this.flex.selectedRanges?.[0].row,
                    irow2 = this.flex.selectedRanges?.[0].row2,
                    icol1 = this.flex.selectedRanges?.[0].col,
                    icol2 = this.flex.selectedRanges?.[0].col2
                
                if (this.flex?.rows?.[0].dataItem?.date) { // date-in-rows
                    for (let row of this.flex.rows) {
                        if (row.dataItem.date === date1) {
                            irow1 = row.index
                        }
                        if (row.dataItem.date === date2) {
                            irow2 = row.index
                        }
                    }
                } else { // date-in-cols
                    for (let col of this.flex.columns) {
                        let attr_col = this.attr_by_binding[col.binding]
                        if (attr_col.date) {
                            if (attr_col.date <= date1 && date1 < attr_col.date_next) {
                                icol1 = col.index
                            }
                            if (attr_col.date <= date2 && date2 < attr_col.date_next) {
                                icol2 = col.index
                            }
                        }
                    }
                }

                this.flex.selectedRanges = [new wjGrid.CellRange(irow1, icol1, irow2, icol2)]
                // if (icol1 !== null && icol2 !== null) {
                //     if (icol1 > icol2) {
                //         [icol1, icol2] = [icol2, icol1]
                //     }
                //     this.flex.selectedRanges = [new wjGrid.CellRange(irow1, icol1, irow2, icol2)]
                // } else if (icol1 !== null) {
                //     this.flex.selectedRanges = [new wjGrid.CellRange(irow1, icol1, irow2, icol1)]
                // } else if (icol2 !== null) {
                //     this.flex.selectedRanges = [new wjGrid.CellRange(irow1, icol2, irow2, icol2)]
                // }
            }
        },
        showGridSummaryLine() {
            if (this.attr.showGridSummaryLine && this.flex.selectedRanges.length) {
                let sum = 0,
                    sumOfPositive = 0,
                    weightedSum = 0,
                    weight = 0,
                    min = null,
                    max = null,
                    itemWeight = null,
                    count = 0,
                    sel = this.flex.selectedRanges[0];
                
                // // measureWeightedAverage 'MEASURE_baseLine'
                // if (sel.row==sel.row2 && sel.col!=sel.col2 && this.flex.rows[sel.row] && this.flex.rows[sel.row].dataItem && this.flex.rows[sel.row].dataItem.measure) {
                //     let measureDoc = this.get_measure(this.flex.rows[sel.row].dataItem.measure).measureDoc
                //     if (measureDoc && measureDoc.params && measureDoc.params.cssStyle_condition=='cssStyle-CONDITIONS-FA') {
                //         for (let irow = 0; irow < this.flex.rows.length; irow++) {
                //             if (this.flex.rows[irow].dataItem && this.flex.rows[irow].dataItem.measure=='MEASURE_baseLine') {
                //                 itemWeight = this.flex.rows[irow].dataItem
                //                 break
                //             }
                //         }
                //     }
                //     if (measureDoc && measureDoc.params && measureDoc.params.measureWeightedAverage) {
                //         for (let irow = 0; irow < this.flex.rows.length; irow++) {
                //             if (this.flex.rows[irow].dataItem && this.flex.rows[irow].dataItem.measure==measureDoc.params.measureWeightedAverage) {
                //                 itemWeight = this.flex.rows[irow].dataItem
                //                 break
                //             }
                //         }
                //     }
                // }

                for (let irow = Math.min(sel.row,sel.row2); irow <= Math.max(sel.row,sel.row2); irow++) {
                    for (let icol = Math.min(sel.col,sel.col2); icol <= Math.max(sel.col,sel.col2); icol++) {
                        if (irow>=0 && icol>=0) {
                            let col = this.flex.columns[icol],
                            dataItem = this.flex.rows[irow].dataItem;
                            if (dataItem && col && !isNaN(dataItem[col.binding])) {
                                let positive_value = (dataItem[col.binding]>0 ? dataItem[col.binding] : 0)
                                sum += dataItem[col.binding]
                                sumOfPositive += positive_value
                                count += 1
                                if (itemWeight) {
                                    weight += itemWeight[col.binding]
                                    weightedSum += positive_value * itemWeight[col.binding]
                                }
                                if (min != null) {
                                    min = Math.min(min, dataItem[col.binding])
                                    max = Math.max(max, dataItem[col.binding])
                                } else {
                                    min = dataItem[col.binding]
                                    max = dataItem[col.binding]
                                }
                            }
                        }
                    }
                }
                if (count>1) {
                    this.currentSelectionHTML = ``
                    if (itemWeight && weightedSum && weight) {
                        this.currentSelectionHTML += `<b class='tooltip-header'>Weighted average:</b> <b class='tooltip-text'> ${weight ? this.store.formatNumber(Math.round(weightedSum/weight)) : ''}</b>`
                        // this.currentSelectionHTML += `<b class='tooltip-header'>Weighted average:</b> <b class='tooltip-text'> ${weight ? this.store.formatNumber((weightedSum)) : ''}</b>`
                        // this.currentSelectionHTML += `<b class='tooltip-header'>Weighted average:</b> <b class='tooltip-text'> ${weight ? this.store.formatNumber((weight)) : ''}</b>`
                    }
                    this.currentSelectionHTML += `<b class='tooltip-header'>\tAverage:</b> <b class='tooltip-text'> ${count ? this.store.formatNumber(Math.round(sumOfPositive/count)) : ''}</b>`
                    this.currentSelectionHTML += `<b class='tooltip-header'>\tCount:</b> <b class='tooltip-text'> ${this.store.formatNumber(Math.round(count))}</b>`
                    if (sumOfPositive != sum) {
                        this.currentSelectionHTML += `<b class='tooltip-header'>\tSum>0:</b> <b class='tooltip-text'> ${this.store.formatNumber(Math.round(sumOfPositive))}</b>`
                    }
                    this.currentSelectionHTML += `<b class='tooltip-header'>\tSum:</b> <b class='tooltip-text'> ${this.store.formatNumber(Math.round(sum))}</b>`
                    this.currentSelectionHTML += `<b class='tooltip-header'>\tMin:</b> <b class='tooltip-text'> ${this.store.formatNumber(Math.round(min))}</b>`
                    this.currentSelectionHTML += `<b class='tooltip-header'>\tMax:</b> <b class='tooltip-text'> ${this.store.formatNumber(Math.round(max))}</b>`
                } else {
                    this.currentSelectionHTML = ''
                }
            }
        },
        get_selectedRanges() {
            let selectedRanges = []
            for (let range of this.flex.selectedRanges) {
                for (let icol=range.col; icol<=range.col2; icol++) {
                    for (let irow=range.row; irow<=range.row2; irow++) {
                        selectedRanges.push(this.get_cell(irow, icol))
                    }
                }
            }
            // console.log(selectedRanges)
            return selectedRanges
        },
        selectionChanging(flex, e) {
            console.log('selectionChanging 1')
            if (this.isRowHeaderClick) {
                e.cancel = true
            }
        },
        selectionChanged(flex, e) {
            console.log('selectionChanged 2')
            if (this.ignore_selectionChanged) return

            if (this.attr.setSelectionWorkspace_date && this.flex.selectedRanges?.length && !this.isFrom_selectionWorkspace) {
                let col1 = this.flex.selectedRanges[0].col,
                    col2 = this.flex.selectedRanges[0].col2,
                    attr_col1 = this.get_attr_col(col1),
                    attr_col2 = this.get_attr_col(col2),
                    item_row1 = this.flex.rows[this.flex.selectedRanges[0].row]?.dataItem,
                    item_row2 = this.flex.rows[this.flex.selectedRanges[0].row2]?.dataItem
                if (item_row1?.date) { // date-in-rows
                    this.store.setSelectionWorkspace_date(this.WIDGET,{
                        selectedDates:{
                            col1: item_row1, 
                            col2: item_row2,
                        }
                    })
                } else if (attr_col1?.date || attr_col2?.date) { // date-in-cols
                    while (!attr_col1?.date && col1 < col2) {
                        attr_col1 = this.get_attr_col(++col1)
                    }

                    this.store.setSelectionWorkspace_date(this.WIDGET,{
                        selectedDates:{
                            col1: attr_col1, 
                            col2: attr_col2,
                        }
                    })
                }
            }

            this.showGridSummaryLine()

            // OLD
            if (this.attr.configType=='measure_calculationModel') {
                if (this.flex.selectedRows.length && this.flex.selectedRows[0].dataItem) {
                    let item = { measure:this.flex.selectedRows[0].dataItem.measure }

                    let WORKSPACE = this.store.activeWORKSPACE
                    let PAGE = WORKSPACE.activePAGE
                    let rightArea = PAGE.rightArea

                    if (rightArea) {
                        if (!rightArea.parentWidget || !rightArea.parentWidget.item || item.measure!=rightArea.parentWidget.item.measure) {
                            let measureRow = this.store.get_measureRow(this.WIDGET, item.measure)
                            if (measureRow && measureRow.own_calculateModel) {
                                item = {
                                    measure: item.measure,
                                    own_calculateModel: measureRow.own_calculateModel,
                                    ...(measureRow.calculateModel_params||{}),
                                }
                            }
                            this.store.rightArea_setDataSource(this, this.attr.binding, this.attr.configType, item)
                        }
                    }
                }
            }
            if (this.attr.setSelectionInAttr_AfterSelectionChanged) {
                let attr_vueObj = this.WIDGET.vueObj.active_attr_vueObj(this.attr.setSelectionInAttr_AfterSelectionChanged)
                if (attr_vueObj && this.flex && this.flex.selectedRanges.length) {
                    console.log('setSelectionInAttr_AfterSelectionChanged')
                    let attr_col1 = this.get_attr_col(this.flex.selectedRanges[0].col),
                        attr_col2 = this.get_attr_col(this.flex.selectedRanges[0].col2)
                    if (attr_col1 && !attr_col1.x1Chart) {
                        attr_col1 = null
                    }
                    if (attr_col2 && !attr_col2.x1Chart) {
                        attr_col2 = null
                    }
                    if (attr_col1) {
                        attr_vueObj.setSelection(Math.min(attr_col1.x1Chart, attr_col2.x1Chart), Math.max(attr_col1.x2Chart, attr_col2.x2Chart))
                        // console.log(Math.min(attr_col1.x1Chart, attr_col2.x1Chart))
                        // console.log(Math.max(attr_col1.x2Chart, attr_col2.x2Chart))
                    }
                }
            }
        },

        use_selectorMeasures() {
            if (this.WIDGET.doc?.params?.use_selectorMeasures && this.attr.use_selectorMeasures) {
                let selectedMeasures = {}
                this.flex.rows.forEach((item, index) => {
                    if (item.dataItem?.measure) {
                        selectedMeasures[item.dataItem.measure] = item.dataItem.selector
                    }
                })
                
                this.store.use_selectorMeasures(this.WIDGET, selectedMeasures)
            }
        },

        //---------------------------------
        onButtonShowDroppedDown__Click(cell, e) {
            let item_value, itemOdoo, attr_col,
            dataItem = this.flex.rows[cell.row].dataItem,
            item_attr = attr_col = this.attr_by_binding[cell.col_binding]
            if (attr_col.attr_type === 'AttrMulti') {
                item_attr = dataItem[attr_col.binding]
                
                item_value = item_attr.value
                itemOdoo = [item_attr.value, item_attr.valueStr]
            } else {
                item_value = this.flex.getCellData(cell.row, cell.col)
                itemOdoo = dataItem[cell.col_binding] // this.data[cell.irow][item_attr.binding]
            }

            this.show_popupTab(item_attr, attr_col, cell, e)

            // dataMapEditor
            // let col = this.column_by_binding[cell.col_binding]
            // col.isDroppedDown = !col.isDroppedDown
        },
        show_popupTab(item_attr, attr_col, cell, e) {
            let itemsSource
            itemsSource = this.store.get_itemsSource(this, {}, item_attr)
            if (!itemsSource?.data && item_attr !== attr_col) {
                itemsSource = this.store.get_itemsSource(this, {}, attr_col)
            }
            
            let itemsSource_types = this.store.get_itemsSource(this, {}, { relation_segment: 'ATTR' })
            // let itemsSource_types = this.store.get_itemsSource(this, {}, { relation_segment: 'SEGMENTS' })

            if (itemsSource?.data) {
                let attr_popupTab_grid_list = this.store.attr_find(this.WIDGET.attrs_popupTab, 'popupTab-grid-list', 'name')
                let attr_popupTab_grid_list_vueObj = this.WIDGET.attrs_vueObj[attr_popupTab_grid_list.id]
                if (attr_popupTab_grid_list_vueObj.data !== itemsSource.data) {
                    attr_popupTab_grid_list_vueObj.data = itemsSource.data

                    attr_popupTab_grid_list_vueObj.set_itemsSource()
                    // attr_popupTab_grid_list_vueObj.refresh()
                }

                let valueStr = this.flex.getCellData(cell.row, cell.col) || ''
                // if (valueStr) {
                    attr_popupTab_grid_list_vueObj.filterChips = valueStr.split(' ')
                    attr_popupTab_grid_list_vueObj.apply_filterChips()
                // }
                
                let attr_popupTab_grid_types = this.store.attr_find(this.WIDGET.attrs_popupTab, 'popupTab-grid-types', 'name')
                let attr_popupTab_grid_types_vueObj = this.WIDGET.attrs_vueObj[attr_popupTab_grid_types.id]
                if (attr_popupTab_grid_types_vueObj.data !== itemsSource_types.data) {
                    attr_popupTab_grid_types_vueObj.data = itemsSource_types.data

                    attr_popupTab_grid_types_vueObj.set_itemsSource()
                    // attr_popupTab_grid_types_vueObj.refresh()
                }
                
                let wj_cell = wjcCore.closest(e.target, '.wj-cell')

                const popupTab = this.WIDGET.attrs_vueObj.WIDGET.popupTab
                this.WIDGET.popupTab = {
                    parent_attr: {...this.attr, attr_col:item_attr, attr_col2:attr_col, cell:cell},
                    attr_popupTab_grid_list_vueObj: attr_popupTab_grid_list_vueObj,
                }
                popupTab.owner = wj_cell
                // if (!popupTab.isVisible) {
                    popupTab.show(false)
                // } else {
                //     popupTab.hide()
                // }
            }
        },
        // ------------------ Chips -----------------------------------
        apply_filterChips(e=null, level=0, topRow=0, bottomRow=0) {
            let filterChips = [],
                rows = this.flex.rows
            for (let i = 0; i < this.filterChips.length; i++) {
                filterChips.push(this.filterChips[i].trim().toLowerCase())
            }
            // console.log(filterChips)
            if (!bottomRow) { bottomRow = rows.length-1 }

            if (!level) {
                this.filterChips_saveToWHERE()
            }

            if (!filterChips.length) {
                for (let i = topRow; i <= bottomRow; i++) {
                    rows[i].visible = true
                }
                return true
            }

            let visible0 = false
            for (let i = topRow; i <= bottomRow; i++) {
                let row = rows[i],
                dataItem = row.dataItem

                if (dataItem && (row.level == undefined || row.level == level)) {
                    let visible = false
                    for (let binding in this.attr_by_binding) { // of this.attr.attrs
                        let attr_col = this.attr_by_binding[binding],
                        item_attr = attr_col
                        if (attr_col.attr_type === 'AttrMulti') {
                            item_attr = dataItem[attr_col.binding]
                            // visible ||= this.check_filterChips(String(item_attr.value).toLowerCase() || '', filterChips, item_attr) LOOK non-empty and item_attr.value===false
                            // if (!visible && item_attr.value != item_attr.valueStr) {
                                visible ||= this.check_filterChips(String(item_attr.valueStr).toLowerCase() || '', filterChips, item_attr)
                            // }
                        } else {
                            let value = this.store.doc_get(dataItem, attr_col.binding)
                            if (item_attr.relation_segment) {
                                let item0 = this.store.itemsSource[item_attr.relation_segment]?.ids?.[value]
                                if (item0) {
                                    visible ||= this.check_filterChips( item0.title.toLowerCase() || '', filterChips, item_attr)
                                }
                            } else if (item_attr.component === 'AttrStr' && value && typeof value === 'string') {
                                visible ||= this.check_filterChips(value.toLowerCase() || '', filterChips, item_attr)
                            } else {
                                visible ||= this.check_filterChips(String(value).toLowerCase() || '', filterChips, item_attr)
                            }
                        }
                        if (visible) {
                            break
                        }
                    }
                    if (row.getCellRange) {
                        let rng = row.getCellRange()
                        if (visible) {
                            for (let j = rng.topRow; j <= rng.bottomRow; j++) {
                                rows[j].visible = true
                            }
                        } else {
                            rows[i].visible = this.apply_filterChips(null, level+1, rng.topRow + 1, rng.bottomRow)
                            // for (let j = rng.topRow + 1; j <= rng.bottomRow; j++) {
                            //     let item2 = rows[j].dataItem,
                            //         visible2 = false
                            //     for (let ifilter = 0; ifilter < filterChips.length; ifilter++) {
                            //         visible2 ||= item2.object && item2.object.toLowerCase().indexOf(filterChips[ifilter]) >= 0
                            //     }
                            //     rows[j].visible = visible2
                            //     visible = visible || visible2
                            // }
                            // rows[i].visible = visible
                        }
                        visible0 ||= rows[i].visible
                        i = rng.bottomRow
                    } else {
                        rows[i].visible = visible0 = visible
                    }
                }
            }
            return visible0
        },
        filterChips_saveToWHERE() {
            const TODO = false
            if (TODO && this.attrWHERE_vueObj) {
                let filterChips = this.filterChips
                let WHERE = this.WIDGET.doc?.params?.WHERE || [],
                rowWHERE_chips = WHERE.find(rowWHERE => rowWHERE.leftValue?.value === '#chips')
                if (!rowWHERE_chips) {
                    rowWHERE_chips = {
                        use: true,
                        leftValue: '#chips',
                        condition: '',
                        rightValue: '',
                        attrs: [],
                    }
                    WHERE.push(rowWHERE_chips)
                }
                
                rowWHERE_chips.use = filterChips.length > 0
                rowWHERE_chips.attrs = []

                for (let i = 0; i < filterChips.length; i++) {
                    rowWHERE_chips.attrs.push({
                        use: true,
                        leftValue: '#any_field',
                        condition: 'like',
                        rightValue: filterChips[i].trim(),
                    })
                }

    
                if (this.attrWHERE_vueObj_refresh_timeout) { clearTimeout(this.attrWHERE_vueObj_refresh_timeout) }
                this.attrWHERE_vueObj_refresh_timeout = setTimeout(() => {
                    // this.attrWHERE_vueObj?.refresh?.(null, true)
                    this.attrWHERE_vueObj?.prepare_AttrMulti_all()
                }, 100)
            }
        },
        filterFields_saveToWHERE(filter) {
            const TODO = false
            if (TODO && this.attrWHERE_vueObj) {
                let WHERE = this.WIDGET.doc?.params?.WHERE || [],
                rowWHERE_fields = WHERE.find(rowWHERE => rowWHERE.leftValue.value === '#fields')
                if (!rowWHERE_fields) {
                    rowWHERE_fields = {
                        use: true,
                        leftValue: '#fields',
                        condition: '',
                        rightValue: '',
                        attrs: [],
                    }
                    WHERE.push(rowWHERE_fields)
                }
                
                // flexgrid.collectionView.refreshOnEdit = false;

                let filterDefinition = JSON.parse(filter.filterDefinition)

                rowWHERE_fields.use = filterDefinition.filters.length > 0
                rowWHERE_fields.attrs = []

                for (let i = 0; i < filterDefinition.filters.length; i++) {
                    let row_filter = filterDefinition.filters[i],
                    showValues = row_filter.showValues,
                    keys = Object.keys(showValues).filter(key => showValues[key]),
                    showValues_text = keys.slice(0, 5).join(', ');
                    if (keys.length > 5) {
                        showValues_text += '...';
                    }

                    rowWHERE_fields.attrs.push({
                        use: true,
                        leftValue: row_filter.binding,
                        condition: 'in',
                        rightValue: showValues_text,
                    })
                }

                if (this.attrWHERE_vueObj_refresh_timeout) { clearTimeout(this.attrWHERE_vueObj_refresh_timeout) }
                this.attrWHERE_vueObj_refresh_timeout = setTimeout(() => {
                    // this.attrWHERE_vueObj?.refresh?.(null, true)
                    this.attrWHERE_vueObj?.prepare_AttrMulti_all()
                }, 100)
            }
        },
        check_filterChips(title, filterChips, attr_col) {
            let check = false
            // return filterChips.some(filter => title.includes(filter))
            for (let ifilter = 0; ifilter < filterChips.length; ifilter++) {
                if (filterChips[ifilter] === 'non-empty') {
                    if (attr_col.skipNonEmptyCheck) {
                        // continue
                    } else  if (title !== '') {
                        check = true
                    } else {
                        return false
                    }
                } else if (title.indexOf(filterChips[ifilter]) >= 0) {
                    check = true
                } else {
                    return false
                }
            }
            return check
        },
        check_filterChips_highlighted(title, filterChips) {
            let check = false;
            let highlightedTitle = title

            for (let ifilter = 0; ifilter < filterChips.length; ifilter++) {
                if (title.toLowerCase().indexOf(filterChips[ifilter].trim().toLowerCase()) >= 0) {
                    check = true
                    highlightedTitle = highlightedTitle.replace(
                        new RegExp(`(${filterChips[ifilter]})`, 'gi'),
                        `<span class="wj-state-match">$1</span>`
                    )
                    return highlightedTitle
                }
            }
            if (this.WIDGET.widget_class === 'widget_SEARCH') {
                const filterChip = this.WIDGET.title
                if (title.toLowerCase().indexOf(filterChip.trim().toLowerCase()) >= 0) {
                    check = true
                    highlightedTitle = highlightedTitle.replace(
                        new RegExp(`(${filterChip})`, 'gi'),
                        `<span class="wj-state-match">$1</span>`
                    )
                    return highlightedTitle
                }
            }

            return check ? highlightedTitle : false
        },

        filterChanged(filter, e) {
            this.filterFields_saveToWHERE(filter)
        },
        // -----------------------------------------------------
        aggregateFooters() {
            if (this.attr.aggregateFooters && !this.flex.columnFooters.rows.length) {
                this.flex.columnFooters.rows.push(new wjGrid.GroupRow())
                this.flex.bottomLeftCells.setCellData(0, 0, 'Σ')
            } else if (!this.attr.aggregateFooters && this.flex.columnFooters.rows.length) {
                this.flex.columnFooters.rows.clear()
            }
        },
        isEmpty(dataItem, attrs, binding_parent='') {
            for (let attr_col of attrs) {
                if (attr_col.component == 'AttrNumber' && dataItem[attr_col.binding]) { // `${binding_parent}${attr_col.binding}`
                    return false
                } else if (attr_col.attrs && !this.isEmpty(dataItem, attr_col.attrs, `${binding_parent}${attr_col.binding}.`)) {
                    return false
                }
            }
            return true
        },
        handleVisibility(isVisible) {
            this.isVisible = isVisible
            if (isVisible) {
                // if (this.attr.getSelectionWorkspace_date){ LOOK in this.getSelectionWorkspace_date()
                    this.WIDGET.getSelectionWorkspace_date = this.getSelectionWorkspace_date
                    this.getSelectionWorkspace_date()
                // }
            }
        },
        refresh(items=null) {
            if (this.flex) {
                if (items) {
                    for (let dataItem of items) {
                        delete dataItem['$cutMarker']
                    }
                }
                this.flex.collectionView.refresh()
                this.isReadOnly_false()

                if (items) {
                    this.selected_items(items)
                }

                this.flex.hostElement.focus()
            }
        },
        prepare_AttrMulti_all() {
            for (let attr_col of this.attr.attrs) {
                if (attr_col.component == 'AttrMulti') { //  && this.attr.attr_type !== 'AttrTabGrid'
                    this.flex.rows.forEach((item, irow) => {
                        if (item.dataItem) {
                            let parentsList = this.get_parentsList(irow, attr_col)
                            this.store.prepare_AttrMulti(this, attr_col, item.dataItem, parentsList)
                        }
                    })
                }
            }
        },

        // ------------- drag&drop ----------------------------------------
        wjRow_dragstart (e) {
            this.wjRow_dragged = wjcCore.closest(e.target, '.wj-row')
            e.dataTransfer.effectAllowed = 'move'
            wjcCore.addClass(this.wjRow_dragged, 'drag-source')
        },
        wjRow_dragover (e) {
            let wjRow_dragOver = wjcCore.closest(e.target, '.wj-row')
            if (wjRow_dragOver) {
                if (this.wjRow_dragOver && this.wjRow_dragOver !== wjRow_dragOver) {
                    wjcCore.removeClass(this.wjRow_dragOver, 'drag-over')
                    this.wjRow_dragOver = null
                }
                if (this.wjRow_dragged !== wjRow_dragOver) {
                    this.wjRow_dragOver = wjRow_dragOver
                    e.preventDefault()
                    e.dataTransfer.dropEffect = 'move'
                    wjcCore.addClass(wjRow_dragOver, 'drag-over')
                }
            }
        },
        wjRow_drop (e) {
            if (this.wjRow_dragOver) {
                let item_dragOver = this.flex.rows[ this.wjRow_dragOver.data_cell.irow ].dataItem

                this.flex.rows[ this.wjRow_dragged.data_cell.irow ].dataItem['$cutMarker'] = 1
                let movedItems = this.cut_byMarker()

                let irow_dragOver = this.rows_indexOf(item_dragOver),
                parentItems = this.get_parentItems(irow_dragOver),
                irowItem = parentItems.indexOf(item_dragOver)
                
                parentItems.splice(irowItem + 1, 0, ...movedItems)

                this.refresh(movedItems)
            }
        },
        wjRow_dragend (e) {
            if (this.wjRow_dragged) {
                wjcCore.removeClass(this.wjRow_dragged, 'drag-source')
                this.wjRow_dragged = null
            }
            if (this.wjRow_dragOver) {
                wjcCore.removeClass(this.wjRow_dragOver, 'drag-over')
                this.wjRow_dragOver = null
            }
        },
        // -----------------------------------------------------

        initMenu(menu) {
            this[menu.hostElement.id] = menu
        },
        menuNodeClicked(menu) {
            // let actionMenu = this.attr.groupMenu.contextMenu[menu.selectedIndex]
            let actionMenu = menu.itemsSource[menu.selectedIndex]
            let vueObj = this.WIDGET.vueObj
            this.store.executeStoreMethod(vueObj, actionMenu)
        },
        tooltip() {
            return this.store.tooltip_attr(this)
        },
    },
    computed: {
        gridClass() {
            return ['attr-grid ' + (this.attr?.cssClass||''), {
                'attr-grid-50': !('attrResize' in this.attr) || this.attr.attrResize,
            }]
        },
		menuTree() {
			return this.attr?.groupMenu?.menuTree||[]
		},
    },
    watch: {
        'store.itemsSource_change_index'(newVal, oldVal) {
            const change_type = this.store.itemsSource.change_type
            if (this.flex && change_type) {
                let changed
                for (let col of this.flex.columns) {
                    let attr_col = this.attr_by_binding[col.binding]
                    if (attr_col?.component == 'AttrLink' && attr_col.relation_segment && attr_col.relation_segment==change_type) {
                        console.log(`watchSheet ${change_type} ${newVal}`)
                        this.store.get_itemsSource(this, col, attr_col)
                    }
                    if (attr_col?.attr_type === 'AttrMulti') {
                        this.flex.rows.forEach((item, irow) => {
                            if (item.dataItem) {
                                let V = item.dataItem[attr_col.binding]
                                if (V?.value && V.component == 'AttrLink' && V.relation_segment && V.relation_segment==change_type) {
                                    const valueStr = V.valueStr,
                                    parentsList = this.get_parentsList(irow, attr_col),
                                    founded = this.store.get_valueStr(this, V, V, attr_col, parentsList)
                                    if (attr_col.customValueWarning) {
                                        if (founded && V.is_customValueWarning) {
                                            delete V.is_customValueWarning
                                            changed = true
                                        } else if (!founded && !V.is_customValueWarning && V.value) {
                                            V.is_customValueWarning = true
                                            changed = true
                                        }
                                    }
                                    if (valueStr !== V.valueStr) {
                                        changed = true
                                    }
                                }
                            }
                        })
                    }
                }
                if (changed) {
                    this.flex.refresh()
                }
            }
        },
        // 'attr.showFilterChips'(newValue) {
        //     this.$nextTick(() => {
        //         this.attrResize()
        //     })
        // }
    },
}
</script>

<style>
/* .my-grid {
    background-color: white;
} */
.label-grid {
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: bold;
    top: 4px;
    color: #3f51b5;
    /*margin: 20px;*/
    margin: 5px 28px
}
.attr-grid {
    overflow: hidden;
    width:-webkit-fill-available !important;
}
.attr-grid-50 {
    height: 50px;
}
.attr-grid-input {
    width:-webkit-fill-available !important;
    /* margin: 5px !important; */
}
.wj-header-row  {
    text-align: center !important;
    border-right: 1px solid !important;
}
.grid-group1-row  {
    background-color: #f7f7f7;
    font-weight: bold;
}
.grid-group2-row  {
    background-color: lightgray;
    /* color: white; */
    font-weight: bold;
}
.grid-total-row  {
    background-color: lightgray;
    color: white;
    font-weight: bolder;
}
.cell-sum {
    color: green;
}
.wj-cell .attr-doc {
    display: flex;
    position: absolute; 
    left: 0px;
    width: 100%; 
    border-radius: 0px;
    margin: -5px 0 0px 0;
}
.wj-cell .attr-doc-input {
    width: 100%;
}
.wj-cell .cell-has-doc {
    background-color: burlywood;
}
.wj-cell .cell-has-doc:hover:after {
    opacity: 1;
}
.attr-column-button-group {
    position: absolute;
    left: 0px;
    top: 0px;
    color: white !important;
    background: transparent;
}
.attr-row-button-group {
    position: absolute;
    left: 0px;
    top: 0px;
    color: lightslategray !important;
    background: transparent;
}
.attr-row-button-group .pi {
    color: lightslategray;
}
.attr-doc-button-group .pi {
    color: lightslategray;
}
.attr-doc .attr-doc-button-group {
    position: absolute;
    right: 0px;
    top: 1px;
    transition: all 250ms;
    color: lightslategray !important;
    background: transparent;
}
.attr-doc .wj-input-group-btn {
    opacity: 0;
}
.attr-doc:hover .wj-input-group-btn {
    opacity: 0.9;
}
.cell-value .cell-value-show {
    opacity: 0.9;
}
/*.wj-flexgrid .cell-value .wj-grid-editor {
    text-align: left;
}*/
.attr-KPI-group {
    display: flex;
    position: absolute;
    top: 3px;
}
.attr-KPI .attr-KPI-selector {
    transition: all 250ms;
    opacity: 0;
    color: lightslategray !important;
    background: transparent;
}
.attr-KPI .attr-KPI-selector-on {
    opacity: 0.9;
}
.attr-KPI:hover .attr-KPI-selector {
    opacity: 1;
}
.attr-KPI .attr-KPI-button {
    transition: all 250ms;
    opacity: 0;
    background: transparent;
}
.attr-KPI:hover .attr-KPI-button {
    opacity: 1;
    background: transparent;
}
.wj-glyph-down {
    opacity: 0.2 !important;
}
.attr-doc2:hover .wj-glyph-down {
    opacity: 0.5 !important;
}
.attr-element:hover .wj-glyph-down {
    opacity: 0.5 !important;
}
.attr-selector .attr-doc-button-group {
    position: absolute;
    right: 0px;
    top: 2px;
    transition: all 250ms;
    /* display: none; */
    opacity: 0;
    color: lightslategray !important;
    /* display: flex; */
    /* align-items: center; */
}
.attr-selector:hover .attr-doc-button-group {
    /* display: block; */
    opacity: 0.9;
}
.wj-cell.wj-has-stack {
    background: lightgoldenrodyellow !important;
    color: lightslategray !important;
}
.wj-cell.wj-has-stack-error {
    background: #ffdfdf !important; 
    color: white !important;
}
.wj-cell.cell-zero {
    color: transparent !important;
}
.wj-cell.cell-zero .wj-grid-editor {
    color: black !important;
}
.wj-cell.cell-empty {
    background: #f2f5f5 !important;
    color: lightslategray !important;
}
.wj-grouppanel {
    min-height: 3.5em;
    padding: 5px;
    width:-webkit-fill-available !important;
}
.wj-flexgrid .wj-cell {
    border-right: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0.35rem 1rem;
    font-size: 0.8125rem;
}
.wj-flexgrid .wj-cell.wj-cell-maker.wj-radio-map.custom-rating label {
    /* width: .25em; */
    color: green;
}
.wj-flexgrid .wj-cell.wj-cell-maker.wj-radio-map.custom-rating label:after {
    transform: scale(12);
    content: '\2b24';
}
.attr-grid .wj-cell {
    padding: 6px 2px;
}
.attr-grid .cell-title {
    color: #3f51b5;
    padding: 0.35rem 0rem 0.35rem 3rem;
}
.attr-grid .cell-value {
    padding: 0.35rem 3rem 0.35rem 0.35rem;
    /*text-align: left;*/
}

.column-actual {
    border-left: medium solid rgba(0, 0, 0, 0.1);
    background: aliceblue;
}
.column-score {
    background: aliceblue;
}
.column-summary {
    background: aliceblue;
}
.column-past {
    background: #f2f5f5;
}
.column-lead-time {
    background: aliceblue;
}
.column-replenishment-cycle {
    background: rgb(255 243 222 / 95%);
}
.column-info {
    background: #F2F5F5 !important; 
}
.column-raw_object {
    background: aliceblue;
    font-style: italic;
}
.column-fact {
    border-left: medium solid white !important; 
    border-right: medium solid white !important; 
    background: aliceblue !important; 
}
.column-notWork {
    background: #F2F5F5; 
}

.measure-forecast {
    background: #88bde6; 
    color: white
}
.measure-baseLine {
    background: darkgray;
    color: white;
}
.measure-in {
    background: #99CC99; 
    color: white
}
.measure-out {
    background: #CC9966; 
    color: white
}


.gantt-container {
    width: 100%;
    height: 30px;
    position: relative;
}
.gantt-bar-gray {
    height: 60%;
    position: absolute;
    border-radius: 5px;
}
.gantt-bar {
    opacity: 0.3;
    transition: all 250ms;
}
.gantt-bar:hover {
    transform: scale(1.2);
    opacity: 0.7;
}
.in-bar-gray {
    background-color:gray;
}
.in-bar-green {
    background-color:#4CAF50;
}
.in-bar-blue {
    background-color:blue;
}
.in-bar {
    position: absolute;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    height: 80%;
    top:20%;
    width:25%;
    left:10%;
    opacity: 0.3;
    transition: all 250ms;
}
.in-bar:hover {
    transform: scale(1.2);
    opacity: 0.7;
}
.first-bar {
    top: 40%;
    width: 10%;
    background-color: blue;
}
.second-bar {
    top: 30%;
    width: 40%;
    left: 15%;
    background-color: yellow;
    background-image: repeating-linear-gradient(
        -45deg,
        rgba(0,0,0,0.3),
        rgba(0,0,0,0.1) 2px,
        transparent 2px,
        transparent 5px
    );
}
.third-bar {
    top: 20%;
    width: 30%;
    left: 60%;
    background-color: green;
}

/* .cell-background-green {
    background: darkseagreen !important; 
    color: white !important;
} */
/* .cell-background-yellow {
    background: #f8ebba !important; 
    color: inherit !important;
} */
/* .cell-background-orange {
    background: orange !important; 
    color: white !important;
} */
/* .cell-background-red {
    background: orange !important; 
    color: white !important;
} */
.cell-border-bottom-grey::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 1px;
    width: 100%;
    height: 5px; 
    background-color: rgba(0, 0, 0, 0.1);
}
.cell-border-bottom-green::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 1px;
    width: 100%;
    height: 5px; 
    background-color: rgba(0, 128, 0, 0.15);
}
.cell-border-bottom-yellow::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 1px;
    width: 100%;
    height: 5px; 
    background-color: rgb(255 243 222 / 95%);
}
.cell-border-bottom-red-after::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 1px;
    width: 100%;
    height: 1px; 
    background-color: rgba(255, 0, 0, 0.5);
}
.cell-border-bottom-red::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 1px;
    width: 100%;
    height: 2px; 
    background-color: rgba(255, 0, 0, 0.5);
}
.cell-border-bottom-red-fat::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 1px;
    width: 100%;
    height: 7px; 
    background-color: rgba(255, 0, 0, 0.5);
}
.cell-border-bottom-orange::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 1px;
    width: 100%;
    height: 2px; 
    background-color: #ffdedebb;
}

.cell-border-left-grey::before {
    content: '';
    position: absolute;
    left: 1px;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: rgba(0, 0, 0, 0.1);
}
.cell-border-left-grey-cell::before {
    content: '';
    position: absolute;
    left: 1px;
    top: 0;
    bottom: 0;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.1);
}
.cell-border-left-green::before {
    content: '';
    position: absolute;
    left: 1px;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: rgba(0, 128, 0, 0.15);
}
.cell-border-left-yellow::before {
    content: '';
    position: absolute;
    left: 1px;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: rgb(255 243 222 / 95%);
}
.cell-border-left-red::before {
    content: '';
    position: absolute;
    left: 1px;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: rgba(255, 0, 0, 0.5);
}
.cell-border-left-red-fat::before {
    content: '';
    position: absolute;
    left: 1px;
    top: 0;
    bottom: 0;
    width: 10px;
    background-color: rgba(255, 0, 0, 0.5);
}
.cell-border-left-orange::before {
    content: '';
    position: absolute;
    left: 1px;
    top: 0;
    bottom: 0;
    width: 5px;
    background-color: #ffdedebb;
}

.cell-background-green {
    background: #dfedde !important;
    color: inherit !important;
}
.cell-background-yellow {
    background: #fff2de !important; 
    color: inherit !important;
}
.cell-background-orange {
    background: #ffdede !important; 
    color: inherit !important;
}
.cell-background-red {
    background: #ff8a8a !important; 
    color: white !important;
}
.cell-background-grey {
    background: rgba(0, 0, 0, 0.1) !important; 
    color: white !important;
}

.cell-transform::before {
    transition: all 250ms;
}
.cell-transform:hover::before {
    transform: scale(1.5);
}

.chart-zone-selected {
    fill: transparent;
    opacity: .9;
    stroke: rgba(255, 150, 30, 0.75);
    stroke-width: 3px;
}
.red-zone {
    fill: #ff8a8a;
    stroke-width: 1;
}
.orange-zone {
    fill: red;
    opacity: .15;
    stroke-width: 0;
}
.yellow-zone {
    fill: orange;
    opacity: .15;
    stroke-width: 0;
}
.green-zone {
    fill: green;
    opacity: .15;
    stroke-width: 0;
}
.aliceblue-zone {
    fill: aliceblue;
    /* opacity: .15; */
    stroke-width: 0;
}

.wj-cell.wj-header {
    color: lightslategray;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
}
.wj-cell.wj-header.wj-state-multi-selected {
    color: #647eff;
    background: white;
}
.wj-cell.wj-header-top {
    background: #96abb4 !important;
	color: white;
    font-size: 0.875rem;
    font-weight: normal;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.wj-cell.wj-header-top.wj-state-multi-selected {
    color: white;
}
.wj-cell.header-replenishment-cycle {
    color: lightslategray !important;
    background: rgb(255 243 222 / 95%) !important;
}
.wj-cell.header-lead-time {
    color: lightslategray !important;
    background: aliceblue !important;
}
.wj-cell.header-past {
    color: lightslategray !important;
    background: #f2f5f5 !important;
}
.wj-cell.header-quantity {
    color: lightslategray !important;
    background: white !important; 
}
.wj-cell.header-summary {
    color: lightslategray !important;
    background: aliceblue !important;
}
.wj-cell.header-raw_object {
    color: lightslategray !important;
    background: aliceblue !important;
}

.wj-flexgrid .wj-colheaders .wj-header.wj-state-multi-selected {
    border-bottom: 3px solid rgba(255,150,30,.75);
}
.wj-flexgrid .wj-rowheaders .wj-header.wj-state-multi-selected {
    border-right: 3px solid rgba(255,150,30,.75);
}
.wj-cells .wj-cell.wj-state-multi-selected {
    background: #e6e6e6;
    color: #222;
}
.attrs-tab-grid-old .wj-cells .wj-cell.wj-state-multi-selected {
    background: inherit;
    color: inherit;
}
.wj-flexgrid .wj-colheaders .wj-header.wj-colgroup.wj-align-center {
    justify-content: unset;
}
.wj-flexgrid .wj-colheaders .wj-header.wj-colgroup.wj-align-right {
    justify-content: unset;
}
/* .wj-cell.wj-group:not(.wj-state-selected):not(.wj-state-multi-selected) {
    background-color: white;
} */

.plan-group-1 {
    color: white !important; 
    background: #C0CCCC !important; 
    font-weight: bold !important;
}
.plan-group-col-border-1 {
    border-left: thick solid #CCCCCC;
}
.cell-quantity {
    background: #f2f5f5 !important;
    color: lightslategray !important;
}
.cell-merge0 {
    border-bottom-color: white !important;
}
.cell-merge1 {
    background: white !important;
    color: white !important;
    border-bottom-color: white !important;
}
.wj-cell.cell-info {
    background: #eeeeee !important;
    color: lightslategray !important;
}
.wj-cell.cell-analysis {
    background: #fef6d5 !important;
    color: lightslategray !important;
}
.wj-cell.cell-header {
    font-weight: bold;
    background-color: #c9d4d8 !important;
}
.wj-cell.wj-row2-header {
    background-color: white !important;
}
.wj-cell.wj-row2-top {
    border-top: 1px double #CCCCCC !important;
    border-bottom: none !important;
}
.wj-cell.wj-row2 {
    border-bottom: none !important;
}
.wj-cell.wj-row2-foot {
    border-bottom: 1px double #CCCCCC !important;
}

.row-parent2-start {
    color: black;
}
.row-parent1-start {
    color: black;
}
.row-parent0-start {
    font-weight: bold;
    color: black;
}
.row-parent0 {
    border-bottom: 4px double rgba(0, 0, 0, 0.5) !important;
    font-weight: bold !important;
    filter: brightness(98%);
}
.row-parent1 {
    border-bottom: 2px solid rgba(0, 0, 0, 0.5) !important;
    font-weight: bold !important;
    filter: brightness(98%);
}
.row-parent2 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.5) !important;
    font-weight: bold !important;
    filter: brightness(98%);
}
.row-label-title {
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: bold;
}
.row-label {
    top: 4px;
    color: #3f51b5;
    border-bottom: none !important;
}
/* background-color: #d6d6d6; */
/* box-shadow: inset 0px -2px 0px 0px #6c757d; */
/* .row-parent1-end::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    width: 100%;
} */

.row-child0 {
    border-bottom: none !important;
}
.row-child2-start {
    border-top: 2px solid rgba(0, 0, 0, 0.2) !important;
}
.row-child1-start {
    border-top: 2px solid rgba(0, 0, 0, 0.5) !important;
}
.row-child0-start {
    border-top: 4px double rgba(0, 0, 0, 0.5) !important;
}
/* .row-parent2 {
    background-color: #ebebeb;
} */

.wj-flexgrid .column-parent1-end {
    border-right: 1px solid rgba(0,0,0,.2);
}
.wj-flexgrid .column-parent0-start {
    border-left: 1px solid rgba(0, 0, 0, 0.2);
}
.wj-flexgrid .column-parent0-end {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
}

.cell-bold {
    font-weight: bold;
}
.grid-summary-line{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    height: 20px;
}
.grid-top-line{
    display: flex;
    align-items: center;
    width: -webkit-fill-available;
    padding-bottom: 1px;
    justify-content: space-between;
}
.wj-cell.spill {
    overflow: visible;
    z-index: 2 !important;
}
.wj-cell.spill_data {
    z-index: 2 !important;
}

.wj-marquee {
    box-shadow: 0 0 0 2px rgba(255,150,30,.75), inset 0 0 0 1px rgba(255,150,30,.75) !important;
    border-radius: 4px;
    z-index: 5;
}

.attrs-tab-grid .wj-marquee {
    box-shadow: 0 0 0 0 rgba(255,150,30,.75), inset 0 -1px 0 0 rgba(255,150,30,.75) !important;
    border-radius: 0px;
    z-index: 5;
}
.attrs-tab-grid:hover .wj-marquee {
    box-shadow: 0 2px 0 0 rgba(255,150,30,.75), inset 0 -1px 0 0 rgba(255,150,30,.75) !important;
    border-radius: 0px;
    z-index: 5;
}

.wj-cells .wj-row:hover .wj-cell:not(.wj-state-selected):not(.wj-state-multi-selected) {
    filter: brightness(96%);
}
.p-chips .p-chips-multiple-container .p-chips-input-token {
    padding: 1px;
}
.p-chips .p-chips-multiple-container {
    padding: 1px;
}
.p-chips .p-chips-multiple-container .p-chips-token {
    border-radius: 4px;
}

.i-chips-container {
    display: flex;
    align-items: center;
    border: 1px solid #cccccc;
    border-radius: 4px;
    padding: 5px 10px;
}
.i-chips2-container {
    display: flex;
    align-items: center;
}
.i-chip {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    background-color: #eee;
    border-radius: 4px;
    padding: 5px 10px;
}
.i-chip2 {
    display: inline-flex;
    align-items: center;
    margin-right: 4px;
    border-radius: 4px;
}
.i-chip3 {
    display: inline-flex;
    align-items: center;
    margin-right: 2px;
    background-color: #eee;
    border-radius: 4px;
}
.i-chip-content {
    margin-right: 10px;
    cursor: pointer;
}
.i-separator {
    color: #0375ff;
}
.i-chip2-content {
    cursor: pointer;
}
.i-chip-delete-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 7px;
}
.i-chip-filter {
    border: none;
    outline: none;
    flex-grow: 1;
}
body .wj-popup {
    display: flex;
    flex-direction: column;    
    min-width: 150px;
    min-height: 200px;
}
body .wj-popup .wj-dialog-body {
    flex-grow: 1;
}
body .wj-popup .wj-dialog-footer {
    margin-top: 0;
}
.iclose {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity = 20);
    opacity: .2;
}
.wj-popup .idialog-header {
    font-size: 1.5rem;
    font-weight: 500;
    color: #647eff;
    padding: 0.5rem;
    opacity: 0.75;
}
.my-input-2 {
    background: #f2f5f5;
}
.my-input-2 .wj-control {
    background: #f2f5f5;
}

.wj-row.drag-over {
    filter: drop-shadow(0px 5px 1px #ffcc66);
    z-index: 10;
    transition: all 250ms;
}
.wj-row.drag-source {
    opacity: 0.1;
    transition: all 250ms;
}

.rowHeaders-level-1 {
    border-left: 5px solid;
}
.rowHeaders-level-2 {
    border-left: 10px solid;
}
.rowHeaders-level-3 {
    border-left: 15px solid;
}
.rowHeaders-level-4 {
    border-left: 20px solid;
}
.rowHeaders-level-5 {
    border-left: 25px solid;
}
.rowHeaders-level {
    border-left-color:#d7dbe0;
}

.row-bottom-group::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 3px;
    background-image: radial-gradient(circle, #878787 1px, transparent 1px);
    background-size: 3px 3px;
}
.row-bottom-group2 {
    top: -3px !important;
}
.row-top-left-group {
    border-top-left-radius: 0.5rem;
}
.row-bottom-left-group {
    border-bottom-left-radius: 0.5rem;
}
.wj-flexgrid .wj-cell.wj-filter-on .wj-elem-filter {
    opacity: 1;
    color: rgba(255,150,30,.75);    
}
.custom-value-warning::before {
    content: '';
    position: absolute;
    left: 5%;
    bottom: 2px;
    width: 90%;
    height: 3px; 
    border-bottom: 2px dashed rgba(255, 0, 0, 0.5);
}
</style>