<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <div v-wjTooltip="attr.tooltip"
            v-wjContextMenu="'ContextWidgetMenuId'"
        >
        <!-- class="label-sheet" -->
            <label class="label-attr" v-if="attr.title && (attr.showTitle !== undefined ? attr.showTitle : true)" :for="attr.html_id">{{ attr.title }}</label>
            <Codemirror
                :id="attr.html_id"
                v-model:value="content_str"
                v-visible="handleVisibility"
                :options="cmOptions"
                @change="attr_set"
                @ready="editorReady"
            />
        </div>
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

import Codemirror from "codemirror-editor-vue3";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/mode/sql/sql.js";
import "codemirror/theme/dracula.css";
import "codemirror/addon/fold/foldcode";
import "codemirror/addon/fold/foldgutter";
import "codemirror/addon/fold/brace-fold";
import "codemirror/addon/fold/foldgutter.css";
import "codemirror/theme/neo.css";

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    data: () => ({ 
        WIDGET: {},
        content_str: '',
        cmOptions: {
            mode: "sql", // Language mode
            theme: "dracula", // Theme
            lineNumbers: true, // Show line number
            smartIndent: true, // Smart indent
            indentUnit: 4, // The smart indent unit is 2 spaces in length
            foldGutter: true, // Code folding
            gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"],
            styleActiveLine: true, // Display the style of the selected row
            // autofocus: true,
            // fontsize: "small",
        },
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
        
            let content = this.store.attr_get(this.WIDGET, this.attr)
            this.content_str = this.store.JS_stringify(content)
        },
        attr_set(input) {
            let new_content = this.store.JS_parse(this.content_str)
            this.store.attr_set(this.WIDGET, this.attr, new_content)
        },
        editorReady(editor) {
            this.editor = editor
        },
        handleVisibility(isVisible) {
            this.isVisible = isVisible
            if (isVisible && this.editor) {
                this.editor.refresh()
            }
        },
    },
    components: {
      Codemirror,
    },
}
</script>

<style scoped>
.codemirror-container {
    margin-top: 20px;
}
</style>