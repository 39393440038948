<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <div :class="['label-tab', attr.header_cssClass]" v-if="parent_type != 'AttrTabPanel' && attr.title && (attr.showTitle !== undefined ? attr.showTitle : true)" :for="attr.html_id">{{ attr.title }}</div>
        <div 
            :class="'attrs ' + attr['cssClass']"
            v-if="attr.visible !== undefined ? attr.visible : true"
            :style="attr.cssStyle || 'flex-direction:row;'"
        >
            <component v-for="attr in attr.attrs"
                :widget_id="widget_id" 
                :key="attr.html_id"
                :is="attr.component"
                :attr_id="attr.id"
            />
        </div>
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

export default {
    props: [
        'widget_id',
        'attr_id',
        'parent_type',
    ],
    data: () => ({ 
        WIDGET: {},
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
        },
    }
}
</script>

<style>
.attrs {
    width:-webkit-fill-available;
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
}
.label-attr {
    font-size: 0.875rem;
    top: 4px;
    color: #3f51b5;
    margin-left: 20px;
}
.label-tab {
    font-size: 0.875rem;
    text-transform: uppercase;
    font-weight: bold;
    border-bottom: 2px solid #77889923;
    top: 4px;
    color: #778899;
    padding: 8px 12px 8px 12px;
    margin-bottom: 8px;
}
.header-1 {
    background: #f2f5f5;
}

</style>