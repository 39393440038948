<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
       <div class="attr-tab-panel">
            <wj-tab-panel
                :initialized="initialized"
                :selectedIndexChanged="selectedIndexChanged"
                :refreshed="refreshed"
                :gotFocus="gotFocus"
                :lostFocus="lostFocus"
            >
                <wj-tab v-for="attr in attr.attrs" :key="attr.html_id" :isVisible="attr.use !== false">
                    <a v-wjTooltip="attr.tooltip" >
                        {{ attr.title }}
                        <i v-if="attr.pi" :class="attr.pi"></i>
                    </a>
                    <div>
                        <component
                            :widget_id="widget_id" 
                            :is="attr.component"
                            :attr_id="attr.id"
                            :parent_type="'AttrTabPanel'"
                        />
                    </div>
                </wj-tab>
            </wj-tab-panel>
        </div>
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

export default {
    props: [
        'widget_id',
        'attr_id',
        'parent_type',
    ],
    data: () => ({ 
        WIDGET: {},
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
            if (this.tabPanel ) {
                if (this.attr.selectedIndex && this.tabPanel.selectedIndex==0) {
                    this.tabPanel.selectedIndex = this.attr.selectedIndex
                    // delete this.attr.selectedIndex
                }
            }
        },
        initialized(tabPanel) {
            this.tabPanel = tabPanel;
            this.content_Changed()
        },
        selectedIndexChanged(tabPanel) {
            // console.log('selectedIndexChanged')
            this.store.rightArea_setDataSource(this, this.attr.binding, this.attr.configType)
            this.store.widgetResize(this, 0)

            // // refresh AttrJSON FIXED in handleVisibility(...)
            // let attrs = this.attr.attrs[tabPanel.selectedIndex].attrs,
            //     vueObj = this.WIDGET.vueObj
            // vueObj.content_Changed(attrs, null, ['AttrJSON','AttrStrMulti'])

        },
        refreshed(tabPanel) {
            this.store.widgetResize(this, 0)
        },
        gotFocus(s) {
            // console.log('gotFocus')
        },
        lostFocus(s) {
            // console.log('lostFocus')
        },
    },
    computed: {
        filteredAttrs() {
            if (this.attr?.attrs) {
                return this.attr.attrs.filter(attr => attr.use !== false)
            } else {
                return []
            }
        }
    },
}
</script>

<style>
.attr-tab-panel {
    width:-webkit-fill-available;
    /* display: flex; */
    /* flex-wrap: wrap; */
}
.wj-tabheaders {
    background: #f2f5f5;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 100;
}
.wj-tabpanes {
    padding-top: 2px;
    overflow-y: auto;
}
.wj-tabpanel>div>.wj-tabheaders>.wj-tabheader:after {
    background: rgba(255, 150, 30, .75);
}
.wj-tabpanel>div>.wj-tabheaders>.wj-tabheader.wj-state-active {
    background: #fff;
    color: #647eff;
}
</style>