<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <div class="attr-link wj-control" v-wjTooltip="tooltip()" :style="attr.cssStyle||'max-width: 25rem !important;'"
        >
            <div :class="cssClass()"
            v-if="attr.use !== false"
            >
                <!-- <wj-combo-box -->
                <wj-auto-complete
                    :class="'link-control attr-element '+attr.cssClass"
                    :id="attr.html_id"
                    :isReadOnly="isReadOnly"
                    :selectedIndexChanged="selectedIndexChanged"
                    :initialized="initialized"
                    :lostFocus="lostFocus"
                    :selectedValue="doc[binding]"
                    :selectedValuePath="'id'"
                    :displayMemberPath="'title'"
                    :formatItem="formatItem"
                    :maxItems="21"
                    :handleWheel="false"
                    v-wjContextMenu="'ContextWidgetMenuId'"
                    :showDropDownButton="false"
                >
                    <div class="link-buttons">
                        <span wj-part="btn" class="wj-input-group-btn" v-if="attr.relation_segment && !isReadOnly && (!('showListButton' in attr)||attr.showListButton) && !attr.itemsSource">
                            <button class="wj-btn wj-btn-default attr-link-button" @click="onButtonСhoiceStart__Click" tabindex="-1" type="button" aria-label="Toggle choose in list">
                                <i class="pi pi-doc pi-ellipsis-h"></i>
                            </button>
                        </span>
                        <span wj-part="btn" class="wj-input-group-btn" v-if="attr.itemsSource || (attr.relation_segment && (!isReadOnly))">
                            <button class="wj-btn wj-btn-default attr-link-button" @click="onButtonShowDroppedDown__Click" tabindex="-1" type="button" aria-label="Drop down">
                                <i class="pi pi-doc pi-angle-down"></i>
                            </button>
                        </span>
                        <span wj-part="btn" class="wj-input-group-btn" v-if="attr.relation_segment && (!('showOpenButton' in attr)||attr.showOpenButton)">
                            <button class="wj-btn wj-btn-default attr-link-button" @click="onButtonOpen__Click" tabindex="-1" type="button" aria-label="Internal link">
                                <i class="pi pi-doc pi-arrow-right"></i>
                            </button>
                        </span>
                    </div>
                </wj-auto-complete>
                    <!-- :displayMemberPath="'title'" -->
                    <!-- :itemsSource="itemsSource" -->
                    <!-- :textChanged="textChanged" -->
                    <!-- :selectedValuePath="'id'" -->
                    <!-- autocomplete="name"  -->
                    <!-- required -->
                <label v-if="attr.title && (attr.showTitle !== undefined ? attr.showTitle : true)" :for="attr.html_id">{{ attr.title }}</label>
                <!-- <div class="wj-error" tabindex="-1">We do need your name...</div> -->
            </div>

            <!-- <span wj-part="btn" class="wj-input-group-btn" v-if="attr.relation_segment && !isReadOnly && (!('showListButton' in attr)||attr.showListButton) && !attr.itemsSource">
                <button class="wj-btn wj-btn-default attr-link-button" @click="onButtonСhoiceStart__Click" tabindex="-1" type="button" aria-label="Toggle choose in list">
                    <i class="pi pi-doc pi-ellipsis-h"></i>
                </button>
            </span>
            <span wj-part="btn" class="wj-input-group-btn" v-if="attr.relation_segment && (!('showOpenButton' in attr)||attr.showOpenButton)">
                <button class="wj-btn wj-btn-default attr-link-button" @click="onButtonOpen__Click" tabindex="-1" type="button" aria-label="Internal link">
                    <i class="pi pi-doc pi-arrow-right"></i>
                </button>
            </span> -->
        </div>
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    data: () => ({ 
        WIDGET: {},
        input: null,
        // itemsSource: [],
        doc: {},
        binding: '',
        buttons_innerHTML: '',
    }),
    setup() {
        const store = useMainStore()
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
            if (this.attr.use == false) {
                return
            }

            if (this.input) {
                const [doc, binding] = this.store.attr_get_link(this.WIDGET, this.attr)
                this.doc = doc, this.binding = binding

                // get_itemsSource && set item
                // let item = this.store.attr_get(this.WIDGET, this.attr)
                this.store.get_itemsSource(this, this.input, this.attr)
            }

            this.attr.groupMenu = {
                cell_buttons: [
                    { 'tooltip': 'Open subelements for type', 'action_id':'action-front-2', 'command':'widget_open', 'widget': 'widget-list', 'cssClass':'pi pi-doc pi-folder-open' },
                    { 'tooltip': 'Drop down', 'command':'DropDown', 'cssClass':'pi pi-doc pi-angle-down' },
                    { 'tooltip': 'Edit', 'action_id':'action-front-3', 'command':'widget_open', 'widget':'widget-DOC', 'cssClass':'pi pi-doc pi-arrow-right' },
                ]
            }
            if (this.attr.groupMenu && this.attr.groupMenu.cell_buttons) {
                this.buttons_innerHTML = this.store.get_buttons_innerHTML(this.attr.groupMenu.cell_buttons)
            }
        },
        initialized(input) {
            // set input
            this.input = input
            this.content_Changed()
        },
        formatItem(s, e) {
            // console.log('formatItem')
            // if (e.item.tabIndex==0) {
            //     let buttons_innerHTML = ''
            //     if (this.buttons_innerHTML) {
            //         buttons_innerHTML = 
            //         `<div class="data-cell attr-link-button-group wj-control">
            //             ${this.buttons_innerHTML}
            //         </div>`
            //     }
            //     e.item.innerHTML = 
            //     `<div class="attr-link wj-listbox-item wj-state-selected" role="option" aria-selected="true" tabindex="0">
            //         ${e.data.title}
            //         ${buttons_innerHTML}
            //     </div>`
            // } else {
                let dataItem = e.data;
                if (dataItem.category) {
                    e.item.classList.add('category');
                }
                if (dataItem.disabled) {
                    e.item.classList.add('wj-state-disabled');
                }
                if (dataItem.pi) {
                    e.item.innerHTML = `<i class="pi ${dataItem.pi}"></i>${e.item.innerHTML}`
                }
            // }
        },
        selectedIndexChanged(input) {
            if (input.selectedItem && !input.itemsSource_myFreeze) {
                let item = this.store.attr_get(this.WIDGET, this.attr)

                //new value
                if (!item || item != input.selectedValue) {
                    this.attr_set(input.selectedValue)
                }
            }
        },
        onButtonOpen__Click() {
            // this.input.listBox.showSelection(true)
            let item = this.store.attr_get(this.WIDGET, this.attr)
            if (!item) {
                this.onButtonСhoiceStart__Click()
            } else {
                this.store.widget_open({
                    doc:{ 
                        id:item,
                        segment:this.attr.relation_segment,
                        relation_binding:this.attr.relation_binding,
                    },
                    widget: 'widget-DOC',
                    parentActionMenu: { 'action_id':'action-front-4', method: 'AttrLink.onButtonOpen__Click' },
                }, this)
            }
        },
        onButtonСhoiceStart__Click() {
            let item = this.store.attr_get(this.WIDGET, this.attr)
            this.store.open_widgetList_forAttr(item, this.attr, {command_Choice:true}, this)
        },
        choiceFinish(attr, item) {
            this.attr_set(item.id)
            this.input.hostElement.focus()
        },
        attr_set(id) {
            this.store.attr_set(this.WIDGET, this.attr, id)
        },
        lostFocus(input) {
            let newVal = input.text
            if (!input.selectedItem) {
                this.attr_set('')
                input.text = ''
                // let item = this.store.attr_get(this.WIDGET, this.attr)

                // //restore current value
                // if (item && 'title' in item && newVal && item.title.toLowerCase().includes(newVal.toLowerCase())) {
                //     input.text = item.title
                //     return
                // }

                // //find bestMatch
                // let itemsSource = this.input.itemsSource
                // if (newVal && itemsSource && itemsSource.length) {
                //     let bestMatch = this.store.findMatch(newVal, itemsSource)
                //     if (bestMatch!=-1) {
                //         input.text = itemsSource[bestMatch].title
                //         return
                //     }
                // }
                
                // //clear
                // if (newVal) {
                //     input.text = ''
                // }
                // if (item) {
                //     this.attr_set('')
                // }
            }
        },
        onButtonShowDroppedDown__Click() {
            this.input.isDroppedDown = !this.input.isDroppedDown
        },
        cssClass() {
            return `${this.attr['inputClass'] ? this.attr['inputClass'] : 'wj-labeled-input'} attr-link-input`
        },
        tooltip() {
            const tooltip2 = `<b class='tooltip-header'>id:</b> <b class='tooltip-text'> ${this.doc[this.binding]}</b>`
            return this.store.tooltip_attr(this, tooltip2)
        },
    },
	computed: {
        isReadOnly() {
            return this.WIDGET.isReadOnly||this.attr.isReadOnly||this.attr.fieldMapping?.use||false
        },
	},
    watch: {
        'store.itemsSource_change_index'(newVal, oldVal) {
            if (this.attr.relation_segment && this.attr.relation_segment==this.store.itemsSource.change_type) {
                console.log(`watch ${this.store.itemsSource.change_type} ${newVal}`)
                this.store.get_itemsSource(this, this.input, this.attr)
            }
        }
    },
}
</script>

<style>
.attr-link {
    width:-webkit-fill-available;
    display: flex;
}
.attr-link-input {
    width:-webkit-fill-available !important;
}
.attr-link .attr-link-button {
    transition: all 250ms;
    opacity: 0;
    color: lightslategray !important;
    /* display: flex; */
    /* align-items: center; */
}
.attr-link:hover .attr-link-button {
    opacity: 1;
}
.cell-value .attr-link-button {
    opacity: 1;
}
.attr-link-button {
    border-radius: 6px;
}
.wj-listbox-item.category {
    padding-top: 4px;
    padding-left: 5px;
    font-size: 120%;
    font-weight: bold;
    background: #f0f0f0;
    color: #808080;
    position: sticky;
    top: 0;
    opacity: 1;
}
.wj-listbox-item .pi {
    padding-right: 6px;
}
.link-buttons {
    position: absolute;
    right: 0;
    transition: all 250ms;
    opacity: 0;
    color: lightslategray !important;
    background: transparent;
    z-index: 1;
}
.link-buttons .pi {
    text-shadow:
            /* many shadows blur out the area around the text */
            0 0 1px white,
            0 0 2px white,
            0 0 3px white,
            0 0 4px white,
            0 0 5px white,
            0 0 6px white,
            0 0 7px white,
            0 0 8px white,
            0 0 9px white,
            0 0 10px white;
}
.link-control:hover .link-buttons {
    opacity: 0.9;
}
</style>