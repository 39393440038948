<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <div class="wj-labeled-input attr-text">
            <wj-combo-box
                :id="attr.html_id"
                :isEditable="true"
                :text="text"
                :textChanged="attr_set"
                v-wjContextMenu="'ContextWidgetMenuId'"
            />
                <!-- autocomplete="name"  -->
                <!-- required -->
            <label v-if="attr.title && (attr.showTitle !== undefined ? attr.showTitle : true)" :for="attr.html_id">{{ attr.title }}</label>
            <!-- <div class="wj-error" tabindex="-1">We do need your name...</div> -->
        </div>
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    data: () => ({ 
        WIDGET: {},
        text: ''
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
        
            this.text = this.store.attr_get(this.WIDGET, this.attr)
        },
        attr_set(input) {
            this.store.attr_set(this.WIDGET, this.attr, input.text)
        },
    }
}
</script>

<style scoped>
.attr-text {
    width:-webkit-fill-available !important;
    max-width: 25rem !important;
}
</style>