<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <div class="p-1" v-if="attr.showFilterChips">
            <span class="p-float-label">
                <Chips inputId="chips" v-model="filterChips"
                    @add="apply_filterChips"
                    @remove="apply_filterChips"
                ></Chips>
                <label for="chips">Search...</label>
            </span>
        </div>
        <div class="attr-graph" ref="attr_container"
            v-visible="handleVisibility"
        >
            <div id="graph-container" ref="sigmaRoot" class="attr-graph absolute inset-0" />
        </div>
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'
import { ref } from 'vue'
import Sigma from 'sigma'
import EdgeCurveProgram from "@sigma/edge-curve"
import { createNodePiechartProgram } from "@sigma/node-piechart"
import graphology from 'graphology'
import { animateNodes } from "sigma/utils"

import circular from "graphology-layout/circular"
import circlepack from "graphology-layout/circlepack"
import forceLayout from 'graphology-layout-force'
import forceAtlas2 from "graphology-layout-forceatlas2"
import noverlap from 'graphology-layout-noverlap'

import ForceSupervisor from "graphology-layout-force/worker"
import FA2Layout from 'graphology-layout-forceatlas2/worker'
import NoverlapLayout from 'graphology-layout-noverlap/worker'

import dagre from 'dagre'

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    data: () => ({ 
        WIDGET: {},
        state: {
            hoveredNode: undefined,
            hoveredNeighbors: undefined,
            filter_values: undefined,
            selector_values: undefined,
            selector_valuesNeighbors: undefined,
            selector_values_inEdges: undefined,
            selector_values_outEdges: undefined,

            // selectedNode: undefined,
            // suggestions: undefined,
        },
        filterChips: [],
    }),
    setup() {
        const store = useMainStore()
        return {
            store,
            sigmaRoot: ref(null),
            // sigmaInstance: shallowRef<Sigma | null>(null),
        }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
    },
    mounted() {
        this.WIDGET.attrsResize.push(this.attrResize)
        this.attrResize()

        this.isMounted = true
        // if (!this.is_load_data) {
        //     this.load_data()
        // }
    },
    beforeUnmount() {
        this.kill()
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
            
            // let dateStr = this.store.attr_get(this.WIDGET, this.attr)
            // date = wjcCore.asDate(date)
            
            const [doc, binding] = this.store.attr_get_link(this.WIDGET, this.attr)
            this.doc = doc, this.binding = binding

            const data = doc[binding]
            if (this.isMounted && data) {
                this.load_data(data)
            }
        },
        load_data(data) {
            this.params = this.store.str_toJSON(this.WIDGET, this.WIDGET?.doc?.params?.['graph-eval'] || '')

            // this.is_load_data = true
            const graph = this.graph = new graphology.Graph()
            graph.import(data)
            
            // Use degrees for node sizes:
            if (this.params['command-select']?.node?.size === 'degree') {
                // graph.forEachNode((node, atts) => {
                //     atts.size = graph.degree(node)
                // })
                const degrees = graph.nodes().map((node) => graph.degree(node))
                const minDegree = Math.min(...degrees)
                const maxDegree = Math.max(...degrees)
                const minSize = 2,
                    maxSize = 15
                
                // const degreeCountMap = {}
                // degrees.forEach((degree) => {
                //     if (!degreeCountMap[degree]) {
                //         degreeCountMap[degree] = 0
                //     }
                //     degreeCountMap[degree]++
                // })
                // const degreeValues = Object.keys(degreeCountMap).map(Number)
                // const minDegree2 = Math.min(...degreeValues)
                // const maxDegree2 = Math.max(...degreeValues)
                
                graph.forEachNode((node) => {
                    const degree = graph.degree(node)
                    const size = Math.round(minSize + ((degree - minDegree) / (maxDegree - minDegree)) * (maxSize - minSize))
                    const currentSize = graph.getNodeAttribute(node, "size") || 0
                    graph.setNodeAttribute( node, "size", size )
                })
            }

            this.layout_start({name:'command-select'})

            let options = {}
            if (this.params['command-select']?.['createNodePiechartProgram']) {
                const NodePiechartProgram = createNodePiechartProgram({
                    ...this.params['command-select']?.['createNodePiechartProgram'],
                    // defaultColor: "#BCB7C4",
                    // slices: [
                    //     { color: { value: "#F05454" }, value: { attribute: "OOS" } },
                    //     { color: { value: "#7798FA" }, value: { attribute: "opt" } },
                    //     { color: { value: "#6DDB55" }, value: { attribute: "OS" } },
                    // ]
                })

                options.nodeProgramClasses = {
                    piechart: NodePiechartProgram,
                }
            }

            this.apply_filterChips()
            this.set_highlighted()

            this.kill()
            const sigma = this.sigma = new Sigma( graph, this.sigmaRoot, {
                allowInvalidContainer: true,
                renderEdgeLabels: true, 
                labelRenderedSizeThreshold: -Infinity,
    
                edgeProgramClasses: {
                    curve: EdgeCurveProgram,
                },
                
                // defaultNodeType: "piechart",
                ...options,
                ...this.params['command-select']?.['sigma-options'] || {},
    
                // itemSizesReference: "positions",
                // zoomToSizeRatioFunction: () => 1, // (x) => x,
                // resizeNodes: false,
                // // zoomingEnabled: false,
                // panningEnabled: false,
                // initialCameraState: {
                //     x: 0,
                //     y: 0,
                //     angle: 30,
                //     ratio: 1,
                // },
            })

            if (this.params['command-select']?.animate?.use) {
                this.animateStart()

                // const nodesToDelete = graph.nodes()
                // this.animateNodeDeletion(graph, nodesToDelete, () => {
                //     console.log('Nodes and edges deleted.')
                // })
            }

            // ~~~~~~~~~~~~~~~~~~~ Bind graph interactions ~~~~~~~~~~~~~~~~~~~
            if (this.params['command-hoveredNode']?.use) {
                sigma.on("enterNode", ({ node }) => { this.setHoveredNode(node) })
                sigma.on("leaveNode", () => { this.setHoveredNode(undefined) })            

                // Render nodes accordingly to the internal state:
                // 1. If a node is selected, it is highlighted == selector
                // 2. If there is query, all non-matching nodes are greyed
                // 3. If there is a hovered node, all non-neighbor nodes are greyed
                sigma.setSetting("nodeReducer", (node, data) => {
                    const res = { ...data }

                    if (res.highlighted) {
                        //
                    } else if (this.state.hoveredNeighbors?.size || this.state.hoveredNode || this.state.selector_valuesNeighbors?.size || this.state.filter_values?.size || this.filterChips.length) {
                        if (!this.state.hoveredNeighbors?.has(node) && this.state.hoveredNode !== node && !this.state.selector_valuesNeighbors?.has(node) && !this.state.filter_values?.has(node)) {
                            res.label = ""
                            res.type = ""
                            res.color = "#f6f6f6"
                        }
                    } else if (this.params['command-select']?.node?.hideLabel) {
                        res.label = ""
                    }

                    // if (this.state.selectedNode === node) {
                    //     res.highlighted = true
                    // } else if (this.state.suggestions) {
                    //     if (this.state.suggestions.has(node)) {
                    //         res.forceLabel = true
                    //     } else {
                    //         res.label = ""
                    //         res.color = "#f6f6f6"
                    //     }
                    // }

                    return res
                })

                // Render edges accordingly to the internal state:
                // 1. If a node is hovered, the edge is hidden if it is not connected to the node
                // 2. If there is a query, the edge is only visible if it connects two suggestions
                sigma.setSetting("edgeReducer", (edge, data) => {
                    const res = { ...data }

                    // if (this.state.hoveredNode && !graph.hasExtremity(edge, this.state.hoveredNode)) {
                    //     res.hidden = true
                    // }
                    if (this.state.selector_values_inEdges?.size || this.state.selector_values_outEdges?.size || this.state.hoveredNode) {
                        res.hidden = true
                        if (this.state.selector_values_inEdges?.has(edge) && this.state.selector_values_outEdges?.has(edge)) {
                            res.hidden = false
                        } else if (this.state.selector_values_inEdges?.has(edge) || graph.target(edge) === this.state.hoveredNode) {
                            res.hidden = false
                            res.color = "#7798FA"
                        } else if (this.state.selector_values_outEdges?.has(edge) || graph.source(edge) === this.state.hoveredNode) {
                            res.hidden = false
                            res.color = "#6DDB55"
                        }
                    } else if (this.params['command-select']?.edge?.hidden) {
                        res.hidden = true
                    }
                    
                    // if (this.state.suggestions &&
                    //     (!this.state.suggestions.has(graph.source(edge)) || !this.state.suggestions.has(graph.target(edge)))
                    // ) {
                    //     res.hidden = true
                    // }
                    
                    if (this.params['command-select']?.edge?.hideLabel) {
                        res.label = ""
                    }

                    return res
                })
            }

            sigma.on("clickNode", (e) => {
                const node = e.node
                if (!graph.getNodeAttribute(node, "highlighted") || this.isDragged) {
                    graph.setNodeAttribute(node, "highlighted", true)
                } else {
                    graph.removeNodeAttribute(node, "highlighted")
                }
                this.isDragged = null

                this.get_highlighted()
            })
            sigma.on("doubleClickNode", (e) => {
                const node = e.node,
                    attrs = this.graph.getNodeAttributes(node)

                if (attrs?.doc?.id) {
                    const actionMenu = this.WIDGET?.doc?.params?.['actionMenu-doubleClickNode']
                    if (actionMenu) {
                        let vueObj = this.WIDGET.vueObj
                        vueObj.store.executeStoreMethod(vueObj, {
                            ...actionMenu,
                            doc: attrs.doc,
                        })
                    } else {
                        this.store.widget_open({
                            doc: attrs.doc,
                            widget: 'widget-DOC',
                            parentActionMenu: { 'action_id':'action-front-2024-07-23', method:'sigma.doubleClickNode' },
                        }, this)
                    }
                }

                // Prevent sigma to move camera:
                e.preventSigmaDefault()
            })
            // rightClickNode
            sigma.on("doubleClickEdge", (e) => {
                const edge = e.edge,
                    attrs = this.graph.getEdgeAttributes(edge)

                if (attrs?.doc?.id) {
                    this.store.widget_open({
                        doc: attrs.doc,
                        widget: 'widget-DOC',
                        parentActionMenu: { 'action_id':'action-front-2024-07-23-2', method:'sigma.doubleClickEdge' },
                    }, this)
                }

                // Prevent sigma to move camera:
                e.preventSigmaDefault()
            })

            // ~~~~~~~~~~~~~~~~~~~ Drag'n'drop ~~~~~~~~~~~~~~~~~~~
            if (this.params['command-dragDrop']?.use) {
                // State for drag'n'drop
                this.draggedNode = null
                this.isDragging = false

                // On mouse down on a node
                //  - we enable the drag mode
                //  - save in the dragged node in the state
                //  - highlight the node
                //  - disable the camera so its state is not updated
                sigma.on("downNode", (e) => {
                    this.isDragging = true
                    this.draggedNode = e.node

                    // Prevent sigma to move camera:
                    e.preventSigmaDefault()
                })

                // On mouse move, if the drag mode is enabled, we change the position of the this.draggedNode
                sigma.getMouseCaptor().on("mousemovebody", (e) => {
                    if (!this.isDragging || !this.draggedNode) return

                    // Get new position of node
                    const pos = sigma.viewportToGraph(e)

                    graph.setNodeAttribute(this.draggedNode, "x", pos.x)
                    graph.setNodeAttribute(this.draggedNode, "y", pos.y)
                    this.isDragged = true

                    // Prevent sigma to move camera:
                    e.preventSigmaDefault()
                    e.original.preventDefault()
                    e.original.stopPropagation()
                })

                // On mouse up, we reset the autoscale and the dragging mode
                sigma.getMouseCaptor().on("mouseup", () => {
                    if (this.draggedNode) {
                        // graph.removeNodeAttribute(this.draggedNode, "highlighted")
                    }
                    this.isDragging = false
                    this.draggedNode = null
                
                    this.layout_start({name:'command-dragDrop'})
                })

                // Disable the autoscale at the first down interaction
                sigma.getMouseCaptor().on("mousedown", () => {
                    if (!sigma.getCustomBBox()) sigma.setCustomBBox(sigma.getBBox())
                })
            }
            
            // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Create node (and edge) by click ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
            if (this.params.createNode) {
                // When clicking on the stage, we add a new node and connect it to the closest node
                sigma.on("clickStage", ({ event }) => {
                // Sigma (ie. graph) and screen (viewport) coordinates are not the same.
                // So we need to translate the screen x & y coordinates to the graph one by calling the sigma helper `viewportToGraph`
                const coordForGraph = sigma.viewportToGraph({ x: event.x, y: event.y })

                // We create a new node
                const node = {
                    ...coordForGraph,
                    size: 10,
                    color: chroma.random().hex(),
                }

                // Searching the two closest nodes to auto-create an edge to it
                const closestNodes = graph
                    .nodes()
                    .map((nodeId) => {
                        const attrs = graph.getNodeAttributes(nodeId)
                        const distance = Math.pow(node.x - attrs.x, 2) + Math.pow(node.y - attrs.y, 2)
                        return { nodeId, distance }
                    })
                    .sort((a, b) => a.distance - b.distance)
                    .slice(0, 2)

                // We register the new node into graphology instance
                const id = uuid()
                graph.addNode(id, node)

                // We create the edges
                closestNodes.forEach((e) => graph.addEdge(id, e.nodeId))
                })
            }

        },
        kill() {
            if (this.sigma) {
                this.sigma.kill()
                this.sigma = null
            }
            if (this.layout) {
                this.layout.kill()
                this.layout = null
            }
        },
        initialized(input) {
            // this.input = input
        },
        attrResize() {
            // if (!this.isVisible) return FIXEDIT Scroll bars are lost
            if (this.attrResize_timeout) {
                clearTimeout(this.attrResize_timeout)
            }
            this.attrResize_timeout = setTimeout(() => {
                this.attrResize_afterTimeout()
                this.attrResize_timeout = null
            }, 1)
        },
        attrResize_afterTimeout() {
            // console.log('attrResize')
            // if (!('attrResize' in this.attr)||this.attr.attrResize) {
                const elementForm = document.getElementById(`WIDGET${this.widget_id}`);
                // const elementForm = document.getElementById(`containerResize${this.attr.html_id}`)
                const elementFlex = this.$refs.sigmaRoot // document.getElementById(this.attr.html_id);
                // const elementTopLine = this.$refs.attr_top_line
                if (!elementForm || !elementFlex) {
                    return
                }
                const elementWidget_Rect = elementForm.getBoundingClientRect()
                const elementFlex_Rect = elementFlex.getBoundingClientRect()
                // const elementTopLine_Rect = elementTopLine.getBoundingClientRect()
                if (elementFlex_Rect.width > 10) { // if mount all elements
                    this.allow_formatItem = true
                    let newHeight = elementFlex_Rect.height
                    if (!('attrResize' in this.attr) || this.attr.attrResize || newHeight === 50) {
                        newHeight = Math.floor(elementWidget_Rect.bottom - elementFlex_Rect.top) // - 2
                        if (newHeight > elementWidget_Rect.height) {
                        // if (elementFlex_Rect.top < 0) {
                            // let visibleRect = this.getVisibleRect(elementFlex)
                            // newHeight = Math.floor(visibleRect.height) - 2
                            newHeight = 160
                        }
                        if (this.attr.size) {
                            newHeight = Math.round(newHeight * this.attr.size / 100)
                        }
                    } else {
                        return
                    }
                    if (newHeight < (this.attr.minHeight||160)) {
                        newHeight = (this.attr.minHeight||160)
                    }
                    if (this.attr.maxHeight && newHeight > this.attr.maxHeight) {
                        newHeight = this.attr.maxHeight
                    } else if (newHeight > 1500) {
                        newHeight = 1500
                    }
                    if (this.attr.heightAdd) {
                        newHeight += this.attr.heightAdd
                    }
                    // if (this.attr.showGridSummaryLine) {
                        // newHeight -= 20
                    // }
                    if (elementFlex.style.height != `${newHeight}px`) { // elementFlex_Rect.height != newHeight
                        console.log(`attrResize∆ ${this.attr.binding}: ${elementFlex_Rect.height - newHeight}`)
                        elementFlex.style.height = `${newHeight}px`
                    }
                }
            // } else {
            //     this.allow_formatItem = true
            // }
        },
        setHoveredNode(node) {
            if (node) {
                this.state.hoveredNode = node
                this.state.hoveredNeighbors = new Set(this.graph.neighbors(node))
            }

            // Compute the partial that we need to re-render to optimize the refresh
            const nodes = this.graph.filterNodes((n) => n !== this.state.hoveredNode && !this.state.hoveredNeighbors?.has(n))
            const nodesIndex = new Set(nodes)
            const edges = this.graph.filterEdges((e) => this.graph.extremities(e).some((n) => nodesIndex.has(n)))

            if (!node) {
                this.state.hoveredNode = undefined
                this.state.hoveredNeighbors = undefined
            }

            this.refresh({
                // partialGraph: {
                //     nodes,
                //     edges,
                // },
                // We don't touch the graph data so we can skip its reindexation
                // skipIndexation: true,
            })
        },
        layout_start(actionMenu) {
            this.params = this.store.str_toJSON(this.WIDGET, this.WIDGET?.doc?.params?.['graph-eval'] || '')
         
            let graph = this.graph,
                layouts = actionMenu.layouts || this.params[actionMenu.name]?.layouts || [],
                timeout = actionMenu.timeout || this.params[actionMenu.name]?.timeout || 1000

            if (this.layoutStop_timeout) {
                clearTimeout(this.layoutStop_timeout)
            }
            if (this.layout) {
                this.layout.stop()
                this.layout.kill()
                this.layout = null
            }

            for (let layout of layouts) {
                let rowLayout = this.params[layout] || {}
                if (rowLayout.layoutType === 'assign') {
                    if (rowLayout.layoutMethod === 'circular') {
                        circular.assign(graph)
                    }

                    if (rowLayout.layoutMethod === 'circlePack') {
                        circlepack.assign(graph, rowLayout.options || {})
                    }

                    if (rowLayout.layoutMethod === 'forceLayout') {
                        forceLayout.assign(graph, rowLayout.options || {})
                    }

                    if (rowLayout.layoutMethod === 'dagre') {
                        this.dagre(rowLayout.options || {})
                    }

                    if (rowLayout.layoutMethod === 'forceAtlas2') {
                        if (rowLayout.options) {
                            const settings = forceAtlas2.inferSettings(graph)
                            forceAtlas2.assign(graph, { 
                                ...rowLayout.options || {},
                                settings
                            })
                        } else {
                            forceAtlas2.assign(graph, rowLayout.options || {})
                        }
                    }

                    if (rowLayout.layoutMethod === 'noverlap') {
                        noverlap.assign(graph, rowLayout.options || {})
                    }
                } else if (rowLayout.layoutType === 'webworker') {
                    if (rowLayout.layoutMethod === 'forceLayout') {
                        this.layout = new ForceSupervisor(graph, { 
                            isNodeFixed: (_, attr) => attr.highlighted, // || (attr.size == 3 && attr.x > 0) || (attr.size != 3 && attr.x < 0),
                            ...rowLayout.options||{}
                        })
                    } else if (rowLayout.layoutMethod === 'forceAtlas2') {
                        if (rowLayout.options) {
                            const settings = forceAtlas2.inferSettings(graph)
                            this.layout = new FA2Layout(graph, { 
                                ...rowLayout.options || {},
                                settings
                            })
                        } else {
                            this.layout = new FA2Layout(graph, rowLayout.options || {})
                        }
                    } else if (rowLayout.layoutMethod === 'noverlap') {
                        this.layout = new NoverlapLayout(graph, rowLayout.options || {})
                    }
                }
            }
                
            if (this.layout) {
                this.layout.start()
                
                // this.layout.on('step', () => {
                //     graph.forEachNode((nodeKey, attr) => {
                //         if (attr.size === 3 && attr.x > 0) {
                //             graph.setNodeAttribute(nodeKey, 'x', 0)
                //         }
                //     })
                // })
    
                this.layoutStop_timeout = setTimeout(() => {
                    if (this.layout) {
                        this.layout.stop()
                    }
                    this.layoutStop_timeout = null
                }, timeout)
            }
        },
        animateExpansion(duration = 1000) {
            const startTime = performance.now(),
                graph = this.graph,
                sigmaInstance = this.sigma,
                camera = sigmaInstance.getCamera()
            
            function step() {
                const currentTime = performance.now()
                const elapsedTime = currentTime - startTime
                const progress = Math.min(elapsedTime / duration, 1)
                
                // // Update node positions based on progress
                // graph.forEachNode((node, attributes) => {
                //     attributes.x = attributes.initialX * progress
                //     attributes.y = attributes.initialY * progress
                //     graph.setNodeAttribute(node, attributes)
                // })

                camera.setState({
                    // x: x,
                    // y: y,
                    ratio: 1 + (1-progress)*10,
                })

                sigmaInstance.refresh({
                    // keepCameraState: true,
                    skipIndexation: true,
                    // partialGraph: {
                    //     nodes: ['node1', 'node2'], // Only refresh these nodes
                    //     edges: ['edge1', 'edge2']  // Only refresh these edges
                    // },
                })

                if (elapsedTime < duration) {
                    requestAnimationFrame(step)
                }
            }
            
            requestAnimationFrame(step)
        },
        animateStart() {
            const graph = this.graph,
                sigmaInstance = this.sigma,
                camera = sigmaInstance.getCamera()
            
            // // Store initial positions and start animation
            // graph.forEachNode((node, attributes) => {
            //     attributes.initialX = attributes.x
            //     attributes.initialY = attributes.y
            //     attributes.x = 0
            //     attributes.y = 0
            //     graph.setNodeAttribute(node, attributes)
            // })

            // this.animateExpansion(this.params.webworker.timeout)

            camera.setState({
                // x: 100,       // Move the camera to x = 100
                // y: 200,       // Move the camera to y = 200
                ratio: 5,
                angle: Math.PI / 4  // Rotate the view by 45 degrees (π/4 radians)
            })
            camera.animate({
                // x: 0,
                // y: 0,
                ratio: 1,
                angle: 0
            },{
                duration: 1000,   // Duration of the animation in milliseconds
                easing: 'quadraticInOut',  // Easing function for the animation
                // onNewFrame: () => {
                //     graph.setNodeAttribute(nodeKey, 'size', actualSizes[nodeKey]);
                //     renderer.refresh({
                //         skipIndexation: true
                //     })
                // }
            })

            // animateNodes(graph, { [centralNodeId]: { size: 20 } }, { duration: 1000 })
            // animateNodes(graph, edges, { duration: 1000, easing: 'cubicInOut' })
        },
        animateNodeDeletion(graph, nodes, callback) {
            const nodesToAnimate = {}
            const edgesToAnimate = {}

            nodes.forEach((node) => {
                nodesToAnimate[node] = { size: 0 }
                graph.forEachEdge((edge, attributes, source, target) => {
                    if (source === node || target === node) {
                        edgesToAnimate[edge] = { size: 0 }
                    }
                })
            })

            animateNodes(graph, nodesToAnimate, {
                duration: 10000,
                easing: 'linear',
                onComplete: () => {
                    nodes.forEach((node) => graph.dropNode(node))
                    Object.keys(edgesToAnimate).forEach((edge) => graph.dropEdge(edge))
                    if (callback) callback()
                },
            })
        },
        dagre(options) {
            let graph = this.graph
            const dagreGraph = new dagre.graphlib.Graph()
            
            dagreGraph.setGraph(options)
            dagreGraph.setDefaultEdgeLabel(() => ({}))

            // nodes++
            graph.forEachNode((node, attrs) => {
                dagreGraph.setNode(node, { label: node, width: attrs.size, height: attrs.size })
            })

            // edge++
            graph.forEachEdge((edge, attrs, source, target) => {
                dagreGraph.setEdge(source, target, { weight: attrs.size })
            })

            dagre.layout(dagreGraph)

            dagreGraph.nodes().forEach(node => {
                const pos = dagreGraph.node(node)
                graph.setNodeAttribute(node, 'x', pos.x)
                graph.setNodeAttribute(node, 'y', pos.y)
            })

            if (this.sigma) {
                this.refresh()
            }
        },
        handleVisibility(isVisible) {
            this.isVisible = isVisible
            if (isVisible) {
                this.refresh()
            }
        },
        set_highlighted() {
            // WIDGET.doc.selector_values > highlighted

            const graph = this.graph

            for (const node of graph.nodes()) {
                graph.setNodeAttribute(node, 'highlighted', false);
            }
            for (const node of this.WIDGET.doc.selector_values || []) {
                if (graph.hasNode(node)) {
                    graph.setNodeAttribute(node, 'highlighted', true)
                }
            }
            this.get_highlighted()
        },
        get_highlighted() {
            // highlighted > selector_values... > WIDGET.doc.selector_values

            const graph = this.graph

            this.state.selector_values = new Set()
            this.state.selector_valuesNeighbors = new Set()
            for (let node of graph.nodes()) {
                if (graph.getNodeAttribute(node, "highlighted")) {
                    this.state.selector_values.add(node)
                    for (let node2 of this.graph.neighbors(node)) {
                        this.state.selector_valuesNeighbors.add(node2)
                    }
                }
            }
            
            this.state.selector_values_inEdges = new Set()
            this.state.selector_values_outEdges = new Set()
            for (let node of this.state.selector_values) {
                for (let edge of this.graph.inEdges(node)) {
                    this.state.selector_values_inEdges.add(edge)
                }
                for (let edge of this.graph.outEdges(node)) {
                    this.state.selector_values_outEdges.add(edge)
                }
            }

            this.WIDGET.doc.selector_values = Array.from(this.state.selector_values)
            this.WIDGET.doc.params.flex_havActiveSelectors = this.WIDGET.doc.selector_values.length > 0
            this.WIDGET.attrs_vueObj.WIDGET.set_commandPanel()
            // this.store.executeStoreMethod(this, { 'command': 'onApplySelector__Click' } )
        },
        refresh(params={}) {
            // Refresh rendering
            if (this.sigma) {
                this.sigma.refresh({
                    // partialGraph: {
                    //     nodes,
                    //     edges,
                    // },
                    // We don't touch the graph data so we can skip its reindexation
                    skipIndexation: true,
                    ...params,
                })
            }
        },
        // ------------------ Chips -----------------------------------
        apply_filterChips(e) {
            const graph = this.graph

            let filterChips = []
            for (let i = 0; i < this.filterChips.length; i++) {
                filterChips.push(this.filterChips[i].trim().toLowerCase())
            }

            this.state.filter_values = new Set()
            for (const node of graph.nodes()) {
                if (this.check_filterChips(node.toLowerCase() || '', filterChips)) {
                    this.state.filter_values.add(node)
                } else if (this.check_filterChips(graph.getNodeAttribute(node, 'label')?.toLowerCase() || '', filterChips)) {
                    this.state.filter_values.add(node)
                }
            }
            if (e) {
                this.refresh()
            }
        },
        check_filterChips(title, filterChips) {
            for (let ifilter = 0; ifilter < filterChips.length; ifilter++) {
                if (title.indexOf(filterChips[ifilter]) >= 0) {
                    return true
                }
            }
            return false
        },
        // -----------------------------------------------------

    }
}
</script>

<style>
.attr-graph {
    width: 100%;
    height: 300px;
}
</style>