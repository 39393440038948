import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';
import { mapActions } from 'pinia'
import { useMainStore } from './stores/mainStore'

const routes = [
    {
        path: '/',
        name: 'app',
        component: App,
        children: [
            {
                path: '/:dbid',
                name: 'db',
                component: () => import('./pages/Page.vue'),
                props: route => ({
                    dbid: route.params.dbid,
                    process_model: route.query.process_model,
                    workspace: route.query.workspace,
                    page: route.query.page,
                }),
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/:dbid/login',
        name: 'dbLogin',
        component: () => import('./pages/Login.vue'),
        props: route => ({
            dbid: route.params.dbid,
            process_model: route.query.process_model,
            workspace: route.query.workspace,
            page: route.query.page,
        }),
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})

router.beforeEach((to, from, next) => {
    console.log('router.beforeEach')
    const store = useMainStore(),
    isLogin = (to.name === 'login' || to.name === 'dbLogin')
    store.router = router

    if (!isLogin && store.isAuthenticated) {
        if (store.user?.database?.dbid != to.params.dbid) {
            return store.logout(to, next)
        } else if (to.name == 'db' && !to.query.process_model && store.process_model_doc?.id) {
            next({ name:to.name, params:to.params, query: { ...to.query,
                process_model: store.process_model_doc.id,
            }})
            return
        } else if (to.name == 'db' && !to.query.workspace && store.activeWORKSPACE?.workspace) {
            next({ name:to.name, params:to.params, query: { ...to.query,
                workspace: store.activeWORKSPACE.workspace,
            }})
            return
        } else if (!to.query.process_model || to.query.process_model !== store.process_model_doc?.id) {
            store.setup(to, next)
            return
        } else if (!store.menuTree.length) {
            store.get_menuTree(to, next)
            return
        } else if (!to.query.workspace) {
            return store.logout(to, next)
        }

        // from // TODO check change settings
        if ((from?.name || '').includes('page-settings') && store.activeWIDGET) {
            store.saveDoc(store.activeWIDGET.vueObj, store.activeWIDGET.doc, {
                parentActionMenu: 'router.beforeEach',
            })
        }
    }

    window.scrollTo(0, 0);
    if (!isLogin && !store.isAuthenticated) {
        let session_key = store.getCookie(`session_key_${to.params.dbid}`)
        if (session_key) {
            store.check_sessionKey(to, next)
            return 
        } else {
            return store.logout(to, next)
        }
    } else {
        if (to.name == 'db') {
            const WORKSPACE = store.WORKSPACES[to.query.workspace]
            if (!WORKSPACE) {
                store.workspace_open(to.query.workspace, to.query.page)
                return
            }
            if (!to.query.page) {
                next({ name:to.name, params:to.params, query: { ...to.query,
                    page: WORKSPACE.active_page || Object.keys(WORKSPACE.PAGES)[0],
                }})
                return
            }
            if (!(to.query.page in WORKSPACE.PAGES)) {
                let page = WORKSPACE.active_page
                if (!(page in WORKSPACE.PAGES)) {
                    page = Object.keys(WORKSPACE.PAGES)[0]
                }
                next({ name:to.name, params:to.params, query: { ...to.query,
                    page,
                }})
                return
            }
        }

        next()
        return
    }
})

router.afterEach((to, from) => {
    console.log('router.afterEach')
    const store = useMainStore()

    const lastMatchedRoute = to.matched[to.matched.length - 1]
    if (lastMatchedRoute) {
        // const props = lastMatchedRoute.props.default
        // if (props && props.workspace && props.page) {
        if (to.query?.workspace && to.query?.page) {
            let WORKSPACE = store.WORKSPACES[to.query.workspace],
                PAGE = WORKSPACE?.PAGES[to.query.page]
            if (PAGE?.vueObj) {
                store.workspace_activate(to.query.workspace, to.query.page)
            }
        }
    }
})

export default router;
