<template>
    <icon :width="24" :height="24" viewBox="0 0 24 20" fill="#819cce">
        <path
            d="M4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H16L12,22L8,18H4A2,2 0 0,1 2,16V4A2,2 0 0,1 4,2M4,4V16H8.83L12,19.17L15.17,16H20V4H4M11,6H13V9H16V11H13V14H11V11H8V9H11V6Z"
        />
    </icon>
</template>

<script>
    import Icon from './Icon.vue';

    export default {
        components: {
            Icon,
        },
    }
</script>

<style scoped>

</style>