<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <!-- <div class="attr-containerResize" :id="'containerResize'+attr.html_id"> -->
            <div class="label-sheet" v-if="attr.title && (attr.showTitle !== undefined ? attr.showTitle : true)" :for="attr.html_id">{{ attr.title }}</div>
            <div class="commandPanel-menu">
                <component
                    v-for="attrM in commandPanel"
                    :key="attrM.html_id"
                    :widget_id="widget_id" 
                    :is="attrM.component"
                    :attr_id="attrM.id"
                />
            </div>
            <div class="attr-sheet" ref="attr_container" >
                <wj-flex-sheet 
                    :id="attr.html_id"
                    class="sheet attr-sheet-input"
                    :initialized="initialized"
                    :autoGenerateColumns="false"
                    :formatItem="formatItem"
                    v-wjTooltip="attr.tooltip"
                    v-visible="handleVisibility"
                    
                    :headersVisibility="attr.headersVisibility||'All'"
                    :beginningEdit="beginningEdit"
                    :cellEditEnding="cellEditEnding"
                    :cellEditEnded="cellEditEnded"
                    :pastingCell="pastingCell"
                    :pastedCell="pastedCell"
                    
                    :pasted="pasted"
                    :pasting="pasting"
                    
                    :updatingView="updatingView"
                    :updatingLayout="updatingLayout"
                    :updatedLayout="updatedLayout"
                    :updatedView="updatedView"
                    :selectionChanged="selectionChanged"
                    
                    :showSort="true"
                    :isTabHolderVisible="false"
                    :allowAddNew="!('allowAddNew' in attr) || attr.allowAddNew"
                    :frozenRows="attr.frozenRows"
                    @click="click"
                    @dblclick="dblclick"
                    v-wjContextMenu="'ContextWidgetMenuId'"
                    :autoSearch="true"
                >
                    <!-- :rowEditEnding="rowEditEnding" -->
                    <wj-sheet 
                        :name="'Data'" 
                        >
                    </wj-sheet>
                </wj-flex-sheet>
                <!-- template for buttons on AttrLink -->
                <div id="tplBtnAttrDoc_choice" style="display:none">
                    <span wj-part="btn" class="wj-input-group-btn">
                        <button class="wj-btn wj-btn-default attr-doc-button my-text-shadow" id="onButtonСhoiceStart__Click" tabindex="-1" type="button" aria-label="Toggle choose in list">
                            <i class="pi pi-doc pi-ellipsis-h" id="onButtonСhoiceStart__Click"></i>
                        </button>
                    </span>
                </div>
                <div id="tplBtnAttrDoc_showDroppedDown" style="display:none">
                    <span wj-part="btn" class="wj-input-group-btn">
                        <button class="wj-btn wj-btn-default attr-doc-button my-text-shadow" id="onButtonShowDroppedDown__Click" tabindex="-1" type="button" aria-label="Internal link">
                            <i class="pi pi-doc pi-angle-down" id="onButtonShowDroppedDown__Click"></i>
                        </button>
                    </span>
                </div>
                <div id="tplBtnAttrDoc_open" style="display:none">
                    <span wj-part="btn" class="wj-input-group-btn">
                        <button class="wj-btn wj-btn-default attr-doc-button my-text-shadow" id="onButtonOpen__Click" tabindex="-1" type="button" aria-label="Internal link">
                            <i class="pi pi-doc pi-arrow-right" id="onButtonOpen__Click"></i>
                        </button>
                    </span>
                </div>

                <div id="tplBtn_OpenGroup" style="display:none">
                    <div role="button" class="wj-elem-collapse" id="onButtonCollapsed__Click">
                        <span class="pi pi-doc pi-plus-circle" id="onButtonCollapsed__Click"></span>
                    </div>
                </div>
                <div id="tplBtn_CloseGroup" style="display:none">
                    <div role="button" class="wj-elem-collapse" id="onButtonCollapsed__Click">
                        <span class="pi pi-doc pi-minus-circle" id="onButtonCollapsed__Click"></span>
                    </div>
                </div>
                <div id="tplBtn_ApplySelector" style="display:none">
                    <span wj-part="btn" class="wj-input-group-btn">
                        <button class="wj-btn wj-btn-default attr-doc-button my-text-shadow" id="onSelector__Click" tabindex="-1" type="button" aria-label="Internal link">
                            <i class="pi pi-doc pi-check-square" id="onSelector__Click"></i>
                        </button>
                    </span>
                    <!-- <div role="button" class="wj-elem-selector" id="onSelector__Click">
                        <span class="pi pi-doc pi-check-square" id="onSelector__Click"></span>
                    </div> -->
                </div>
            </div>
            <div class="grid-summary-line" v-if="attr.showGridSummaryLine">
                <p v-html="currentSelectionHTML"></p>
            </div>
        <!-- </div> -->
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'
import * as wjcCore from '@mescius/wijmo';
import * as wjGrid from '@mescius/wijmo.grid';
import { InputDate, InputTime, ComboBox, AutoComplete, InputColor, InputNumber } from "@mescius/wijmo.input";
import { h,createApp } from 'vue/dist/vue.esm-bundler.js';
import "@mescius/wijmo.vue2.grid.sheet";

// import { UndoStack } from '@mescius/wijmo.undo';
export let tooltip = new wjcCore.Tooltip();

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    data: () => ({ 
        WIDGET: {},
        data: [],
        columns: [],
        col_input: {},
        col_itemsSource: {},
        attr_by_binding: {},
        flex_isEditing: false,

        headerWijmoRows: 1,
        headerRows: 1,
        headerColumns: 0,
        row_bottom: 0,
        column_left: 0,

        changesStack: {},
        data_overrides: {},
        undoStack: [],
        redoStack: [],
        actionStack: 0,
        canUndo: false,
        canRedo: false,
        actionCount: 0,

        rowLevelGroup_ranges_length: 0,
        commandPanel: [],
        topLeftMenu_cssClass: 'pi-arrow-right',
        currentSelectionHTML: '',
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
        // this.attr.groupMenu = {
        //     commandPanel: [
        //         {"tooltip":"Select","command":"executeWidgetMethod","method":"select","cssClass":"pi pi-refresh","type":"AttrButton","id":"root_4_AttrButton","html_id":"root_4_AttrButton","component":"AttrButton","attrs":[]}
        //     ],
        // }
    },
    mounted() {
        this.WIDGET.attrsResize.push(this.attrResize)
        // this.store.setObserver_attrResize(this)
    },
    methods: {
        formatItem(flex, e) {
            if (e.panel == flex.cells) {
                let col = flex.columns[e.col],
                    irow = e.row,
                    col_binding = col.binding,
                    item = flex.rows[irow].dataItem,
                    attr = this.attr_by_binding[col_binding];

                // rowGroups collapse
                if (item && attr && attr.binding=='groupsRow') { // this.attr.collapseColumnsCount
                    let collapseButton = ''
                    if (irow >= this.headerRows && item && 'isCollapsed' in item) {
                        let classCollapse = ''
                        if (item.isCollapsed) {
                            classCollapse = 'wj-glyph-right'
                        } else if (item.collapseFrom < irow) {
                            classCollapse = 'wj-glyph-up'
                        } else {
                            classCollapse = 'wj-glyph-down'
                        }
                        collapseButton = 
                        `<div class="data-cell">
                            <button id="onButtonCollapsed2__Click" class="wj-btn wj-btn-glyph wj-elem-collapse" type="button" tabindex="-1" aria-label="Toggle Group" aria-expanded="false">
                                <span id="onButtonCollapsed2__Click" class="${classCollapse}"></span>
                            </button>
                        </div>`
                    }
                    if (item.collapseBottom) {
                        e.cell.style.cssText += `border-bottom: none;`
                    }
                    if ('collapseLine' in item && !item.isCollapsed) {
                        // let collapseItem = flex.rows[item.collapseIndex+this.headerWijmoRows].dataItem
                        // e.cell.style.cssText += `border-right: 3px solid ${collapseItem.cssStyleChart.stroke};`
                        e.cell.classList.add(item.collapseLine)
                    }
                    // if (item && item.cssStyle) {
                    //     console.log(irow)
                    //     e.cell.style.cssText += item.cssStyle
                    // }

                    e.cell.innerHTML =
                    `${collapseButton}`
                    
                    if (collapseButton) {
                        e.cell.children[0].data_cell = { irow: irow }
                        // e.cell.style.cssText += `display: flex;`
                    }
                    return
                }

                if (attr && attr.cssStyle) {
                    e.cell.style.cssText += attr.cssStyle
                }
                if (item && item.cssStyleChart && item.cssStyleChart.stroke && item.visibleChart) {
                // if ((col_binding=='visibleChart' || col_binding=='measure') && item && item.cssStyleChart && item.cssStyleChart.stroke && item.visibleChart) {
                    e.cell.style.cssText += `border-bottom: 3px solid ${item.cssStyleChart.stroke};`
                    // e.cell.style.cssText += `text-decoration: underline; text-decoration-color: ${item.cssStyleChart.stroke};`
                // } else if (item && item.collapseBottom && e.col<2) {
                //     e.cell.style.cssText += `border-bottom: none;`
                }
                // if (col_binding=='visibleChart' && item && item.cssStyleChart && item.cssStyleChart.stroke && item.visibleChart) {
                //     e.cell.style.cssText += `background: ${item.cssStyleChart.stroke};`
                // } else 
                if (item && item.cssStyle) {
                    e.cell.style.cssText += item.cssStyle
                }
                if (item && item.cssClass) {
                    wjcCore.addClass(e.cell, item.cssClass)
                }

                // columnHeaders tooltip
                if (irow==0) {
                    if (attr.tooltip) {
                        tooltip.setTooltip(e.cell, attr.tooltip)
                    } else {
                        tooltip.setTooltip(e.cell, attr.title)
                    }
                }

                // BtnAttrDoc
                if (attr && attr.component == 'AttrLink' && irow >= this.headerRows ) {
                    wjcCore.addClass(e.cell, 'attr-doc')
                    let div1 = document.createElement('div')
                    div1.classList = ["data-cell attr-doc-button-group wj-control"]
                    e.cell.appendChild(div1)
                    // var parentDiv = e.cell.parentNode;
                    // parentDiv.insertBefore(div1, e.cell)

                    let innerHTML = ''
                    if (attr.isReadOnly || this.attr.isReadOnly) {
                        innerHTML += document.getElementById('tplBtnAttrDoc_open').innerHTML
                    } else if (!attr.relation_segment) {
                        // innerHTML += document.getElementById('tplBtnAttrDoc_showDroppedDown').innerHTML
                    } else {
                        innerHTML += document.getElementById('tplBtnAttrDoc_choice').innerHTML
                        // innerHTML += document.getElementById('tplBtnAttrDoc_showDroppedDown').innerHTML
                        innerHTML += document.getElementById('tplBtnAttrDoc_open').innerHTML
                    }
                    if (innerHTML!='') {
                        div1.innerHTML = innerHTML
                    }

                    div1.data_cell = {
                        irow: irow,
                        col_binding: col_binding,
                        row: e.row,
                        col: e.col
                    }
                }

                // row padding
                if (irow >= this.headerRows && e.col==0 && item) { //  && item.icol_group
                    e.cell.style.cssText += `padding-left: ${30+20*item.icol_group}px !important;`
                }

                // // rowGroups collapse
                // if (irow >= this.headerRows && e.col==0 && item && 'isCollapsed' in item) {
                //     let div1 = document.createElement('div')
                //     div1.style.cssText += `padding: 6px 0px 6px ${6+20*item.icol_group}px !important;`
                //     div1.classList = ["data-cell attr-row-button-group wj-control"]
                //     e.cell.appendChild(div1)
                //     if (item.isCollapsed) {
                //         div1.innerHTML = document.getElementById('tplBtn_OpenGroup').innerHTML
                //     } else {
                //         div1.innerHTML = document.getElementById('tplBtn_CloseGroup').innerHTML
                //     }
                //     div1.data_cell = {
                //         irow: irow,
                //     }
                //     // e.cell.innerHTML = `<input type="checkbox" v-model="allocGr.isCollapsed">` + e.cell.innerHTML
                // }

                // columnGroups collapse
                if (attr && irow ==0 && e.col >= this.headerColumns && 'isCollapsed' in attr) {
                    let div1 = document.createElement('div')
                    e.cell.style.cssText += `padding-left: 30px !important;`
                    div1.style.cssText += `padding: 6px 0px 6px 6px !important;`
                    div1.classList = ["data-cell attr-column-button-group wj-control"]
                    e.cell.appendChild(div1)
                    if (attr.isCollapsed) {
                        div1.innerHTML = document.getElementById('tplBtn_OpenGroup').innerHTML
                    } else {
                        div1.innerHTML = document.getElementById('tplBtn_CloseGroup').innerHTML
                    }
                    div1.data_cell = {
                        col_binding: col_binding,
                    }
                }

                // row ApplySelector
                if (attr && irow >= this.headerRows && attr.addBtn_ApplySelector) {
                    wjcCore.addClass(e.cell, 'attr-selector')
                    let div1 = document.createElement('div')
                    div1.classList = ["data-cell attr-doc-button-group wj-control"]
                    e.cell.appendChild(div1)
                    div1.innerHTML = document.getElementById('tplBtn_ApplySelector').innerHTML
                    div1.data_cell = {
                        irow: irow,
                    }
                    // e.cell.innerHTML = `<input type="checkbox" v-model="allocGr.isCollapsed">` + e.cell.innerHTML
                }
                
                // changesStack (undoStack)
                let has_stack = false
                if (item && attr) {
                    let i = this.data.indexOf(item)
                    if (`${i}:${col_binding}` in this.changesStack) {
                        wjcCore.addClass(e.cell, 'wj-has-stack')
                        tooltip.setTooltip(e.cell, `<b>Old value:</b><br/>${this.changesStack[`${i}:${col_binding}`]}`);
                        has_stack = true
                    } else if (`${item.measure}:${attr.date}` in this.data_overrides) {
                        wjcCore.addClass(e.cell, 'wj-has-override')
                        let row_overrides = this.data_overrides[`${item.measure}:${attr.date}`]
                        tooltip.setTooltip(e.cell, `<b>Old value:</b><br/>${row_overrides.oldVal}<br/>${row_overrides.tooltip}`);
                        has_stack = true
                    }

                    // if (irow >= this.headerRows && e.col >= this.headerColumns && item && !item.levelGroup) {
                    //     for (let iStack=this.undoStack.length-1; iStack>=0 && this.undoStack[iStack].actionStack > this.actionStack-100; iStack--) {
                    //         if (this.undoStack[iStack].i==irow-this.headerWijmoRows && this.undoStack[iStack].col_binding==col_binding) {
                    //             wjcCore.addClass(e.cell, "wj-has-stack");
                    //             // wjcCore.addClass(e.cell, 'wj-has-notes')
                    //             tooltip.setTooltip(e.cell, `<b>Old value:</b><br/>${this.undoStack[iStack].val}`);
                    //             break
                    //         }
                    //     }
                    // }
                }

                // MD.tooltip, cellsStyle
                if (irow >= this.headerRows && item && item.MD) {
                    if (item.MD.tooltip && item.MD.tooltip[col_binding]) {
                        tooltip.setTooltip(e.cell, item.MD.tooltip[col_binding]);
                    }
                    if (item.MD.rowStyle) {
                        e.cell.style.cssText += item.MD.rowStyle
                    }
                    if (item.MD.cellsStyle && item.MD.cellsStyle[col_binding]) {
                        e.cell.style.cssText += item.MD.cellsStyle[col_binding]
                    }
                    if (item.MD.cssClass && item.MD.cssClass[col_binding]) {
                        wjcCore.addClass(e.cell, item.MD.cssClass[col_binding])
                    }
                }

                // 0
                if (item && item[col_binding]==0 && !has_stack) {
                    wjcCore.addClass(e.cell, 'cell-zero')
                }

                // hide empty rows
                if (this.attr.hideEmptyRows && item) {
                    let isEmpty = true
                    for (let attr of this.attr.attrs) {
                        if (attr.component == 'AttrNumber' && item[attr.binding]) {
                            isEmpty = false
                            break
                        }
                    }
                    if (isEmpty) {
                        wjcCore.addClass(e.cell, 'cell-empty')
                    }
                }

            } else if (e.panel == flex.rowHeaders) { //  && e.col == 0
                let rowTitle = e.row+1-this.headerWijmoRows,
                    irow = e.row,
                    item = flex.rows[irow].dataItem;
                if (item && e.row >= this.headerWijmoRows) {
                    if (1!=1 && this.attr.collapseColumnsCount && e.col < this.attr.collapseColumnsCount) {
                        // rowGroups collapse
                        let collapseButton = ''
                        if (irow >= this.headerRows && item && 'isCollapsed' in item) {
                            let classCollapse = ''
                            if (item.isCollapsed) {
                                classCollapse = 'wj-glyph-right'
                            } else if (item.collapseFrom < irow) {
                                classCollapse = 'wj-glyph-up'
                            } else {
                                classCollapse = 'wj-glyph-down'
                            }
                            collapseButton = 
                            `<div class="data-cell">
                                <button id="onButtonCollapsed2__Click" class="wj-btn wj-btn-glyph wj-elem-collapse" type="button" tabindex="-1" aria-label="Toggle Group" aria-expanded="false">
                                    <span id="onButtonCollapsed2__Click" class="${classCollapse}"></span>
                                </button>
                            </div>`
                        }
                        if (item.collapseBottom) {
                            e.cell.style.cssText += `border-bottom: none;`
                        }
                        // if (item && item.cssStyle) {
                        //     console.log(irow)
                        //     e.cell.style.cssText += item.cssStyle
                        // }

                        e.cell.innerHTML =
                        `${collapseButton}`
                        
                        if (collapseButton) {
                            e.cell.children[0].data_cell = { irow: irow }
                            e.cell.style.cssText += `display: flex;`
                        }
                    } else if (rowTitle) {
                        let rowHeaderButton = ''
                        if ((this.attr.groupMenu?.topLeftMenu?.length) || this.dblclick_action) {
                            // if (item && item.cssStyleChart && item.cssStyleChart.stroke) {
                            //     e.cell.style.cssText += `background: ${item.cssStyleChart.stroke}`
                            // // } else if (item && item.cssStyle) {
                            // //     e.cell.style.cssText += item.cssStyle
                            // }
                            rowHeaderButton = 
                            `<div class="row-header">
                                <button class="button-hover wj-btn wj-btn-default attr-doc-button my-text-shadow" id="onHeader__Click" tabindex="-1" type="button" aria-label="Internal link">
                                    <i class="pi pi-doc ${this.topLeftMenu_cssClass}" id="onHeader__Click"></i>
                                </button>
                            </div>`
                        }
                        e.cell.innerHTML =
                        `<div><div>${rowTitle}</div></div>${rowHeaderButton}`
                    }
                } else {
                    e.cell.innerHTML = ''
                }
            } else if (e.panel == flex.columnHeaders) {
                let colTitle = e.col+1-this.headerColumns,
                    col = flex.columns[e.col],
                    col_binding = col.binding,
                    attr = this.attr_by_binding[col_binding];
                    
                if (attr && e.col >= this.headerColumns) {
                    e.cell.innerHTML = `${colTitle}`
                } else {
                    e.cell.innerHTML = ''
                }
            }
        },
        content_Changed(command=null) {
            this.store.widget_attr_set(this)
            if (command=='changeAttrState') {
                this.attrResize()
                return
            }
            this.data = this.store.attr_get(this.WIDGET, this.attr)
            if (!this.data) {
                this.store.attr_set(this.WIDGET, this.attr, [])
                this.data = this.store.attr_get(this.WIDGET, this.attr)
            }
            tooltip.dispose()
            this.changesStack = {}
            this.undoStack.length = 0
            this.redoStack.length = 0
            this.flex_isEditing = this.attr.flex_isEditing||false

            if (this.flex) {
                // if (this.attr.collapseColumnsCount) {
                //     this.flex.rowHeaders.columns.push(new wjGrid.Column()) // onButtonCollapsed2__Click
                // }

                this.attrResize()
                this.dblclick_action = this.store.dblclick(this.WIDGET.vueObj, this.attr, false)
                let selectedRanges = this.flex.selectedRanges
                this.flex.selectionMode = this.attr.selectionMode||'ListBox'
                this.flex.isReadOnly = this.attr.isReadOnly||false
                this.flex.showMarquee = !this.flex.isReadOnly
                if ('showFilterIcons' in this.attr) {
                    this.flex.showFilterIcons = this.attr.showFilterIcons
                }

                if (this.attr.groupMenu?.topLeftMenu?.length) {
                    this.topLeftMenu_cssClass = this.attr.groupMenu.topLeftMenu[0].cssClass
                }
                // this.flex.itemsSource = this.data
                
                //columns
                this.attr_by_binding = {}
                this.flex.columns.clear()
                if (this.attr.collapseColumnsCount) {
                    let col = new wjGrid.Column();
                    col.binding = 'groupsRow'
                    col.name = ''
                    col.width = 25
                    this.flex.columns.push(col)
                    this.attr_by_binding[col.binding] = {
                        binding: 'groupsRow',
                    }
                }
                for (let attr of this.attr.attrs) {
                    let col = new wjGrid.Column();
                    col.binding = attr.binding
                    col.name = attr.title
                    col.cssClass = attr.cssClass
                    col.align = attr.align
                    col.width = attr.width
                    col.minWidth = attr.minWidth
                    col.isReadOnly = attr.isReadOnly
                    col.visible = (!('visible' in attr) || attr.visible)
                    // col.header = attr.title
                    
                    col.type = attr.component
                    if (attr.component == 'AttrLink') {
                        if (!col.minWidth) {
                            col.minWidth = 60
                            // if (col.width=='*' && this.attr.attrs.length>3) {
                            //     col.width = null
                            // }
                        }
                        col.dataMapEditor = 'AutoComplete'
                        this.store.get_itemsSource(this, col, attr)
                    } else if (attr.component == 'AttrStr') {
                        if (!col.minWidth && (attr.title||'').toLowerCase().includes('title')) {
                            col.minWidth = 60
                            // if (col.width=='*' && this.attr.attrs.length>3) {
                            //     col.width = null
                            // }
                        }
                        col.dataType = wjcCore.DataType.String
                    } else if (attr.component == 'AttrBool') {
                        col.dataType = wjcCore.DataType.Boolean
                    } else if (attr.component == 'AttrDate') {
                        col.dataType = wjcCore.DataType.String // Date
                    } else if (attr.component == 'AttrNumber') {
                        col.dataType = wjcCore.DataType.Number
                        if (attr.format) {
                            col.format = attr.format
                        } else {
                            col.format = 'n0'
                        }
                        col.step = attr.step
                    } else {
                        col.dataType = wjcCore.DataType.String
                    }
                    this.flex.columns.push(col)
                    this.attr_by_binding[col.binding] = attr
                }
                // this.flex.itemsSource = null
                this.flex.itemsSource = this.data
                this.initialized_data_overrides()

                // columnGroups
                // this.flex.columns.clear()
                // this.flex.columnGroups = [
                //     { binding: 'col_group.title', header: 'Name', width: 150 },
                //     { binding: 'icol_group', header: 'icol_group', width: 21 },
                //     { header: 'Measure 1', align: 'center', collapseTo: 'res3', columns: [
                //         { header: 'data 1', align: 'center', collapseTo: 'res3', columns: [
                //             { binding: 'res1', header: 'quantity' },
                //             { binding: 'res2', header: 'sum' },
                //         ] },
                //         { header: 'data 2', align: 'center', collapseTo: 'res3', columns: [
                //             { binding: 'res3', header: 'quantity' },
                //             { binding: 'res4', header: 'sum' },
                //         ] },
                //     ] },
                //     { header: 'Measure 2', align: 'center', collapseTo: ['res5','res6'], columns: [
                //         { header: 'data 1', align: 'center', collapseTo: 'res6', columns: [
                //             { binding: 'res5', header: 'quantity' },
                //             { binding: 'res6', header: 'sum' },
                //         ] },
                //         { header: 'data 2', align: 'center', collapseTo: 'res3', columns: [
                //             { binding: 'res7', header: 'quantity' },
                //             { binding: 'res8', header: 'sum' },
                //         ] },
                //     ] },
                // ]

                // handle button clicks
                // this.flex.addEventListener(this.flex.hostElement, 'click', this.click)
                
                // if (this.WIDGET.widget_class=='widget_SOP_document') {
                    this.set_headerColumns()
                    this.formatSheet()
                    this.collapsedAll()
                // }
                // if (this.flex.sortManager.checkSortItemExists(0)==-1) {  
                //     this.flex.sortManager.addSortLevel(0, true)  !!! problems with ediding: Uncaught TypeError: Cannot set properties of undefined (setting '_view')
                // }
                // this.flex.sortManager.commitSort()
                // this.set_headerColumns()

                // selectedRows
                if (this.attr.selectedRanges_dontChange) {
                    this.flex.selectedRanges = selectedRanges
                } else if (this.attr.selectedRanges_byDefault) {
                    let range = this.attr.selectedRanges_byDefault[0],
                        r1 = this.headerRows,
                        c1 = 0
                    if (range.measure) {
                        for (let row of this.flex.rows) {
                            if (row.dataItem && row.dataItem.measure==range.measure) {
                                r1 = row.index
                                break
                            }
                        }
                    }
                    if (range.binding) {
                        for (let col of this.flex.columns) {
                            if (col.binding && col.binding==range.binding) {
                                c1 = col.index
                                break
                            }
                        }
                    }
                    this.flex.selectedRanges = [new wjGrid.CellRange(r1, c1, r1, c1)]
                } else {
                    this.flex.selectedRanges = [new wjGrid.CellRange(this.headerRows,0,this.headerRows,this.column_left)]
                    if (this.flex.selectionMode==5) { // ListBox
                        if (this.attr.selectRowID) {
                            console.log('selectedRows')
                            for (let row of this.flex.rows) {
                                if (row.dataItem && row.dataItem.id==this.attr.selectRowID) {
                                    // this.flex.selectedRows = [row]
                                    this.flex.selectedRanges = [new wjGrid.CellRange(row.index,0,row.index,this.column_left)]
                                    break
                                }
                            }
                        } else if (this.WIDGET.doc.params.current_item) {
                            console.log('selectedRows')
                            for (let row of this.flex.rows) {
                                if (row.dataItem && row.dataItem.id==this.WIDGET.doc.params.current_item) {
                                    // this.flex.selectedRows = [row]
                                    this.flex.selectedRanges = [new wjGrid.CellRange(row.index,0,row.index,this.column_left)]
                                    break
                                }
                            }
                        }
                    }
                }
                // this.flex.itemsSource = null
                // this.flex.itemsSource = this.data
                this.flex.refresh()
                this.set_commandPanel()
            }
        },
        set_headerColumns() {
            for (let col of this.flex.columns) {
                let attr = this.attr_by_binding[col.binding]
                this.flex.setCellData(0, col.index, attr.title)
            }
            this.flex.refresh()
        },
        formatSheet(){
            console.log('formatSheet')
            // let row = new wjGrid.Row()
            // this.flex.columnHeaders.rows.push(row)

            this.headerWijmoRows = 1
            if (this.attr.headerRows) {
                this.headerRows = this.attr.headerRows // 3
            }
            if (this.attr.collapseRowsCount) {
                this.headerRows++
            }
            if (this.attr.headerColumns) {
                this.headerColumns = this.attr.headerColumns // 1
            } else {
                this.headerColumns = 0
            }
            this.row_bottom = this.data.length + this.headerWijmoRows
            this.column_left = this.flex.columns.length-1
            
            // this.flex.columns[0].width = 3
            if (this.attr.frozenColumns) {
                this.flex.frozenColumns = this.attr.frozenColumns
            } else {
                this.flex.frozenColumns = this.headerColumns
            }
            if (this.attr.collapseColumnsCount) {
                this.flex.frozenColumns++
            }
            this.flex.frozenRows = this.headerRows

            this.formatRows()
            this.formatColumns()
            this.mergeHeaderTop()
            // this.mergeHeaderLeft_leftToRight()
            this.mergeHeaderFirstCell()
            // this.mergeHeaderLeft_topToBottom()

            this.flex.refresh()
            this.attrResize()
        },
        formatRows() {
            // this.flex.applyCellsStyle({
            //     backgroundColor:"#4488CC",
            //     borderBottomWidth:5,
            //     className: "sheet-group2-row",
            // }, new wjGrid.CellRange(1, 0, 10, 15))

            // rowlevelGroup_ranges
            let rowLevelGroup_ranges = {}
            this.data.forEach((row, i) => {
                if (!rowLevelGroup_ranges[row.levelGroup]) rowLevelGroup_ranges[row.levelGroup] = []
                rowLevelGroup_ranges[row.levelGroup].push(new wjGrid.CellRange(i+1, 0, i+1, this.column_left))
            })
            
            // format rows
            let levelGroups_ICellStyle = {
                // 0: {
                //     // backgroundColor: "#4488CC",
                //     className: "sheet-detail-row",
                //  }, 
                1: {
                    className: "sheet-group1-row",
                 }, 
                2: {
                     className: "sheet-group2-row",
                 }, 
                99: { //total
                     className: "sheet-total-row",
                }, 
                100: { //header
                    className: "wj-header-row",
                },
            }
            this.rowLevelGroup_ranges_length = rowLevelGroup_ranges.length
            // if (rowLevelGroup_ranges.length > 1) 
                this.flex.alternatingRowStep = 0
            for (let levelGroup in levelGroups_ICellStyle) {
                if (rowLevelGroup_ranges[levelGroup]) {
                    this.flex.applyCellsStyle(levelGroups_ICellStyle[levelGroup], rowLevelGroup_ranges[levelGroup])
                }
            }

        },
        formatColumns() {
            // colValue_ranges
            let colValue_ranges = {}
            this.attr.attrs.forEach((attr, i) => {
                if (!colValue_ranges[attr.value]) colValue_ranges[attr.value] = []
                colValue_ranges[attr.value].push(new wjGrid.CellRange(0, i, this.row_bottom, i))
            })
            
            // format columns
            let value_ICellStyle = {
                'sum': {
                    // className: "cell-sum",
                    color: 'green',
                },
            }
            for (let value in value_ICellStyle) {
                if (colValue_ranges[value]) {
                    this.flex.applyCellsStyle(value_ICellStyle[value], colValue_ranges[value])
                }
            }
        },
        mergeHeaderTop() {
            let merge_ranges = []
            for (let irow = 0; irow < this.headerRows; irow++) {
                let icol_mergeLeft = this.headerColumns,
                    icol_mergeRight = this.headerColumns
                for (let icol = icol_mergeLeft + 1; icol <= this.column_left; icol++) {
                    // getCellValue
                    if (this.flex.getCellValue(irow, icol_mergeLeft) == this.flex.getCellValue(irow, icol)) {
                        icol_mergeRight = icol
                    } else {
                        if (icol_mergeLeft < icol_mergeRight) {
                            merge_ranges.push(new wjGrid.CellRange(irow, icol_mergeLeft, irow, icol_mergeRight))
                        }
                        icol_mergeLeft = icol_mergeRight = icol
                    }
                }
                if (icol_mergeLeft < icol_mergeRight) {
                    merge_ranges.push(new wjGrid.CellRange(irow, icol_mergeLeft, irow, icol_mergeRight))
                }
            }
            merge_ranges.forEach(range => {
                this.flex.mergeRange(range)
                this.flex.applyCellsStyle({ className: "wj-header-row" }, range)
            })
        },
        mergeHeaderFirstCell() {
            if (this.headerRows > 1) {
                let range = new wjGrid.CellRange(0, 0, this.headerRows-1, this.headerColumns-1)
                this.flex.mergeRange(range)
                this.flex.applyCellsStyle({ className: "wj-header-row" }, range)
            }
        },
        mergeHeaderLeft_leftToRight() {
            let merge_ranges = []
            for (let irow = this.headerRows; irow <= this.row_bottom; irow++) {
                let icol_mergeLeft = 0,
                    icol_mergeRight = 0
                for (let icol = 1; icol < this.headerColumns; icol++) {
                    // getCellValue
                    if (!this.flex.getCellValue(irow, icol)) {
                        icol_mergeRight = icol
                    } else {
                        if (icol_mergeLeft < icol_mergeRight) {
                            merge_ranges.push(new wjGrid.CellRange(irow, icol_mergeLeft, irow, icol_mergeRight))
                        }
                        icol_mergeLeft = icol_mergeRight = icol
                    }
                }
                if (icol_mergeLeft < icol_mergeRight) {
                    merge_ranges.push(new wjGrid.CellRange(irow, icol_mergeLeft, irow, icol_mergeRight))
                }
            }
            merge_ranges.forEach(range => {
                this.flex.mergeRange(range)
            })
        },
        mergeHeaderLeft_topToBottom() {
            let merge_ranges = []
            for (let icol = 0; icol < this.headerColumns; icol++) {
                let irow_mergeTop = 0,
                    irow_mergeBottom = 0
                for (let irow = this.headerRows; irow <= this.row_bottom; irow++) {
                    // getCellValue
                    if (this.flex.getCellValue(irow_mergeTop, icol) == this.flex.getCellValue(irow, icol)) {
                        irow_mergeBottom = irow
                        this.flex.setCellValue(irow, icol)
                    } else {
                        if (irow_mergeTop < irow_mergeBottom) {
                            merge_ranges.push(new wjGrid.CellRange(irow_mergeTop, icol, irow_mergeBottom, icol))
                        }
                        irow_mergeTop = irow_mergeBottom = irow
                    }
                }
                if (irow_mergeTop < irow_mergeBottom) {
                    merge_ranges.push(new wjGrid.CellRange(irow_mergeTop, icol, irow_mergeBottom, icol))
                }
            }
            merge_ranges.forEach(range => {
                this.flex.mergeRange(range)
            })
        },
        groupAll() {
            this.collapsedAll(true)
        },
        ungroupAll() {
            this.collapsedAll(false)
        },
        collapsedAll(forsed=null, justRefresh=false) {
            this.collapsedAllRows(forsed, justRefresh)
            this.collapsedAllColumns(forsed, justRefresh)
        },
        collapsedAllRows(forsed, justRefresh=false) {
            for (let i=0; i<this.data.length; i++) {
                let item = this.data[i]
                if ('isCollapsed' in item) { // && !item.isCollapsed
                    this.collapsed_rowGroup(i, forsed ?? item.isCollapsed, justRefresh);
                }
            }
        },
        collapsedAllColumns(forsed, justRefresh=false) {
            for (let attr of this.attr.attrs) {
                if ('isCollapsed' in attr) { // && !attr.isCollapsed
                    this.collapsed_columnGroup(attr.binding, forsed ?? attr.isCollapsed, justRefresh)
                }
            }
        },
        initialized(flex) {
            this.flex = flex;
            this.content_Changed()
        },
        onButtonOpen__Click(cell) {
            let attr_col = this.attr_by_binding[cell.col_binding],
                item = this.flex.getCellData(cell.row, cell.col)
            if (!item) {
                // this.store.choiceStart(this.WIDGET.vueObj, cell)
            } else if (attr_col.attr_type === 'AttrLinkOdoo') {
                this.store.widget_open({
                    widget: 'widget-DOC-DataHub-odoo',
                    doc: {
                        id: item[0],
                        type: attr_col.relation_segment,
                        relation_binding: attr_col.relation_binding,
                    },

                    parentActionMenu: { 'action_id':'action-front-2024-01-23-2', method: 'AttrLinkOdoo.onButtonOpen__Click' },
                    params_onOpen: {
                        parentSelectedItem: item,
                        attr: attr_col, 
                    },
                }, this.WIDGET.vueObj)
            } else {
                this.store.widget_open({
                    doc:{
                        id: item,
                    },
                    parentActionMenu: { 'action_id':'action-front-10', method: 'onButtonOpen__Click' },
                }, this.WIDGET.vueObj)
            }
        },
        onButtonCollapsed__Click(cell) {
            if (cell.irow) {
                let row_from = this.flex.rows[cell.irow].dataItem
                this.collapsed_rowGroup(cell.irow - this.headerWijmoRows, !row_from.isCollapsed)
            } else {
                let attr_from = this.attr_by_binding[cell.col_binding]
                this.collapsed_columnGroup(cell.col_binding, !attr_from.isCollapsed)
            }
        },
        attrResize() {
            if (!this.isVisible) return
            console.log(`attrResize ${this.attr.html_id}`)
            const elementForm = document.getElementById(`WIDGET${this.widget_id}`);
            // const elementForm = document.getElementById(`containerResize${this.attr.html_id}`)
            const elementFlex = this.$refs.attr_container // document.getElementById(this.attr.html_id);
            if (!elementForm || !elementFlex) {
                return
            }
            const elementWidget_Rect = elementForm.getBoundingClientRect()
            const elementFlex_Rect = elementFlex.getBoundingClientRect()
            if (elementFlex_Rect.height) {
                let newHeight = Math.floor(elementWidget_Rect.bottom - elementFlex_Rect.top) - 2
                console.log(`size: ${this.attr.size}`)
                if (this.attr.size) {
                    newHeight = Math.round(newHeight * this.attr.size / 100)
                }
                if (newHeight < (this.attr.minHeight||160)) {
                    newHeight = (this.attr.minHeight||160)
                }
                if (this.attr.maxHeight && newHeight > this.attr.maxHeight) {
                    newHeight = this.attr.maxHeight
                } else if (newHeight > 1500) {
                    newHeight = 1500
                }
                if (this.attr.showGridSummaryLine) {
                    newHeight -= 20
                }
                if (this.attr.heightAdd) {
                    newHeight += this.attr.heightAdd
                }
                if (elementFlex_Rect.height != newHeight) {
                    console.log(`attrResize ${this.attr.html_id} set:${elementFlex_Rect.height - newHeight}`)
                    elementFlex.style.height = `${newHeight}px`
                }
            }
        },
        collapsed_rowGroup(item_index, isCollapsed=true, justRefresh=false) {
            let item_from = this.data[item_index]
            item_from.isCollapsed = isCollapsed
            let optionally_collapse = []
            if (!('collapseFrom' in item_from)) {
                item_from.collapseFrom = item_index+1
            }

            for (let i=item_from.collapseFrom; i<=item_from.collapseTo; i++) {
                let row = this.flex.rows[i+this.headerWijmoRows]
                let item = this.data[i]
                if (i < item_index || i < item_from.collapseTo) {
                    item.collapseBottom = true
                }
                item.collapseLine = 'line-middle-inside'
                item.collapseIndex = item_index
                if (row.visible != !isCollapsed) {
                    row.visible = !isCollapsed
                }
                if (!justRefresh && !isCollapsed && 'isCollapsed' in item) { //  && row.icol_group+1 == row_from.icol_group
                    if (item.isCollapsed) {
                        i = item.collapseTo
                    } else {
                        optionally_collapse.push(i)
                    }
                }
            }

            if (item_index < item_from.collapseTo) {
                item_from.collapseBottom = true
                item_from.collapseLine = 'line-first-inside'
                this.data[item_from.collapseTo].collapseLine = 'line-last-inside'
            } else {
                this.data[item_from.collapseFrom].collapseLine = 'line-first-inside'
                item_from.collapseLine = 'line-last-inside'
            }
            item_from.collapseIndex = item_index

            for (let i of optionally_collapse) {
                this.collapsed_rowGroup(i)
            }
        },
        collapsed_columnGroup(col_binding, isCollapsed=true, justRefresh=false) {
            let attr_from = this.attr_by_binding[col_binding]
            attr_from.isCollapsed = isCollapsed
            for (let icol=attr_from.collapseFrom+1; icol<=attr_from.collapseTo; icol++) {
                // let attr = this.attr.attrs[icol]
                let col = this.flex.columns[icol]
                if (col.visible != !isCollapsed) {
                    col.visible = !isCollapsed
                }
            }
        },
        onButtonСhoiceStart__Click(cell) {
            let item = this.flex.getCellData(cell.row, cell.col),
                attr_col = this.attr_by_binding[cell.col_binding]
            this.store.open_widgetList_forAttr(item, 
                {...this.attr, attr_col:attr_col, cell:cell}, 
                {command_Choice:true}, 
                this
            )
        },
        choiceFinish(attr, item) {
            if (this.flex.rows[attr.cell.row].dataIndex == -1) {
                // this.flex.itemsSource.push({})
                // this.flex.refresh()
            }
            this.flex.setCellData(attr.cell.row, attr.cell.col, item.id)
            this.cellEditEnding2(attr.cell, item.id)
        },
        onSelector__Click(cell) {
            for (let i in this.data) {
                this.data[i].selector = (i==cell.irow-this.headerWijmoRows)
            }
            this.store.executeStoreMethod(this, { 'command': 'onApplySelector__Click' })
            this.flex.refresh()
        },
        pastingCell(flex, e) {
            this.cellEditEnding2(e, e.data)
            this.saveVisibility()
            this.need_restoreVisibility = true
        },
        pastedCell(flex, e) {
            this.cellEditEnded2(e)
        },
        pasting(flex, e) {
            console.log('pasting')
            this.WIDGET.loading = true
            this.activityInfo = 0
        },
        pasted(flex, e) {
            console.log('pasted')
            this.WIDGET.loading = false
            this.WIDGET.activityInfo = ''
        },
        updatingView(flex, e) {
            if (this.need_aggregate) {
                this.aggregate()
                this.actionStack++
                this.need_aggregate = false
            }
            if (this.need_restoreVisibility && this.rowLevelGroup_ranges_length) {
                this.restoreVisibility()
                this.need_restoreVisibility = false
            } else if (this.need_collapsedAll) {
                console.log('updatingView+need_collapsedAll')
                this.need_collapsedAll = false
                this.collapsedAll(false, true)
            }
        },
        saveVisibility() {
            this.columnsVisibility = []
            this.rowsVisibility = []
            
            for (let col of this.flex.columns) {
                this.columnsVisibility.push(col.visible)
            }
            for (let row of this.flex.rows) {
                this.rowsVisibility.push(row.visible)
            }
        },
        restoreVisibility() {
            for (let icol in this.columnsVisibility) {
                if (this.flex.columns[icol].visible != this.columnsVisibility[icol]) {
                    this.flex.columns[icol].visible = this.columnsVisibility[icol]
                }
            }
            for (let irow in this.rowsVisibility) {
                if (this.flex.rows[irow].visible != this.rowsVisibility[irow]) {
                    this.flex.rows[irow].visible = this.rowsVisibility[irow]
                }
            }

            this.columnsVisibility = []
            this.rowsVisibility = []
        },
        updatingLayout(flex, e) {
            // console.log('updatingLayout')
        },
        updatedLayout(flex, e) {
            // console.log('updatedLayout')
        },
        updatedView(flex, e) {
            // console.log('updatedView')
        },
        beginningEdit(flex, e) {
            this.cell_oldVal = this.flex.getCellData(e.row, e.col)
            wjcCore.removeClass(e.cell, 'cell-zero')
        },
        cellEditEnding(flex, e) {
            let newVal = ''
            if (this.flex.activeEditor) {
                newVal = this.flex.activeEditor.value
            }
            this.cellEditEnding2(e, newVal)
        },
        cellEditEnded(flex, e) {
            // let newVal = ''
            // if (this.flex.activeEditor) {
            //     newVal = this.flex.activeEditor.value
            // }
            this.cellEditEnded2(e)
        },
        cellEditEnded2(e) {
            let newVal = this.flex.getCellData(e.row, e.col),
                col = this.flex.columns[e.col],
                attr_col = this.attr_by_binding[col.binding],
                irow = e.row - this.headerWijmoRows,
                item = this.data[irow],
                col_binding = attr_col.binding

            if (attr_col.attr_type === 'AttrBool') {
                newVal = Boolean(newVal)
            } else if (attr_col.attr_type === 'AttrLink') {
                // return
            } else {
                return
            }
            console.log('cellEditEnded2')
            this.WIDGET.activityInfo = ++this.activityInfo
            // await this.$nextTick()

            // check
            if (this.AttrLink_oldVal == newVal) {
                return
            } else if (irow < this.headerRows - this.headerWijmoRows || e.col < this.headerColumns) {
                return
            }

            // calculate subtotal, undoStack...
            if (!item.levelGroup) {
                this.set_newVal(item, col_binding, this.AttrLink_oldVal, newVal)
            }
            this.actionStack++
        },
        cellEditEnding2(e, newVal) {
            let oldVal = this.flex.getCellData(e.row, e.col),
                col = this.flex.columns[e.col],
                attr_col = this.attr_by_binding[col.binding],
                irow = e.row - this.headerWijmoRows,
                item = this.data[irow],
                col_binding = attr_col.binding;

            // if (!item) { // choiceFinish in new line
            //     this.data.push({})
            //     this.flex.refresh()
            //     item = this.data[irow]
            // }

            // oldVal if dell cell
            if (oldVal==null) {
                oldVal = this.cell_oldVal
            }

            // selector
            if (attr_col.action_afterChange) {
                this.store.executeStoreMethod(this.WIDGET.vueObj, attr_col.action_afterChange)
            }

            // convert to type
            if (attr_col.attr_type === 'AttrNumber') {
                if (typeof newVal === 'string') {
                    newVal = +newVal.replace(/,/g, '') // Math.max(0,+newVal)
                }
            } else if (attr_col.attr_type === 'AttrStr') {
                newVal = '' + newVal
            } else if (attr_col.attr_type === 'AttrBool') {
                this.AttrLink_oldVal = oldVal
                return
            } else if (attr_col.attr_type === 'AttrDate') {
                let date = this.store.parseDateString(newVal)
                if (date) {
                    newVal = wjcCore.Globalize.formatDate(date, 'yyyy-MM-dd') // input.value.toISOString()
                } else {
                    newVal = null
                }
            } else if (attr_col.attr_type === 'AttrTime') {
                // newVal = new Date('1970-01-01T' + newVal + 'Z').toISOString().substr(11, 8)
            } else if (attr_col.attr_type === 'AttrLink') {
                this.AttrLink_oldVal = oldVal
                return
            }

            e.cancel = true

            // check
            if (oldVal == newVal && newVal != 0) {
                return
            } else if (irow < this.headerRows - this.headerWijmoRows || e.col < this.headerColumns) {
                return
            } else if (attr_col.attr_type === 'AttrNumber') {
                if (oldVal == newVal && newVal != 0) {
                    if (item[col_binding]==null) {
                        // item[col_binding] = 0 2023 05 23 bed for data_strip
                    }
                    return
                } else if (isNaN(newVal)) {
                    return
                }
            }

            // calculate subtotal, undoStack...
            if (!item.levelGroup) {
                let set_newVal_answer = this.set_newVal(item, col_binding, oldVal, newVal)
                if (set_newVal_answer.recursive) {
                    return
                }
            }

            // Sales||Forecast overrides
            if (item.measure=='MEASURE_salesAdjustments') {
                // console.log('frontend calc')
                let measure = 'MEASURE_baseLine',
                    measureDoc = this.get_measure(measure).measureDoc,
                    item_summary = this.get_row(measure),
                    item_price = this.get_row('MEASURE_price'),
                    item_summary_inMoney = this.get_row('MEASURE_sumSales')
                if (item_summary) {
                    if (newVal) {
                        this.set_newVal(item_summary, col_binding, item_summary[col_binding], newVal)
                    } else if (measureDoc && measureDoc.params && measureDoc.params.eval) {
                        let eval_ = measureDoc.params.eval
                        for (let evalParam of measureDoc.params.tabel_evalfields) {
                            let item_2 = this.get_row(evalParam.measure)
                            if (item_2) {
                                eval_ = eval_.replace(evalParam.binding, item_2[col_binding])
                            }
                        }
                        try {
                            let result = math.evaluate(eval_)
                            this.set_newVal(item_summary, col_binding, item_summary[col_binding], result)
                        } catch (error) {
                            console.error('Error :', error.message);
                        }
                    }
                    if (item_price && item_summary_inMoney) {
                        this.set_newVal(item_summary_inMoney, col_binding, item_summary_inMoney[col_binding], Math.round(item_summary[col_binding] * item_price[col_binding]))
                    }
                    this.flex.refreshCells()
                }
            } else if (item.measure=='MEASURE_forecastAdjustments') {
                this.set_MEASURE_forecast(col_binding, newVal)
                this.flex.refreshCells()
            } else {
                if (this.WIDGET.doc.measures_effect && this.WIDGET.doc.measures_effect[item.measure]) {
                    let measure_AI = 'MEASURE_AI',
                        item_AI = this.get_row(measure_AI),
                        effect = this.WIDGET.doc.measures_effect[item.measure]
                    if (item_AI && effect.multiplicative) {
                        let oldVal_AI = item_AI[col_binding],
                            oldEffect = Math.round(oldVal*effect.multiplicative),
                            newEffect = Math.round(newVal*effect.multiplicative)

                        if  (effect.measure_timeSeries) {
                            let item_TS = this.get_row(effect.measure_timeSeries)
                            oldEffect = item_TS[col_binding]
                            this.set_newVal(item_TS, col_binding, item_TS[col_binding], newEffect)
                        }
                        let newVal_AI = oldVal_AI - oldEffect + newEffect

                        this.set_newVal(item_AI, col_binding, item_AI[col_binding], newVal_AI)
                        this.set_MEASURE_forecast(col_binding)
                        
                        // inMoney
                        let item_price = this.get_row('MEASURE_price'),
                            measure_inMoney = `${effect.measure_timeSeries}$`
                        if (item_price) {
                            this.set_newVal(null, col_binding, 0, Math.round(newEffect * item_price[col_binding]), true, false, measure_inMoney)
                        }
                        
                        this.flex.refreshCells()
                    }
                }
            }
            
            if (attr_col.attr_type === 'AttrNumber') {
                // decomposition
                if (item.levelGroup > 0) {
                    // base
                    let items_base = [],
                        rowInGroup_index = irow+1,
                        base = 0,
                        base_binding = col_binding
                    while(rowInGroup_index < this.data.length && item.levelGroup != this.data[rowInGroup_index].levelGroup) {
                        let next_item = this.data[rowInGroup_index]
                        if (next_item.levelGroup == 0) {
                            items_base.push(next_item)
                            base += +next_item[base_binding]
                        }
                        rowInGroup_index++
                    }

                    //calc base on previous period
                    if (!base) {
                        base = 0 //!!!
                    }

                    // decomposition
                    if (base) {
                        let restVal = newVal
                        for (let i=0; i<items_base.length; i++) {
                            let canTake = Math.min( restVal, (newVal*items_base[i][base_binding]/base).toFixed(0) )
                            restVal -= canTake
                            if (items_base[i][col_binding] != canTake) {
                                let oldVal2 = items_base[i][col_binding]
                                this.set_newVal(items_base[i], col_binding, oldVal2, canTake)
                        }}
                        if (restVal) {
                            // save in max
                            let imax = 0
                            for (let i=1; i<items_base.length; i++) {
                                if (items_base[i][base_binding] > items_base[imax][base_binding]) {
                                    imax = i
                            }}
                            let oldVal2 = items_base[imax][col_binding]
                            this.set_newVal(items_base[imax], col_binding, oldVal2, items_base[imax][col_binding] + restVal)
                    }} else {
                        // save in first
                        let oldVal2 = items_base[0][col_binding]
                        this.set_newVal(items_base[0], col_binding, oldVal2, newVal)
                    }
                }

                if (this.rowLevelGroup_ranges_length) {
                    this.need_aggregate = true
                } else {
                    this.actionStack++
                }
            } else {
                this.actionStack++
            }
        },
        set_MEASURE_forecast(col_binding, newVal_forecastAdjustments=null) {
            // console.log('frontend calc')
            let measure = 'MEASURE_forecast',
                measureDoc = this.get_measure(measure).measureDoc,
                item_summary = this.get_row(measure),
                item_price = this.get_row('MEASURE_price'),
                item_summary_inMoney = this.get_row('MEASURE_sumForecast')
            if (item_summary) {
                // MEASURE_forecastAdjustments
                let measureAdjustments = 'MEASURE_forecastAdjustments',
                    attr_col = this.attr_by_binding[col_binding] || {}
                if (newVal_forecastAdjustments==null) {
                    let item = this.get_row(measureAdjustments)
                    if (item[col_binding]) {
                        newVal_forecastAdjustments = item[col_binding]
                    }
                }

                // OVERRIDES MEASURE_forecastAdjustments
                if (newVal_forecastAdjustments==null) {
                    let data_overrides = this.WIDGET.doc.data_overrides
                    for (let i=data_overrides.length-1; i>=0; i--) {
                        if (data_overrides[i].measure == measureAdjustments && data_overrides[i].date == attr_col.date) {
                            newVal_forecastAdjustments = data_overrides[i].val
                            break
                        }
                    }
                }
                
                // score_measure
                if (newVal_forecastAdjustments==null) {
                    if (this.WIDGET.doc.score_measure) {
                        let item_2 = this.get_row(this.WIDGET.doc.score_measure)
                        if (item_2) {
                            newVal_forecastAdjustments = item_2[col_binding]
                        }
                    } else {
                        newVal_forecastAdjustments = 0
                    }
                }

                this.set_newVal(item_summary, col_binding, item_summary[col_binding], newVal_forecastAdjustments)

                if (item_price && item_summary_inMoney) {
                    this.set_newVal(item_summary_inMoney, col_binding, item_summary_inMoney[col_binding], Math.round(item_summary[col_binding] * item_price[col_binding]))
                }
            }
        },
        get_row(measure) {
            for (let row of this.data) {
                if (row.measure==measure)
                    return row
            }
            return null
        },
        get_measure(measure) {
            for (let m of this.WIDGET.doc.measures) {
                if (m.measure==measure) {
                    return m
                }
            }
            return null
        },
        set_newVal(item, col_binding, oldVal, newVal, changeStack=true, isUndo=false, measure='') {
            // let oldVal = item[col_binding],
            let attr_col = this.attr_by_binding[col_binding] || {}
            if (item === null) {
                item = this.get_row(measure)
                if (item !== null) {
                    oldVal = item[col_binding]
                }
            }

            // transpose
            if (this.attr.transpose && this.attr.binding_transpose && 'irow_tranpose' in attr_col) {
                let irow_tranpose = attr_col.irow_tranpose
                if (item !== null) {
                    this.store.doc_set(this.WIDGET.doc, this.attr.binding_transpose, newVal, irow_tranpose, item.measure)
                } else if (measure !== ''){
                    this.store.doc_set(this.WIDGET.doc, this.attr.binding_transpose, newVal, irow_tranpose, measure)
                }
            }

            if (item === null) {
                return {}
            }
            if (oldVal==newVal && newVal != 0) {
                return {}
            }

            // undoStack
            if (changeStack && col_binding!='visibleChart' && col_binding!='selector') {
                let i = this.data.indexOf(item)
                let rowStack = {
                    actionStack: this.actionStack,
                    i: i, 
                    col_binding: col_binding, 
                    val: oldVal,
                    newVal: newVal,
                }
                this.undoStack.push(rowStack)
                this.changesStack[`${rowStack.i}:${rowStack.col_binding}`] = rowStack.val
                this.redoStack.length = 0
                if (!this.flex_isEditing) {
                    this.flex_isEditing = true
                    this.set_commandPanel()
                }
            }

            // data_overrides
            let set_overrides = !['MEASURE_baseLine', 'MEASURE_sumSales', 'MEASURE_forecast', 'MEASURE_sumForecast'].includes(item.measure)
            if (set_overrides && this.attr.binding != 'data_overrides' && 'data_overrides' in this.WIDGET.doc && attr_col.date && item.measure) {
                let data_overrides = this.WIDGET.doc.data_overrides

                if (!isUndo) {
                    let new_override = {
                        measure: item.measure,
                        date: attr_col.date,
                        val: newVal,
                        tooltip: '',
                        oldVal: oldVal,
                        changes_info_user: this.store.user.id,
                        changes_info_date: new Date().toISOString(),
                    }
                    if (data_overrides.length) {
                        let last_override = data_overrides[data_overrides.length-1]
                        if (last_override.measure==new_override.measure
                            && last_override.date==new_override.date
                            && last_override.val==new_override.val
                        ) {
                            console.log('!!! recursive overrides')
                            return {recursive:true}
                        }
                    }
                    data_overrides.push(new_override)
                    
                    // test
                    // let attr_vueObj_data_overrides = this.WIDGET.vueObj.active_attr_vueObj('data_overrides')
                    // attr_vueObj_data_overrides.flex.rows.push(new_override)
                } else {
                    for (let i=data_overrides.length-1; i>=0; i--) {
                        if (data_overrides[i].measure == item.measure && data_overrides[i].date == attr_col.date) {
                            data_overrides.splice(i, 1)
                            break
                        }
                    }
                }

                // refresh data_overrides
                let attr_vueObj_data_overrides = this.WIDGET.vueObj.active_attr_vueObj('data_overrides')
                if (attr_vueObj_data_overrides) {
                    // !!! recursive overrides
                    // attr_vueObj_data_overrides.flex.itemsSource = null
                    // attr_vueObj_data_overrides.flex.itemsSource = data_overrides
                    // attr_vueObj_data_overrides.flex.onItemsSourceChanging()
                    attr_vueObj_data_overrides.flex.collectionView.refresh() // || attr_vueObj_data_overrides.flex.itemsSource.refresh()
                }
            }

            // set new value
            item[col_binding] = newVal

            if (col_binding=='visibleChart') {
                this.set_visibleChart(newVal, item.measure)
                return{}
            }

            if (this.attr.refreshAttr_AfterChange) {
                let attr_vueObj = this.WIDGET.vueObj.active_attr_vueObj(this.attr.refreshAttr_AfterChange)
                attr_vueObj.refresh()
                console.log('refreshAll')
            }

            if (attr_col.attr_type === 'AttrNumber') {
                let _quantity = attr_col.binding_quantity,
                    _sum = attr_col.binding_sum,
                    _price = attr_col.binding_price,
                    _calculation = attr_col.type_of_quantity_calculation

                // calculate
                if (_sum && _quantity && _price) {
                    if (_calculation=='sum=quantity*price') {
                        if (col_binding==_quantity || col_binding==_price) {
                            // sum=quantity*price
                            item[_sum] = (item[_quantity] * item[_price]).toFixed(0)
                        } else if (col_binding==_sum) {
                            if (item[_price]) {
                                // quantity=sum/price
                                item[_quantity] = (item[_sum] / item[_price]).toFixed(0)
                            } else if (item[_quantity]) {
                                // price=sum/quantity
                                item[_price] = (item[_sum] / item[_quantity]).toFixed(3)
                            }
                        }
                    }
                } else if (_sum && _quantity) {
                    if (_calculation=='sum=quantity*price') {
                        if (col_binding==_quantity) {
                            let old_quantity = oldVal
                            if (item[_sum] && old_quantity) {
                                let price = item[_sum] / old_quantity
                                // sum=quantity*price
                                item[_sum] = (item[_quantity] * price).toFixed(0)
                            }
                        } else if (col_binding==_sum) {
                            let old_sum = oldVal
                            if (old_sum && item[_quantity]) {
                                let price = old_sum / item[_quantity]
                                if (price) {
                                    // quantity=sum/price
                                    item[_quantity] = (item[_sum] / price).toFixed(0)
                                }
                            }
                        }
                    }
                }
            }

            if (this.WIDGET.widget_class == 'widget_PARAMS') {
                this.store.rightArea_editEnded(this.WIDGET, this.attr)
            }
            return{}
        },
        set_visibleChart(visibleChart, measure) {
            let attrChart = this.store.attr_find_by_keys(this.WIDGET.attrs, 'dataChart')
            if (attrChart) {
                let flexChart = this.WIDGET.attrs_vueObj[attrChart.id].flex
                for (let series of flexChart.series) {
                    if (series.binding==measure) {
                        series.visibility = visibleChart ? 0 : 2 // hide
                        break
                    }
                }
                this.get_measure(measure).visibleChart = visibleChart
            }
        },
        aggregate() {
            // + formulas
            console.time("aggregate"); let countFormulas = 0
            for (let attr of this.attr.attrs) {
                if (attr.aggregate) {
                    let col_binding = attr.binding
                    for (let i=0; i<this.data.length; i++) {
                        let item = this.data[i]
                        if (item.levelGroup>0 && item.levelGroup<99) {
                            // sum()
                            // this.flex.setCellData(i, 2, "=sum(B2:D4)")
                            let i_next = i + 1,
                                sum = 0
                            while(i_next < this.data.length && item.levelGroup != this.data[i_next].levelGroup) {
                                if (this.data[i_next].levelGroup == 0) {
                                    sum += +this.data[i_next][col_binding]
                                }
                                i_next++
                                countFormulas++
                            }
                            item[col_binding] = sum
                        }
                    }
                }
            }
            console.timeEnd("aggregate"); console.log(`countFormulas: ${countFormulas}`)
        },
        undo_Click() {
            this.changeStack(this.undoStack, this.redoStack)
        },
        redo_Click() {
            this.changeStack(this.redoStack, this.undoStack, 'newVal', false)
        },
        changeStack(fromStack, toStack, nameVal='val', isUndo=true) {
            if (fromStack.length) {
                let actionStack = fromStack[fromStack.length-1].actionStack
                while (fromStack.length && actionStack == fromStack[fromStack.length-1].actionStack) {
                    let a = fromStack.pop(),
                        item = this.data[a.i],
                        oldVal = item[a.col_binding]
                    this.set_newVal(item, a.col_binding, oldVal, a[nameVal], false, isUndo)
                    toStack.push(a)
                }
                if (this.rowLevelGroup_ranges_length) {
                    this.aggregate()
                }
                this.initialized_stackСhanges()
                this.flex.refresh()
            }
        },
        click(e) {
            if (e.target.id == "onButtonCollapsed__Click") {
                let cell = wjcCore.closest(e.target, '.data-cell').data_cell;
                this.onButtonCollapsed__Click(cell)
            } else if (e.target.id == "onButtonCollapsed2__Click") {
                let cell = wjcCore.closest(e.target, '.data-cell').data_cell;
                // e.preventDefault() not work
                this.onButtonCollapsed__Click(cell)
                // this.flex.rowHeaders.refresh()
            } else if (e.target.id == "onButtonOpen__Click") {
                let cell = wjcCore.closest(e.target, '.data-cell').data_cell;
                this.onButtonOpen__Click(cell)
            } else if (e.target.id == "onButtonShowDroppedDown__Click") {
                let cell = wjcCore.closest(e.target, '.data-cell').data_cell;
                this.onButtonShowDroppedDown__Click(cell)
            } else if (e.target.id == "onButtonСhoiceStart__Click") {
                let cell = wjcCore.closest(e.target, '.data-cell').data_cell;
                this.onButtonСhoiceStart__Click(cell)
            } else if (e.target.id == "onSelector__Click") {
                let cell = wjcCore.closest(e.target, '.data-cell').data_cell;
                this.onSelector__Click(cell)
            } else if (e.target.id == "onHeader__Click") {
                if (this.attr.groupMenu?.topLeftMenu?.length) {
                    this.store.executeStoreMethod(this, this.attr.groupMenu.topLeftMenu[0])
                } else {
                    this.dblclick()
                }
            }
        },
        dblclick(m) {
            let vueObj = this.WIDGET.vueObj,
                col = this.flex.columns[this.flex.selection.col],
                attr_col = this.attr_by_binding[col.binding]
            if (this.attr.isReadOnly || attr_col.isReadOnly) {
                this.store.dblclick(vueObj, this.attr)
            }
        },
        onClick(actionMenu) {
            let vueObj = this.WIDGET.vueObj

            // Let's leave only the modified lines
            if (actionMenu.method == 'save') {
                const [doc, binding] = this.store.attr_get_link(this.WIDGET, this.attr)
                const indexes = new Set(this.undoStack.map(row => row.i))
                doc[binding] = [...doc[binding].filter((row, index) => indexes.has(index)).map(row => row)]
            }

            vueObj.store.executeStoreMethod(vueObj, actionMenu)
        },
        flex_editing() {
            this.flex.isReadOnly = this.attr.isReadOnly = false
            this.flex.showMarquee = !this.flex.isReadOnly
            this.flex_isEditing = true
            this.flex.selectionMode = 2 // CellRange
            this.set_commandPanel()

            // show Id, type
            for (let col of this.flex.columns) {
                let attr = this.attr_by_binding[col.binding]
                if (attr.binding == 'id' || attr.binding == 'type') {
                    col.visible = true
                    attr.visible = true
                }
            }

            // for (attr of this.attr.attrs) {
            //     attr.isReadOnly = false
            // }
        },
        new_Click() {
            let irow = 1
            if (this.flex.selectedRows.length && this.flex.selectedRows[0].index >= this.headerRows) {
                irow = this.flex.selectedRows[0].index
            }
            this.flex.insertRows(irow+1)
            this.shift_undoStack(irow)
            this.flex.selectedRanges = [new wjGrid.CellRange(irow+1,0,irow+1,this.column_left)]
        },
        shift_undoStack(irow, add=1) {
            for (let i = 0; i < this.undoStack.length; i++) {
                let rowStack = this.undoStack[i];
                if (rowStack.i == irow && add==-1) {
                    this.undoStack.splice(i, 1);
                    i--;
                } else if (rowStack.i >= irow) {
                    rowStack.i += add;
                }
            }
            this.initialized_stackСhanges()
        },
        initialized_stackСhanges() {
            // changesStack
            tooltip.dispose()
            this.changesStack = {}
            for (let i = 0; i < this.undoStack.length; i++) {
                let rowStack = this.undoStack[i];
                this.changesStack[`${rowStack.i}:${rowStack.col_binding}`] = rowStack.val
            }
        },
        initialized_data_overrides() {
            this.data_overrides = {}
            if (this.WIDGET && this.WIDGET.doc && this.WIDGET.doc.data_overrides) {
                for (let row_overrides of this.WIDGET.doc.data_overrides) {
                    this.data_overrides[`${row_overrides.measure}:${row_overrides.date}`] = row_overrides
                }
            }
        },
        duplicate_Click() {
            let irow = 1
            if (this.flex.selectedRows.length && this.flex.selectedRows[0].index >= this.headerRows) {
                irow = this.flex.selectedRows[0].index
                const dataItem_dublicate = this.flex.selectedRows[0].dataItem
                this.flex.insertRows(irow+1)
                this.shift_undoStack(irow)
                this.flex.selectedRanges = [new wjGrid.CellRange(irow+1,0,irow+1,this.column_left)]
                const dataItem_new = this.flex.selectedRows[0].dataItem
                for (let col_binding in dataItem_dublicate) {
                    if (col_binding=='id'){
                        continue
                    } else if (col_binding=='title'){
                        dataItem_new[col_binding] = this.store.incrementName(dataItem_dublicate[col_binding])
                    } else {
                        dataItem_new[col_binding] = dataItem_dublicate[col_binding]
                    }
                    if (dataItem_new[col_binding] && dataItem_new[col_binding]!='0') {
                        this.set_newVal(dataItem_new, col_binding, '', dataItem_new[col_binding])
                    }
                }
                this.actionStack++
            }
        },
        up_Click() {
            let currentPosition = this.flex.selectedRanges[0].topRow,
                count = this.flex.selectedRanges[0].bottomRow - this.flex.selectedRanges[0].topRow + 1,
                items = this.flex.collectionView.items
            if (currentPosition > 0) {
                let movedItems = items.splice(currentPosition, count)
                items.splice(currentPosition - 1, 0, ...movedItems)

                this.flex.collectionView.refresh()
                this.flex.selectedRanges = [new wjGrid.CellRange(currentPosition-1,0,currentPosition-1+count-1,this.column_left)]
            }
        },
        down_Click() {
            let currentPosition = this.flex.selectedRanges[0].topRow,
                count = this.flex.selectedRanges[0].bottomRow - this.flex.selectedRanges[0].topRow + 1,
                items = this.flex.collectionView.items
            if (currentPosition + count < items.length) {
                let movedItems = items.splice(currentPosition, count)
                items.splice(currentPosition + 1, 0, ...movedItems)

                this.flex.collectionView.refresh()
                this.flex.selectedRanges = [new wjGrid.CellRange(currentPosition+1,0,currentPosition+count,this.column_left)]
            }
        },
        delete_Click() {
            let cellRange = []
            for (let row of this.flex.selectedRows) {
                if (row.index >= this.headerRows) {
                    cellRange.push(new wjGrid.CellRange(row.index))
                    this.shift_undoStack(row.index, -1)
                }
            }
            this.flex.deleteRows(cellRange)
        },
        clean_data_overrides_Click() { // in development
            let cellRange = []
            for (let row of this.flex.selectedRows) {
                if (row.index >= this.headerRows) {
                    let data_overrides = this.WIDGET.doc.data_overrides
                    for (let i=data_overrides.length-1; i>=0; i--) {
                        if (data_overrides[i].measure == measureAdjustments && data_overrides[i].date == attr_col.date) {
                            newVal_forecastAdjustments = data_overrides[i].val
                            break
                        }
                    }
                }
            }
        },
        set_commandPanel() {
            let params = {}
            params['flex_isEditing'] = this.flex_isEditing
            params['!flex_isEditing'] = !this.flex_isEditing
            params['show_actionMenu'] = !this.flex_isEditing

            if (this.WIDGET.data && this.WIDGET.frontend_set_commandPanel) {
                this.WIDGET.attrs_vueObj.WIDGET.set_commandPanel(params)
            }

            this.commandPanel = []
            if (this.WIDGET.widget_class=='widget_SOP_document' || (!this.attr.isReadOnly && this.WIDGET.widget_class!='widget_LIST')) {
                if (this.flex_isEditing) {
                    this.commandPanel = [...this.commandPanel,
                        { 'attr_type':'AttrButton', 'binding':this.attr.binding, 'tooltip': 'Undo', 'command':'executeVueObjMethod', 'method':'undo_Click', 'cssClass':'pi pi-undo' },
                        { 'attr_type':'AttrButton', 'binding':this.attr.binding, 'tooltip': 'Redo', 'command':'executeVueObjMethod', 'method':'redo_Click', 'cssClass':'pi pi-refresh' },
                        { 'attr_type':'AttrButton', 'binding':this.attr.binding, 'tooltip': 'New', 'command':'executeVueObjMethod', 'method':'new_Click', 'cssClass':'pi pi-plus' },
                        { 'attr_type':'AttrButton', 'binding':this.attr.binding, 'tooltip': 'Duplicate', 'command':'executeVueObjMethod', 'method':'duplicate_Click', 'cssClass':'pi pi-copy' },
                        { 'attr_type':'AttrButton', 'binding':this.attr.binding, 'tooltip': 'Clean out selected lines from editable rows', 'command':'executeVueObjMethod', 'method':'delete_Click', 'cssClass':'pi pi-minus' },
                        // { 'attr_type':'AttrButton', 'binding':this.attr.binding, 'tooltip': 'Clean overrides in selected cells', 'command':'executeVueObjMethod', 'method':'clean_data_overrides_Click', 'cssClass':'pi pi-minus' },
                        // { 'attr_type':'AttrButton', 'binding':this.attr.binding, 'tooltip': 'Move up', 'command':'executeVueObjMethod', 'method':'up_Click', 'cssClass':'pi pi-angle-up' },
                        // { 'attr_type':'AttrButton', 'binding':this.attr.binding, 'tooltip': 'Move down', 'command':'executeVueObjMethod', 'method':'down_Click', 'cssClass':'pi pi-angle-down' },
                    ]
                }
                if (this.rowLevelGroup_ranges_length) {
                    this.commandPanel = [...this.commandPanel,
                        { 'attr_type':'AttrButton', 'binding':this.attr.binding, 'tooltip': 'Group all', 'title': 'Group all', 'command':'executeVueObjMethod', 'method':'groupAll', 'cssClass':'pi' },
                        { 'attr_type':'AttrButton', 'binding':this.attr.binding, 'tooltip': 'Ungroup all', 'title': 'Group all', 'command':'executeVueObjMethod', 'method':'ungroupAll', 'cssClass':'pi' },
                    ]
                }
            }
            this.store.attrs_prepare(this.commandPanel, this.attr.html_id)
            // console.log(this.commandPanel)
            if (!this.attr.groupMenu) {
                this.attr.groupMenu = {}
            }
            this.attr.groupMenu.commandPanel = this.commandPanel
        },
        selectionChanged(flex, e) {
            if (this.attr.configType=='measure_calculationModel') {
                if (this.flex.selectedRows.length && this.flex.selectedRows[0].dataItem) {
                    let item = { measure:this.flex.selectedRows[0].dataItem.measure }

                    let WORKSPACE = this.store.activeWORKSPACE
                    let PAGE = WORKSPACE.activePAGE
                    let rightArea = PAGE.rightArea

                    if (rightArea) {
                        if (!this.rightArea.parentWidget || !this.rightArea.parentWidget.item 
                        || (this.rightArea.parentWidget.configType=='measure_calculationModel' && item.measure!=this.rightArea.parentWidget.item.measure)) {
                            let measureRow = this.store.get_measureRow(this.WIDGET, item.measure)
                            if (measureRow && measureRow.own_calculateModel) {
                                item = {
                                    measure: item.measure,
                                    own_calculateModel: measureRow.own_calculateModel,
                                    ...(measureRow.calculateModel_params||{}),
                                }
                            }
                            this.store.rightArea_setDataSource(this, this.attr.binding, this.attr.configType, item)
                        }
                    }
                }
            }
            if (this.attr.setSelectionInAttr_AfterSelectionChanged) {
                let attr_vueObj = this.WIDGET.vueObj.active_attr_vueObj(this.attr.setSelectionInAttr_AfterSelectionChanged)
                if (attr_vueObj && this.flex && this.flex.selectedRanges.length) {
                    console.log('setSelectionInAttr_AfterSelectionChanged')
                    let attr_col1 = this.get_attr_col(this.flex.selectedRanges[0].col),
                        attr_col2 = this.get_attr_col(this.flex.selectedRanges[0].col2)
                    if (attr_col1 && attr_col2) {
                        attr_vueObj.setSelection(Math.min(attr_col1.x1Chart, attr_col2.x1Chart), Math.max(attr_col1.x2Chart, attr_col2.x2Chart))
                        // console.log(Math.min(attr_col1.x1Chart, attr_col2.x1Chart))
                        // console.log(Math.max(attr_col1.x2Chart, attr_col2.x2Chart))
                    }
                }
            }

            // currentSelectionHTML
            if (this.flex.selectedRanges.length) {
                let sum = 0,
                    sumOfPositive = 0,
                    weightedSum = 0,
                    weight = 0,
                    min = null,
                    max = null,
                    itemWeight = null,
                    count = 0,
                    sel = this.flex.selectedRanges[0];
                
                // measureWeightedAverage 'MEASURE_baseLine'
                if (sel.row==sel.row2 && sel.col!=sel.col2 && this.flex.rows[sel.row] && this.flex.rows[sel.row].dataItem && this.flex.rows[sel.row].dataItem.measure) {
                    let measureDoc = this.get_measure(this.flex.rows[sel.row].dataItem.measure).measureDoc
                    if (measureDoc && measureDoc.params && measureDoc.params.cssStyle_condition=='cssStyle-CONDITIONS-FA') {
                        for (let irow = 0; irow < this.flex.rows.length; irow++) {
                            if (this.flex.rows[irow].dataItem && this.flex.rows[irow].dataItem.measure=='MEASURE_baseLine') {
                                itemWeight = this.flex.rows[irow].dataItem
                                break
                            }
                        }
                    }
                    if (measureDoc && measureDoc.params && measureDoc.params.measureWeightedAverage) {
                        for (let irow = 0; irow < this.flex.rows.length; irow++) {
                            if (this.flex.rows[irow].dataItem && this.flex.rows[irow].dataItem.measure==measureDoc.params.measureWeightedAverage) {
                                itemWeight = this.flex.rows[irow].dataItem
                                break
                            }
                        }
                    }
                }

                for (let irow = Math.min(sel.row,sel.row2); irow <= Math.max(sel.row,sel.row2); irow++) {
                    for (let icol = Math.min(sel.col,sel.col2); icol <= Math.max(sel.col,sel.col2); icol++) {
                        if (irow>=0 && icol>=0) {
                            let col = this.flex.columns[icol],
                                item = this.flex.rows[irow].dataItem;
                            if (item && col && !isNaN(item[col.binding])) {
                                let positive_value = (item[col.binding]>0 ? item[col.binding] : 0)
                                sum += item[col.binding]
                                sumOfPositive += positive_value
                                count += 1
                                if (itemWeight) {
                                    weight += itemWeight[col.binding]
                                    weightedSum += positive_value * itemWeight[col.binding]
                                }
                                if (min != null) {
                                    min = Math.min(min, item[col.binding])
                                    max = Math.max(max, item[col.binding])
                                } else {
                                    min = item[col.binding]
                                    max = item[col.binding]
                                }
                            }
                        }
                    }
                }
                if (count>1) {
                    this.currentSelectionHTML = ``
                    if (itemWeight && weightedSum && weight) {
                        this.currentSelectionHTML += `<b class='tooltip-header'>Weighted average:</b> <b class='tooltip-text'> ${weight ? this.store.formatNumber(Math.round(weightedSum/weight)) : ''}</b>`
                        // this.currentSelectionHTML += `<b class='tooltip-header'>Weighted average:</b> <b class='tooltip-text'> ${weight ? this.store.formatNumber((weightedSum)) : ''}</b>`
                        // this.currentSelectionHTML += `<b class='tooltip-header'>Weighted average:</b> <b class='tooltip-text'> ${weight ? this.store.formatNumber((weight)) : ''}</b>`
                    }
                    this.currentSelectionHTML += `<b class='tooltip-header'>\tAverage:</b> <b class='tooltip-text'> ${count ? this.store.formatNumber(Math.round(sumOfPositive/count)) : ''}</b>`
                    this.currentSelectionHTML += `<b class='tooltip-header'>\tCount:</b> <b class='tooltip-text'> ${this.store.formatNumber(Math.round(count))}</b>`
                    if (sumOfPositive != sum) {
                        this.currentSelectionHTML += `<b class='tooltip-header'>\tSum>0:</b> <b class='tooltip-text'> ${this.store.formatNumber(Math.round(sumOfPositive))}</b>`
                    }
                    this.currentSelectionHTML += `<b class='tooltip-header'>\tSum:</b> <b class='tooltip-text'> ${this.store.formatNumber(Math.round(sum))}</b>`
                    this.currentSelectionHTML += `<b class='tooltip-header'>\tMin:</b> <b class='tooltip-text'> ${this.store.formatNumber(Math.round(min))}</b>`
                    this.currentSelectionHTML += `<b class='tooltip-header'>\tMax:</b> <b class='tooltip-text'> ${this.store.formatNumber(Math.round(max))}</b>`
                } else {
                    this.currentSelectionHTML = ''
                }
            }
        },
        get_attr_col(icol) {
            if (icol > 0) {
                let col = this.flex.columns[icol]
                let attr_col = this.attr_by_binding[col.binding]
                if (attr_col.x1Chart) {
                    return attr_col
                }
            }
            return null
        },
        setSelection(date) {
            if (this.flex) {
                let irow = this.flex.selectedRanges[0].row
                for (let col of this.flex.columns) {
                    let attr = this.attr_by_binding[col.binding]
                    if (attr.date && attr.date==date) {
                        this.flex.selectedRanges = [new wjGrid.CellRange(irow,col.index,irow,col.index)]
                    }
                }
            }
        },
        onButtonShowDroppedDown__Click(cell) {
            // dataMapEditor
            // let col = this.column_by_binding[cell.col_binding]
            // col.isDroppedDown = !col.isDroppedDown
        },
        handleVisibility(isVisible) {
            this.isVisible = isVisible
            if (isVisible) {
                if (this.attr.getSelectionWorkspace_date){
                    this.WIDGET.getSelectionWorkspace_date = this.getSelectionWorkspace_date
                }
            }
        },
    },
    watch: {
        'store.itemsSource.change_index'(newVal, oldVal) {
            if (this.flex) {
                for (let col of this.flex.columns) {
                    let attr = this.attr_by_binding[col.binding]
                    if (attr.component == 'AttrLink' && attr.relation_segment && attr.relation_segment==this.store.itemsSource.change_type) {
                        console.log(`watchSheet ${this.store.itemsSource.change_type} ${newVal}`)
                        this.store.get_itemsSource(this, col, attr)
                    }
                }
            }
        }
    },
}
</script>

<style>
.attr-containerResize {
    height:inherit;
    width:-webkit-fill-available;
}
.sheet {
    background-color: white;
}
.label-sheet {
    font-size: 0.875rem;
    top: 4px;
    color: #3f51b5;
    margin-left: 20px;
    margin-bottom: 7px;
}
.attr-sheet {
    height: 400px;
    overflow: hidden;
    width:-webkit-fill-available !important;
}
.attr-sheet-input {
    width:-webkit-fill-available !important;
    /* margin: 5px !important; */
}
.wj-header-row  {
    text-align: center !important;
    border-right: 1px solid !important;
}
.sheet-group1-row  {
    background-color: #f7f7f7;
    font-weight: bold;
}
.sheet-group2-row  {
    background-color: lightgray;
    /* color: white; */
    font-weight: bold;
}
.sheet-total-row  {
    background-color: lightgray;
    color: white;
    font-weight: bolder;
}
.cell-sum {
    color: green;
}
.attr-sheet .wj-cell {
    padding: 6px 2px !important;
}
.wj-cell .attr-doc {
    display: flex;
    position: absolute; 
    left: 0px;
    width: 100%; 
    border-radius: 0px;
    margin: -5px 0 0px 0;
}
.wj-cell .attr-doc-input {
    width: 100%;
}
.wj-cell .cell-has-doc {
    background-color: burlywood;
}
.wj-cell .cell-has-doc:hover:after {
    opacity: 1;
}
.attr-column-button-group {
    position: absolute;
    left: 0px;
    top: 0px;
    color: white !important;
    background: transparent;
}
.attr-row-button-group {
    position: absolute;
    left: 0px;
    top: 0px;
    color: lightslategray !important;
    background: transparent;
}
.attr-row-button-group .pi {
    color: lightslategray;
}
.wj-cell.wj-has-override {
    background: lightgoldenrodyellow !important;
    color: lightslategray !important;
    font-weight: bold;
}
.commandPanel-menu{
    display: flex;
    align-items: center;
    padding: 0 6px;
}
.line-first-inside::before {
    content: "";
    position: absolute;
    top: 70%;
    bottom: 0;
    left: 50%;
    border-left: 3px solid #eee;
    transform: translateX(-50%); 
}
.line-middle-inside::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    border-left: 3px solid #eee;
    transform: translateX(-50%); 
}
.line-last-inside::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 70%;
    left: 50%;
    border-left: 3px solid #eee;
    transform: translateX(-50%); 
}

</style>