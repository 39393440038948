<template>
    <div v-if="WIDGET?.editAttrs" class="attr">
        <!-- <div class="attr-content"> -->
            <slot />
        <!-- </div> -->
        <div class="attr-container"
            draggable="true"
            @dragstart="store.attr_dragStart({event: $event, widget_id: widget_id, attr: attr})"
            @dragover="store.attr_dragOver({event: $event, widget_id: widget_id, attr: attr})"
            @drop="store.attr_dragFinish({event: $event, widget_id: widget_id, attr: attr})"
            @dragend="store.attr_dragEnd({event: $event, widget_id: widget_id, attr: attr})"
            @click="store.attr_activate({event: $event, widget_id: widget_id, attr: attr})"
        >
            <!-- <div class="attr-header">{{attr.id}}</div> -->
            <div class="attr-buttons">
                <div class="button mr-2" title="Attr" @click="onClick({'command': 'onAttr__Click'})">
                    <i class="pi pi-bars"></i>
                </div>
            </div>
        </div>
    </div>
    <slot v-else />
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    data: () => ({ 
        WIDGET: {},
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this, false)
        },
        onClick: function(actionMenu) {
            let vueObj = this.WIDGET.vueObj
            // vueObj.store.executeStoreMethod(vueObj, actionMenu)
        },
    }
}
</script>

<style scoped>
.attr {
    display: flex;
    justify-content: space-between;
    /* background: transparent; */
    /* box-shadow: 0 1px 2px rgba(55, 63, 66, 0.07), 0 2px 4px rgba(55, 63, 66, 0.07), 0 4px 8px rgba(55, 63, 66, 0.07),
    0 8px 16px rgba(55, 63, 66, 0.07), 0 16px 24px rgba(55, 63, 66, 0.07), 0 24px 32px rgba(55, 63, 66, 0.07); */
    border: dashed;
    border-radius: 0.5rem;
    border-color: lightgray;
    border-left: none;
    margin: 3px;
    background-color: transparent;
}
.attr.active {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: rgba(145, 200, 248, 0.75);
}
.attr.drag-over {
    border: 2px dashed #000;
    background-color: rgba(0, 0, 0, 0.1);
    transition: all 250ms;
}
.attr.drag-source {
    opacity: 0.4;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: rgba(145, 200, 248, 0.75);
    transform: scale(0.9);
    transition: all 250ms;
}
.attr .attr-container {
    /* border-bottom: 1px solid #e0e0e0; */
    padding: 0.30rem 1rem;
    display: flex;
    align-items: center;
    cursor: move;
}
.attr .attr-header {
    flex-grow: 1;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 0.125rem;
    opacity: 0.75;
}
.attr-buttons {
    color: lightgray;
}
</style>
