<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <!-- v-if="attr['@use']!=false" -->
        <Button 
            :id="attr.html_id"
            :class="buttonClass"
            v-wjTooltip="attr.tooltip"
            @click="onClick()"
        >
            {{ `${attr.cssClass&&attr.title? ' ' : ''}${attr.title?? ''}` }}
        </Button>
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    data: () => ({
        WIDGET: {},
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()

        if (this.attr.shortkey) {
        //    this.$shortkey(this.attr.hotkey, 'onClick');
        }
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
        },
        onClick() {
            let vueObj = this.WIDGET.vueObj
            vueObj.store.executeStoreMethod(vueObj, this.attr)
        },
    },
    computed: {
        buttonClass() {
            return ['commandPanel-buttons p-button-secondary p-button-sm mr-2 p-2 ' + this.attr['cssClass'], {
                'p-button-active': this.attr.active,
            }]
        },
    },
}
</script>

<style scoped>
</style>