<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <div class="wj-labeled-input" v-wjTooltip="tooltip()">
            <input 
                :id="attr.html_id"
                type="checkbox"
                :disabled="isReadOnly"
                :checked="doc[binding]"
                @click="attr_set"
                v-wjContextMenu="'ContextWidgetMenuId'"
            />
            <label v-if="attr.title && (attr.showTitle !== undefined ? attr.showTitle : true)" :for="attr.html_id">{{ attr.title }}</label>
            <!-- <div class="wj-error" tabindex="-1">We do need your name...</div> -->
        </div>
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    data: () => ({ 
        WIDGET: {},
        doc: {},
        binding: '',
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
       
            // this.checked = this.store.attr_get(this.WIDGET, this.attr)
            const [doc, binding] = this.store.attr_get_link(this.WIDGET, this.attr)
            this.doc = doc, this.binding = binding
        },
        attr_set(input) {
            this.store.attr_set(this.WIDGET, this.attr, input.currentTarget.checked)
        },
        tooltip() {
            return this.store.tooltip_attr(this)
        },
    },
	computed: {
        isReadOnly() {
            return this.WIDGET.isReadOnly||this.attr.isReadOnly||this.attr.fieldMapping?.use||false
        },
	},
}
</script>

<style scoped>
</style>