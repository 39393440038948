<template>
    <div
        :class="WIDGET.class"
        :style="WIDGET.style"
        @focusin="activateWidget(false, false)"
    >
        <div
            :class="{
                'widget-container': true,
                'widget-loading': WIDGET.loading,
                'widget-active': isActiveWidget,
                'widget-container-inactive': !isActiveWidget && isMainArea,
            }"
            v-wjContextMenu="'ContextWidgetMenuId'"
        >
            <component
                v-for="attr in commandPanelLeft"
                :key="attr"
                :widget_id="widget_id" 
                :is="attr.component"
                :attr_id="attr.id"
            />
            <i :class="WIDGET.pi + ' pi-header'" v-if="WIDGET?.pi"></i>
            <div 
                class="widget-header single-line-text" 
                @click="activateWidget(true)"
                @dblclick="onZoomClick" 
                :id="'widget_title'+widget_id"
                draggable="true"
                @dragstart="store.dragStart({event: $event, widget_id: widget_id})"
                @dragover="store.dragOver({event: $event, widget_id: widget_id})"
                @drop="store.dragFinish({event: $event, widget_id: widget_id})"
                @dragend="store.dragEnd({event: $event, widget_id: widget_id})"
                v-wjTooltip="tooltip()"
                :contenteditable="WIDGET.title_editable"
                @keydown="handleKeydown"
                @blur="onTextChange"
            >
                {{WIDGET.title}}{{WIDGET.doc.params && WIDGET.doc.params.showArchived?', ARCHIVED':''}}{{WIDGET.activityInfo?`, ${WIDGET.activityInfo}`:''}}
            </div>
            <!-- {{WIDGET.doc && WIDGET.doc.title ? WIDGET.doc.title : WIDGET.title}}{{WIDGET.doc.params && WIDGET.doc.params.showArchived?', ARCHIVED':''}}{{WIDGET.activityInfo?`, ${WIDGET.activityInfo}`:''}} -->
            <div :class="{
                'buttons': true,
                'buttons-inactive': !isActiveWidget && isMainArea,
                'widget-container-buttons-max-inactive': !isActiveWidget && isMainArea && WIDGET?.Rect?.width > 500,
                'widget-container-buttons-min': (isActiveWidget || !isMainArea) && WIDGET?.Rect?.width <= 500,
                'widget-container-buttons-min-inactive': !isActiveWidget && isMainArea && WIDGET?.Rect?.width <= 500,
            }" >
                <!-- <div> -->
                    <component
                        v-for="attr in commandPanel"
                        :key="attr"
                        :widget_id="widget_id" 
                        :is="attr.component"
                        :attr_id="attr.id"
                    />
                <!-- </div> -->
                <div class="widget_menu">
                    <wj-menu id="actionMenuId" :header="'Action'" :displayMemberPath="'title'" :subItemsPath="'attrs'" :showDropDownButton="false"
                        v-if="show_actionMenu && WIDGET && 'groupMenu' in WIDGET && 'actionMenu' in WIDGET.groupMenu"
                        :openOnHover="true" :isAnimated="false" :itemsSource="WIDGET.groupMenu.actionMenu" :initialized="initMenu" :itemClicked="menuNodeClicked"
                    >
                        <!-- <i class="pi pi-bolt"></i> -->
                    </wj-menu>
                    <wj-menu id="windowMenuId" :header="''" :displayMemberPath="'title'" :subItemsPath="'attrs'" :showDropDownButton="false"
                        v-if="WIDGET && 'groupMenu' in WIDGET && 'windowMenu' in WIDGET.groupMenu"
                        :openOnHover="true" :isAnimated="false" :itemsSource="WIDGET.groupMenu.windowMenu" :initialized="initMenu" :itemClicked="menuNodeClicked"
                    >
                        <i class="my_right pi pi-arrows-h"></i>
                    </wj-menu>
                </div>
                
                <div class="button mr-2" title="Minimize" @click="onClick({'command': 'onScale__Click', 'params':{'cssClassW':'widget-w4', 'cssClassH':'widget-h0'}})" v-if="isMainArea" >
                    <i class="pi pi-window-minimize"></i>
                </div>
                <div class="button mr-2" title="Full screen" @click="onClick({'command': 'onFullscreen__Click'})">
                    <i class="pi pi-window-maximize"></i>
                </div>
                <div class="button mr-2 btn-hover" title="Close Widget" @click="onClick({'command': 'onCloseWindow__Click'})">
                    <i class="pi pi-times"></i>
                </div>
            </div>
            <div class="button button-more mr-2" v-if="!(WIDGET?.Rect?.width>500)">
                <i class="pi pi-ellipsis-v"></i>
            </div>
        </div>

        <div v-if="WIDGET.loading" class='widget-text-loading'></div>
        <div v-if="WIDGET?.notification?.visible" class="notification-widget" :class="{ 
            'notification-warning': WIDGET.notification.status=='warning',
            'notification-error': WIDGET.notification.status=='error', 
            'notification-critical': WIDGET.notification.status=='critical', 
        }">
            {{ truncateText(WIDGET.notification.header,100) }} {{ truncateText(WIDGET.notification.text, 50) }}
            <!-- <div class="button ml-2 btn-hover" title="Close notification" @click="store.close_notification">
                <i class="pi pi-times"></i>
            </div> -->
        </div>
        
        <!-- content -->
        <div 
            :class="{
                'widget-content': true,
                'widget-content-inactive': false && !isActiveWidget && isMainArea,
            }"
            v-wjContextMenu="'cellMenuId'" >
            <slot :palette="palette" />
        </div>

        <wj-popup class="popover popup-tab" :initialized="initPopupTab" 
            :isResizable="true"
        >
            <div class="popover-content" v-if="WIDGET">
                <component v-for="attr in WIDGET?.attrs_popupTab"
                    :widget_id="widget_id" 
                    :key="attr.html_id"
                    :is="attr.component"
                    :attr_id="attr.id"
                />
            </div>
        </wj-popup>

    </div>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'
// import RemoveIcon from '../icons/RemoveIcon.vue';

export default {
    props: [
        'widget_id',
    ],
    data: () => ({ 
        WIDGET: {},
        commandPanel: [],
        commandPanelLeft: [],
        show_actionMenu: true,
    }),
    // setup: () => ({ palette }),
    setup() {
        const store = useMainStore();
        const palette = store.palette;
        return {
            store,
            palette
        }
    },
    created() {
        // this.title = this.store.findWidget(this.widget_id).WIDGET.title
        // console.log('created Widget')
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        // if (!('vueObj' in this.WIDGET)) {
        //     this.WIDGET.vueObj = this
        // }
            
        // this.store.widget_attr_set(this)
        this.WIDGET.attrs_vueObj.WIDGET = this
        this.WIDGET.attrs_popupTab = this.store.attr_find_by_keys(this.WIDGET.attrs, 'attrs_popupTab', ['name'])?.attrs
        
        this.set_commandPanel()

        this.PAGE = this.store.WORKSPACES[this.WIDGET.workspace].PAGES[this.WIDGET.page]
    },
    mounted() {
        let vueObj = this; const store = this.store; let WIDGET = this.WIDGET
        this.store.widgetResize_afterTimer(vueObj)
        this.setObserver_widgetResize()
        store.Scale(vueObj, WIDGET)
        // if (WIDGET.component == 'WidgetWorkspace') {
        //     $('#WIDGET'+vueObj.widget_id)[0].classList.toggle("widget_workspace")
        // }
        // store.executeStoreMethod(vueObj, {'command': 'onScale__Click', params:WIDGET})

        // if (WIDGET.loading) {
        //     $('#WIDGET'+vueObj.widget_id)[0].classList.toggle("header_loading")
        // }

        if ('openMenu' in vueObj) vueObj.openMenu.command = vueObj.menuCommand();
        if ('moreMenu' in vueObj) vueObj.moreMenu.command = vueObj.menuCommand();
        if ('actionMenu' in vueObj) vueObj.actionMenu.command = vueObj.menuCommand();

        // if (WIDGET.component == 'WidgetWorkspace') {
        //     // store.activate_workspaceWidget(WIDGET)
        // } else {
            vueObj.activateWidget()
        // }

        this.PAGE?.checkWorkspaceScroll()

        // const endTime = performance.now()
        // console.log(`Rendering1 ${this.widget_id} in ${((endTime - this.WIDGET.startTime) / 1000).toFixed(3)}s`)
    },
    updated() {
        if (this.WIDGET?.startTime) {
            if (this.timerId) {
                clearTimeout(this.timerId)
            }
            const endTime = performance.now()
            this.timerId = setTimeout(() => {
                console.log()
                this.WIDGET.TERMINAL += `\n${( (endTime - this.WIDGET.startTime) / 1000).toFixed(3) }s rendering [[[[[[[[[[[]]]]]]]]]]]`
                this.WIDGET.startTime = null
                if (this.WIDGET.vueObj.content_Changed_attr_vueObj) {
                    this.WIDGET.vueObj.content_Changed_attr_vueObj('TERMINAL', {fold:true})
                }
            }, 2000)

        }
    },
    unmounted() {
        this.PAGE?.checkWorkspaceScroll()
    },
    methods: {
        activateWidget(is_header=false, run_scrollInWorkspace=true) {
            // if (is_header || this.WIDGET.component != 'WidgetWorkspace') {
                this.store.widget_activate(this.WIDGET, run_scrollInWorkspace)
            // }
        },
        initMenu(menu) {
            this[menu.hostElement.id] = menu;
        },
        menuCommand(param) {
            let this_ = this
            return {
                executeStoreMethod: function(param){
                    let vueObj = this_.WIDGET.vueObj
                    vueObj.store.executeStoreMethod(vueObj, param)
                },
                canExecuteCommand: function(param){
                    return true;
                }
            }
        },
        menuNodeClicked(menu) {
            let actionMenu = menu.itemsSource[menu.selectedIndex]
            let vueObj = this.WIDGET.vueObj
            this.store.executeStoreMethod(vueObj, actionMenu)
        },
        onClick(actionMenu) {
            let vueObj = this.WIDGET.vueObj
            vueObj.store.executeStoreMethod(vueObj, actionMenu)
        },
        onZoomClick() {
            if (!this.WIDGET.title_editable) {
                let vueObj = this.WIDGET.vueObj
                vueObj.store.executeStoreMethod(vueObj, {'command': 'onScale__Click', 'params':{'cssClassW':'widget-w4', 'cssClassH':'widget-h4'}})
            }
        },
        handleKeydown(e) {
            if (e.key === 'Enter') {
                e.preventDefault()

                // e.target.blur()
                this.onTextChange(e)
            }
        },
        onTextChange(e) {
            if (this.WIDGET.title !== e.target.innerText) {
                this.WIDGET.title = e.target.innerText
                if (this.WIDGET.action_afterChange_title) {
                    this.store.executeStoreMethod(this.WIDGET.vueObj, this.WIDGET.action_afterChange_title)
                }
            }
        },
        setObserver_widgetResize() {
            const elementToObserve = document.querySelector(`#WIDGET${this.widget_id}`)
            if (elementToObserve) {
                const resizeObserver = new ResizeObserver(entries => {
                    this.store.widgetResize(this, 120)
                    // for (let entry of entries) {
                    //     const {width, height} = entry.contentRect;
                    //     console.log(`#WIDGET${this.widget_id} имеет новые размеры: ширина ${width}px, высота ${height}px`);
                    // }
                })
                resizeObserver.observe(elementToObserve)
            }
        },
        set_commandPanel(params={}) {
            if (this.WIDGET.doc && this.WIDGET.doc.params) {
                params.flex_havActiveSelectors = this.WIDGET.doc.params.flex_havActiveSelectors
            }
            if ('groupMenu' in this.WIDGET && 'commandPanel' in this.WIDGET.groupMenu) {
                console.log('set_commandPanel')
                this.commandPanelLeft = []
                this.commandPanel = []
                for (let attr of this.WIDGET.groupMenu.commandPanel) {
                    attr['@use'] = !('v-if' in attr) || (params[attr['v-if']])
                    if (attr['@use']) {
                        if (attr['commandPanelLeft']) {
                            this.commandPanelLeft.push(attr)
                        } else {
                            this.commandPanel.push(attr)
                        }
                    }
                }
            }
        },
        truncateText(text, length) {
            if (!text) {
                return ''
            } else if (text.length > length) {
                return text.substring(0, length) + '...'
            }
            return text
        },
        tooltip() {
            let params = {},
            WIDGET = this.WIDGET
            
            params['id'] = WIDGET.doc?.id
            params['segment'] = WIDGET.doc?.segment || WIDGET.doc?.MDH?.segment
            
            if (this.isReadOnly) { 
                params['isReadOnly'] = 'true'
            }
            if (WIDGET.doc.predefined) {
                params['Predefined'] = `<input type="checkbox" checked disabled>`

                params[`Non Updatable`] = this.store.attr_html(this.WIDGET, 'Widget', 'noupdate', WIDGET.doc.noupdate)
            }
                            
            return this.store.tooltip_html(params, WIDGET.tooltip||'')
        },
        initPopupTab(popup) {
            this.popupTab = popup
        },
    },
    computed: {
        isReadOnly() {
            return this.WIDGET.isReadOnly||false // TODO WIDGET.isReadOnly
        },
        isActiveWidget() {
            return this.store.activeWIDGET === this.WIDGET
        },
        isMainArea() {
            return this.WIDGET?.area == 'main-area'
        },
    },
    watch: {
        // loading(new_loading, old_loading) {
        //     console.log('change loading')
        //     $('#WIDGET'+this.widget_id)[0].classList.toggle("header_loading")
        // }
    },
}
</script>

<style>
.header_loading {
    background-color: lightyellow;
}
.widget-active {
    color:#647eff;
    font-weight: bold;
}
.widget-loading {
    animation-name: animation-loading-WIDGET;
    animation-duration: 7s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    /* background-color: #42d3926d; */
}
.widget-container-inactive {
    background: darkgray;
    color:white
}
.widget-content-inactive::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(169, 169, 169, 0.016);
    z-index: 2;
    pointer-events: none;
}
.widget-content-inactive {
    filter: grayscale(50%);
    /* opacity: 0.5; */
}
.widget-text-loading {
    position: absolute;
    margin-left: inherit;
}
.widget-text-loading:after {
    position: absolute;

    animation-name: animation-loading-text;
    animation-duration: 7s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    content: "Loading";

    font-family: helvetica, sans-serif;
    font-weight: bold;
    font-size: smaller;
    position: absolute;
    margin-left: inherit;
}
@keyframes animation-loading-text {
    0% { content: "Loading"; color: #647eff69; }
    15% { content: "Loading."; }
    30% { content: "Loading.."; }
    45% { content: "Loading..."; color: #42d3926d; }
    55% { content: "Loading"; }
    70% { content: "Loading."; }
    85% { content: "Loading.."; }
    100% { content: "Loading..."; color: #647eff69; }
}
@keyframes animation-loading-WIDGET {
    0% { background-color: #42d3926d; }
    45% { background-color: #647eff69; }
    100% { background-color: #42d3926d; }
}
.wj-control {
    border: none;
}
.wj-dropdown {
    border: dotted lightgray;
}
.widget_menu .wj-dropdown {
    border: none;
}
.grid-menu .wj-dropdown {
    border: none;
}
/* .wj-control .wj-template {
    display: none !important;
} */
.my_right {
    margin: 0 -0.7rem 0 0;
}
.buttons {
    color: lightslategray;
}
.buttons-inactive {
    color: white;
}
.buttons-inactive .wj-control {
    color: white;
}
.wj-control {
    color: lightslategray;
}
.widget-container .wj-menu {
    background: transparent;
}
.widget-container {
    position: relative;
    min-height: 37px;
}
.widget-container-buttons-max-inactive{
    position: absolute;
    right: 24px;
    visibility: hidden;
    text-shadow:
            /* many shadows blur out the area around the text */
            0 0 1px #a9a9a9,
            0 0 2px #a9a9a9,
            0 0 3px #a9a9a9,
            0 0 4px #a9a9a9,
            0 0 5px #a9a9a9,
            0 0 6px #a9a9a9,
            0 0 7px #a9a9a9,
            0 0 8px #a9a9a9,
            0 0 9px #a9a9a9,
            0 0 10px #a9a9a9
}
.widget-container-buttons-min{
    position: absolute;
    right: 24px;
    visibility: hidden;
    text-shadow:
            /* many shadows blur out the area around the text */
            0 0 1px white,
            0 0 2px white,
            0 0 3px white,
            0 0 4px white,
            0 0 5px white,
            0 0 6px white,
            0 0 7px white,
            0 0 8px white,
            0 0 9px white,
            0 0 10px white
}
.widget-container-buttons-min-inactive{
    position: absolute;
    right: 24px;
    visibility: hidden;
    text-shadow:
            /* many shadows blur out the area around the text */
            0 0 1px #a9a9a9,
            0 0 2px #a9a9a9,
            0 0 3px #a9a9a9,
            0 0 4px #a9a9a9,
            0 0 5px #a9a9a9,
            0 0 6px #a9a9a9,
            0 0 7px #a9a9a9,
            0 0 8px #a9a9a9,
            0 0 9px #a9a9a9,
            0 0 10px #a9a9a9
}
.WIDGET:hover .widget-container-buttons-max-inactive{
    visibility: visible;
}
.widget-container:hover .widget-container-buttons-min{
    visibility: visible;
}
.widget-container:hover .widget-container-buttons-min-inactive{
    visibility: visible;
}
.widget-container:hover .button-more{
    visibility: hidden;
}
.widget_menu {
	display: flex;
    align-items: center;
    z-index: 2;
}
.pi-header {
    font-size: 1.3rem;
    font-weight: bold;
    padding-right: 0.3rem;
    opacity: 0.75;
}
</style>
