<template>
	<div class="layout-menu-container">
		<AppSubmenu :items="model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" @keydown="onKeyDown" />
		<span>
			<div class="logo">
				<a 
					:href="store?.url" 
					target="_blank" 
					rel="noopener noreferrer" 
                    v-wjTooltip="'Go to ' + `<b class='tooltip-header'>web-scm.com</b>` + '<br>(opens in a new tab)' + '<br><br>' + store?.version_frontend + '<br>' + store?.version_backend"
				>WebSCM</a>
			</div>
			<!-- <div class="versions-container">
				<div class="versions">
					{{ store.version_frontend }} <br>
					{{ store.version_backend }}
				</div>
			</div> -->
		</span>
	</div>
</template>

<script>
import AppSubmenu from './AppSubmenu'
import { useMainStore } from '@/stores/mainStore'
import { onMounted } from 'vue'

export default {
	props: {
		model: Array
	},
	data: () => ({ 
    }),
    setup() {
		// onMounted(() => {
		// 	const e = document.querySelector('div a[href*="wijmo"]')
		// 	if (e) {
		// 		e.parentElement.remove()
		// 	}
		// });
			
        const store = useMainStore();
        return { store }
    },
    // created() {
    //     console.log(`created`)
    // },
    methods: {
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
		onKeyDown(event) {
			const nodeElement = event.target;
			if (event.code === 'Enter' || event.code === 'Space') {
				nodeElement.click();
				event.preventDefault();
			}
		},
		bannerImage() {
			return this.$appState.darkTheme ? 'images/banner-primeblocks-dark.png' : 'images/banner-primeblocks.png';
		}
    },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	},
	components: {
		'AppSubmenu': AppSubmenu
	}
}
</script>

<style scoped>
.logo {
	font-size: 1.7rem;
    font-weight: 900 !important;
    /* letter-spacing: -1.5px !important; */
	background: -webkit-linear-gradient(315deg,#42d392 25%,#647eff);
	-webkit-background-clip:text;
	-webkit-text-fill-color:transparent;
	margin-top: 41px;
	margin-bottom: 41px;
}
.versions-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
}

.versions {
	color: gray;
    font-size: 1rem;
    margin: 0 10px;
}
</style>