<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <div class="commandPanel">
            <div class="commandPanel-menu">
                <component
                    v-for="attrM in commandPanel"
                    :key="attrM.html_id"
                    :widget_id="widget_id" 
                    :is="attrM.component"
                    :attr_id="attrM.id"
                />
            </div>
        </div>
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    data: () => ({ 
        WIDGET: {},
        commandPanel: [],
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
            this.commandPanel = this.attr.attrs
        },
    }
}
</script>

<style scoped>
.commandPanel{
    display: flex;
    width:-webkit-fill-available !important;
    padding: 6px;
}
.commandPanel-menu{
    display: flex;
    align-items: center;
    padding: 0 6px;
}
</style>